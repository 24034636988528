import React from 'react';
import { Link, useLocation } from "react-router-dom";
import nav1 from './img/nav1.svg'
import nav2 from './img/nav2.svg'
import nav3 from './img/nav3.svg'
import nav4 from './img/nav4.svg'
import nav5 from './img/nav5.svg'
import nav6 from './img/nav6.svg'

export default function Sidebar(props) {
    const location = useLocation();

    return (
        <>
            <div className={ props.hamBurger ? "sidebar showHumburger" : 'sidebar '}>
                <div className="menu-nav">
                    <ul>
                        <li className={location.pathname === '/dashboard' ? 'active' : ''} onClick={props.handleHamBurger}>
                            <Link to="/dashboard">
                                <figure>
                                    <img src={nav1} alt=""/>
                                </figure>
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li className={location.pathname === '/profile' ? 'active' : ''} onClick={props.handleHamBurger}>
                            <Link to="/profile">
                                <figure>
                                    <img src={nav2} alt=""/>
                                </figure>
                                <span>Profile</span>
                            </Link>
                        </li>
                        <li className={location.pathname === '/buy-token' ? 'active' : ''} onClick={props.handleHamBurger}>
                            <Link to="/buy-token">
                                <figure>
                                    <img src={nav3} alt=""/>
                                </figure>
                                <span>Buy Token</span>
                            </Link>
                        </li>
                        <li className={location.pathname === '/transactions' ? 'active' : ''} onClick={props.handleHamBurger}>
                            <Link to="/transactions">
                                <figure>
                                    <img src={nav4} alt=""/>
                                </figure>
                                <span>Transactions</span>
                            </Link>
                        </li>
                        <li className={location.pathname === '/support' ? 'active' : ''} onClick={props.handleHamBurger}>
                            <Link to="/support">
                                <figure>
                                    <img src={nav5} alt=""/>
                                </figure>
                                <span>Support</span>
                            </Link>
                        </li>                     
                        <li className={location.pathname === '/activity-log' ? 'active' : ''} onClick={props.handleHamBurger}>
                            <Link to="/activity-log">
                                <figure>
                                <img src={nav6} alt=""/>
                                </figure>
                                <span>Activity Logs {props.logs}</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
