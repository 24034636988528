import React, { useState, useEffect } from 'react';
import { getUserDetails, presaleCheck } from "components/helper/userHelper";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from 'components/UserContext';

export default function MenuTab(props) {
    const { users } = useUser();
    const [user, setUser] = useState({
        fullname: ""
    })
    const [endTime, setEndTime] = useState(0);
    const [startTime, setStartTime] = useState(0);
    const [showWaitList, setShowWaitList] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let isAPISubscribed = true;
        if (isAPISubscribed) {
            setStartTime(users.startTime);
            setEndTime(users.endTime);
            setUser({
                fullname: users.fullname
            });
            checkPresale()
        }
        return () => {
            isAPISubscribed = false
        }
    }, [users.startTime, users.endTime, users.fullname]);



    const checkPresale = async () => {
        let res = await presaleCheck()
        setShowWaitList(res.data.presale)
    }

    return (
        <>
            <div className="small-menu">
                <ul>
                    <li className={props.toggleState === 1 ? "active" : ""}><Link to="/profile?id=1" onClick={() => props.toggleTab(1)} >Personal Data</Link></li>
                    {/* <li className={props.toggleState === 6 ? "active" : ""}><Link to="/profile?id=6" onClick={() => props.toggleTab(6)}>Identity Verification
                        </Link></li> */}
                    <li className={props.toggleState === 2 ? "active" : ""}><Link to="/profile?id=2" onClick={() => props.toggleTab(2)} >Settings</Link></li>
                    <li className={props.toggleState === 3 ? "active" : ""}><Link to="/profile?id=3" onClick={() => props.toggleTab(3)} >Referral Details</Link></li>
                    {showWaitList ? '' : <li className={props.toggleState === 4 ? "active" : ""}><Link to="/profile?id=4" onClick={() => props.toggleTab(4)} >Waitlist</Link></li>}
                    <li className={props.toggleState === 5 ? "active" : ""}><Link to="/profile?id=5" onClick={() => props.toggleTab(5)} >Password</Link></li>
                </ul>
            </div>
        </>
    )
}
