import React, { useState, useEffect,useCallback } from 'react'
import { enableTwoFA, verify2FA, getUserDetails, disabledTwoFA, activityLogs } from 'components/helper/userHelper'
import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from 'sweetalert2';
// import AuthContext from 'components/Common/ContextReferralMenu';
// import { useNavigate } from "react-router-dom";
import { useUser } from 'components/UserContext';

export default function Settings(props) {
    const { users } = useUser();
    const [show, setShow] = useState(false);
    const [loaderAct, setloaderAct] = useState(false);
    const [loaderAct1, setloaderAct1] = useState(false);
    const [loaderAct2, setloaderAct2] = useState(false);
    const [tokenval, setTokenVal] = useState();
    const [secret, setsecret] = useState();
    const [imgQR, setImgQR] = useState();
    const [twoFactor, setTwoFactor] = useState()
    const [copybtn, setcopybtn] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    // const auth = useContext(AuthContext);
    const [getStartedOn, setGetStartOff] = useState(true)
    // const navigate = useNavigate();
    // console.log("Auth status",auth.status);

    const onCopyText = () => {
        setcopybtn(true)
        setTimeout(function () {
            setcopybtn(false)
        }, 2000);
    };

    const enableTwoFA_fn = async () => {
        setloaderAct(true)
        const res = await enableTwoFA();
        if(!res){
            setloaderAct(false)
            Swal.fire({
                text: "Error while processing your request please try again later",
                button: false,
                background: "black",
                icon: "error",
                timer: 2000,
            });
        }else{
            setloaderAct(false)
            if (res.data.success) {
                setImgQR(res.data.data)
                setsecret(res.data.secret)
                setGetStartOff(false)
                setShow(true)
            }else {
                Swal.fire({ showConfirmButton:false,
                    text: res.data.msg,
                    button: false,
                    background: 'black',
                    icon: "error",
                    timer: 3000,
                });
            }
        }

    }

    const saveVerify2FA = async () => {
        if (tokenval) {
            setloaderAct1(true)
            const res1 = await verify2FA(secret, tokenval);
            if(!res1){
                setloaderAct1(false)
                Swal.fire({
                    text: "Error while processing your request please try again later",
                    button: false,
                    background: "black",
                    icon: "error",
                    timer: 2000,
                });
            }else{
                setloaderAct1(false)
                if (res1.data.success) {
                    setShow(false)
                    setTimeout(function () {
                        Swal.fire({ showConfirmButton:false,
                            text: res1.data.msg,
                            button: false,
                            background: 'black',
                            icon: "success",
                            timer: 3000,
                        });
                    }, 500);
                    fatchUserDetails()
                }else {
                    Swal.fire({ showConfirmButton:false,
                        text: res1.data.msg,
                        button: false,
                        background: 'black',
                        icon: "error",
                        timer: 4000,
                    });
                }
            }
        }
        else {
            Swal.fire({ showConfirmButton:false,  text: 'Please enter token code', icon: 'error', background: 'black', button: false, timer: 3000 });
        }

    }

    const fatchUserDetails = useCallback(async () => {
        let res = await getUserDetails();
        if (res.data.success) {
            setTwoFactor(res.data.data.userDetails.twoFactor)
            setIsChecked(res.data.data.userDetails.recordActivity)
        }
    },[])

    const disable2FA_fn = async () => {
        setloaderAct(true)
        let res1 = await disabledTwoFA();
        if(!res1){
            setloaderAct(false)
            Swal.fire({
                text: "Error while processing your request please try again later",
                button: false,
                background: "black",
                icon: "error",
                timer: 2000,
            });
        }else{
            setloaderAct(false)
            if (res1.data.success) {
                setTimeout(function () {
                    Swal.fire({ showConfirmButton:false,
                        text: res1.data.msg,
                        button: false,
                        background: 'black',
                        icon: "success",
                        timer: 3000,
                    });
                }, 500);
                fatchUserDetails()
                setGetStartOff(true)
            }else {
                Swal.fire({ showConfirmButton:false,
                    text: res1.data.msg,
                    button: false,
                    background: 'black',
                    icon: "error",
                    timer: 4000,
                });
            }
        }

    }

    const activityLog_fn = async (value) => {
        setIsChecked(!isChecked)
        setloaderAct2(true)
        let res = await activityLogs(value);
        if(res){
            setloaderAct2(false)
            if (res.data.success) {
                Swal.fire({ showConfirmButton:false,
                    text: res.data.msg,
                    button: false,
                    background: 'black',
                    icon: "success",
                    timer: 3000,
                });
            }
            else {
                Swal.fire({ showConfirmButton:false, 
                    text: res.data.msg,
                    button: false,
                    background: 'black',
                    icon: "error",
                    timer: 3000,
                });
            }
        }
        else{
           Swal.fire({
             text: "Error while processing your request please try again later",
             button: false,
             background: "black",
             icon: "error",
             timer: 2000,
           });
        }

    }

    useEffect(() => {
        let isAPISubscribed = true;
        if(isAPISubscribed){
            fatchUserDetails();
        }
        return () =>{
            isAPISubscribed = false
        }
    }, [fatchUserDetails, users]);
    return (
        <>
            <div className={props.toggleState === 2 ? "active-content col-md-8 d-block" : "content col-md-8"}>
                <div className="setting-sec mb-4">
                    <div className="security-settings">
                        <div className="card-cust p-4">
                            <h4>Security Settings</h4>
                            <div className="switch-toggle d-flex align-items-center">
                                <p className="mb-0">Save my activities log</p>
                                <label className="switch ml-auto">
                                    <input
                                        type="checkbox"
                                        onChange={(event) => activityLog_fn(event.currentTarget.checked)}
                                        checked={isChecked}
                                    />
                                    <span className="slider round"></span>
                                </label>
                                {loaderAct2 ? <img className="loaderImg imgloAct" src="images/loader2.gif" alt="" /> : ''}

                            </div>
                            {/* <div className="switch-toggle d-flex align-items-center">
                                <p className="mb-0">Alert me by email in case of unusual activity in my account</p>
                                <label className="switch ml-auto">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="two-factor w-100">
                    <div className="card-cust p-4">
                        <h4>Two-Factor Authentication</h4>
                        <p>With 2-Step Verification, also called two-factor authentication, you can add an extra layer of security to your account in case your password is stolen.</p>
                        <div className="two-factor-btns">
                            {twoFactor ?

                                <>
                                    {loaderAct ? <button className="btn btn-grd mr-2" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> : <a href="#" className="btn btn-border" rel="noopener" onClick={disable2FA_fn}>Disable 2FA</a>}
                                </>
                                :
                                <>
                                    {
                                    loaderAct 
                                    ? 
                                    <button className="btn btn-grd mr-2" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> 
                                    : 
                                    <>
                                    {getStartedOn ? <a href="#" className="btn btn-grd mr-2" rel="noopener" onClick={enableTwoFA_fn}>Get Started</a> : ''}
                                    </>
                                    }
                                </>

                            }

                        </div>
                        <div className={show ? 'twofaBox show2FA ' : 'twofaBox hide2FA ' }>
                            <div className='mt-4'>
                                <p><b>1. Get the app.</b> Download <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US" target="_blank" rel="noreferrer">Google Authenticator</a> on your phone.</p>
                                <div className='hrline'></div>
                                <p><b>2. Scan the QR code below.</b>  Alternatively, you can type <a href="#" rel="noopener">the secret key</a> into your authenticator app.</p>
                               <div className='qrcodebx'>
                               <div className="row mb-4">
                                    <div className="col-lg-12 text-center d-flex gap-3 twofaOverly">
                                        <img className='qrcodeImage' src={imgQR} alt="" />
                                        <div className="miniLogoouter">
                                            <img src="images/logoLoader.png" className="miniLogo" alt="" />
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className='cssForAll'>
                                    <div className="  or-box"><span>OR</span></div>
                                </div>
                                <div className="form-group secretcode">
                                    <label>Secret&nbsp;:&nbsp;</label>
                                    <input type="text" name="" value={secret} className="form-control" disabled />
                                    {/* <button className="btn btn-grd"><i className="fa fa-clone" aria-hidden="true"></i> Copy</button> */}
                                    <CopyToClipboard text={secret} className="btn btn-grd" onCopy={onCopyText}>
                                        {copybtn ? <button style={{ border: 0, backgroundColor: "#0000" }}><i className="fa fa-clone copyto" aria-hidden="true"></i> </button> : <button style={{ border: 0, backgroundColor: "#0000" }}><i className="fa fa-clone" aria-hidden="true"></i></button>}

                                    </CopyToClipboard>
                                </div>
                               </div>
                             
                                <div className='hrline'></div>
                                <p><b>3. Verify your devices.</b> Please enter the code to confirm.</p>
                                <div className=''>

                                    {/* <div className="form-group">
                                    <label>Email Address</label>
                                    <input type="text" name="" className="form-control" value="akansha.agarwal@codebird.in"/>
                                </div> */}
                                    <div className="form-group ">
                                        <input type="number" name="" className="form-control mb-4 mt-4" placeholder="Enter Google authenticator code" value={tokenval} onChange={(event) => { setTokenVal(event.target.value) }} />
                                        {loaderAct1 ? <button className="btn btn-grd mr-2" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Wait...</button> : <button type='submit' className='btn btn-grd mr-2' onClick={saveVerify2FA}>Enable Two-Factor Authentication</button>}
                                    </div>
                                    {/* <div>
                                    <input type="text" name="" className="form-control " placeholder="Enter Token Code" />
                                    <button>COPY</button>
                                </div> */}

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
