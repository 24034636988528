/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import { Helmet } from "react-helmet";
import "../Css/Custom.css";
import { Link, useNavigate } from "react-router-dom";
import { signup, googleSignup, login2FAApi, getUserDetails, privacyPolicy, terms, ssoId, getCurrencyList } from "components/helper/userHelper";
import qs from "qs";
import Swal from 'sweetalert2';
import { gapi } from 'gapi-script';
import { GoogleLogin } from "react-google-login";
import { countryCode } from "./countryCode";
import DatePicker from 'react-date-picker';
import { nationality_list } from "components/Common/nationality";
import Captcha from "components/Common/Captcha";
import Select from "react-select";
import DistributionImg from './img/Distribution-img.webp'
import laptopDesk from './img/laptopDesk.png'

const newCountryCodes = [
  {
    "label": "+93-Afghanistan",
    "value": "+93"
  },
  {
    "label": "+358-Aland Islands",
    "value": "+358"
  },
  {
    "label": "+355-Albania",
    "value": "+355"
  },
  {
    "label": "+213-Algeria",
    "value": "+213"
  },
  {
    "label": "+1684-AmericanSamoa",
    "value": "+1684"
  },
  {
    "label": "+376-Andorra",
    "value": "+376"
  },
  {
    "label": "+244-Angola",
    "value": "+244"
  },
  {
    "label": "+1264-Anguilla",
    "value": "+1264"
  },
  {
    "label": "+672-Antarctica",
    "value": "+672"
  },
  {
    "label": "+1268-Antigua and Barbuda",
    "value": "+1268"
  },
  {
    "label": "+54-Argentina",
    "value": "+54"
  },
  {
    "label": "+374-Armenia",
    "value": "+374"
  },
  {
    "label": "+297-Aruba",
    "value": "+297"
  },
  {
    "label": "+61-Australia",
    "value": "+61"
  },
  {
    "label": "+43-Austria",
    "value": "+43"
  },
  {
    "label": "+994-Azerbaijan",
    "value": "+994"
  },
  {
    "label": "+1242-Bahamas",
    "value": "+1242"
  },
  {
    "label": "+973-Bahrain",
    "value": "+973"
  },
  {
    "label": "+880-Bangladesh",
    "value": "+880"
  },
  {
    "label": "+1246-Barbados",
    "value": "+1246"
  },
  {
    "label": "+375-Belarus",
    "value": "+375"
  },
  {
    "label": "+32-Belgium",
    "value": "+32"
  },
  {
    "label": "+501-Belize",
    "value": "+501"
  },
  {
    "label": "+229-Benin",
    "value": "+229"
  },
  {
    "label": "+1441-Bermuda",
    "value": "+1441"
  },
  {
    "label": "+975-Bhutan",
    "value": "+975"
  },
  {
    "label": "+591-Bolivia, Plurinational State of",
    "value": "+591"
  },
  {
    "label": "+387-Bosnia and Herzegovina",
    "value": "+387"
  },
  {
    "label": "+267-Botswana",
    "value": "+267"
  },
  {
    "label": "+55-Brazil",
    "value": "+55"
  },
  {
    "label": "+246-British Indian Ocean Territory",
    "value": "+246"
  },
  {
    "label": "+673-Brunei Darussalam",
    "value": "+673"
  },
  {
    "label": "+359-Bulgaria",
    "value": "+359"
  },
  {
    "label": "+226-Burkina Faso",
    "value": "+226"
  },
  {
    "label": "+257-Burundi",
    "value": "+257"
  },
  {
    "label": "+855-Cambodia",
    "value": "+855"
  },
  {
    "label": "+237-Cameroon",
    "value": "+237"
  },
  {
    "label": "+1-Canada",
    "value": "+1"
  },
  {
    "label": "+238-Cape Verde",
    "value": "+238"
  },
  {
    "label": "+ 345-Cayman Islands",
    "value": "+ 345"
  },
  {
    "label": "+236-Central African Republic",
    "value": "+236"
  },
  {
    "label": "+235-Chad",
    "value": "+235"
  },
  {
    "label": "+56-Chile",
    "value": "+56"
  },
  {
    "label": "+86-China",
    "value": "+86"
  },
  {
    "label": "+61-Christmas Island",
    "value": "+61"
  },
  {
    "label": "+61-Cocos (Keeling) Islands",
    "value": "+61"
  },
  {
    "label": "+57-Colombia",
    "value": "+57"
  },
  {
    "label": "+269-Comoros",
    "value": "+269"
  },
  {
    "label": "+242-Congo",
    "value": "+242"
  },
  {
    "label": "+243-Congo, The Democratic Republic of the Congo",
    "value": "+243"
  },
  {
    "label": "+682-Cook Islands",
    "value": "+682"
  },
  {
    "label": "+506-Costa Rica",
    "value": "+506"
  },
  {
    "label": "+225-Cote d'Ivoire",
    "value": "+225"
  },
  {
    "label": "+385-Croatia",
    "value": "+385"
  },
  {
    "label": "+53-Cuba",
    "value": "+53"
  },
  {
    "label": "+357-Cyprus",
    "value": "+357"
  },
  {
    "label": "+420-Czech Republic",
    "value": "+420"
  },
  {
    "label": "+45-Denmark",
    "value": "+45"
  },
  {
    "label": "+253-Djibouti",
    "value": "+253"
  },
  {
    "label": "+1767-Dominica",
    "value": "+1767"
  },
  {
    "label": "+1849-Dominican Republic",
    "value": "+1849"
  },
  {
    "label": "+593-Ecuador",
    "value": "+593"
  },
  {
    "label": "+20-Egypt",
    "value": "+20"
  },
  {
    "label": "+503-El Salvador",
    "value": "+503"
  },
  {
    "label": "+240-Equatorial Guinea",
    "value": "+240"
  },
  {
    "label": "+291-Eritrea",
    "value": "+291"
  },
  {
    "label": "+372-Estonia",
    "value": "+372"
  },
  {
    "label": "+251-Ethiopia",
    "value": "+251"
  },
  {
    "label": "+500-Falkland Islands (Malvinas)",
    "value": "+500"
  },
  {
    "label": "+298-Faroe Islands",
    "value": "+298"
  },
  {
    "label": "+679-Fiji",
    "value": "+679"
  },
  {
    "label": "+358-Finland",
    "value": "+358"
  },
  {
    "label": "+33-France",
    "value": "+33"
  },
  {
    "label": "+594-French Guiana",
    "value": "+594"
  },
  {
    "label": "+689-French Polynesia",
    "value": "+689"
  },
  {
    "label": "+241-Gabon",
    "value": "+241"
  },
  {
    "label": "+220-Gambia",
    "value": "+220"
  },
  {
    "label": "+995-Georgia",
    "value": "+995"
  },
  {
    "label": "+49-Germany",
    "value": "+49"
  },
  {
    "label": "+233-Ghana",
    "value": "+233"
  },
  {
    "label": "+350-Gibraltar",
    "value": "+350"
  },
  {
    "label": "+30-Greece",
    "value": "+30"
  },
  {
    "label": "+299-Greenland",
    "value": "+299"
  },
  {
    "label": "+1473-Grenada",
    "value": "+1473"
  },
  {
    "label": "+590-Guadeloupe",
    "value": "+590"
  },
  {
    "label": "+1671-Guam",
    "value": "+1671"
  },
  {
    "label": "+502-Guatemala",
    "value": "+502"
  },
  {
    "label": "+44-Guernsey",
    "value": "+44"
  },
  {
    "label": "+224-Guinea",
    "value": "+224"
  },
  {
    "label": "+245-Guinea-Bissau",
    "value": "+245"
  },
  {
    "label": "+595-Guyana",
    "value": "+595"
  },
  {
    "label": "+509-Haiti",
    "value": "+509"
  },
  {
    "label": "+379-Holy See (Vatican City State)",
    "value": "+379"
  },
  {
    "label": "+504-Honduras",
    "value": "+504"
  },
  {
    "label": "+852-Hong Kong",
    "value": "+852"
  },
  {
    "label": "+36-Hungary",
    "value": "+36"
  },
  {
    "label": "+354-Iceland",
    "value": "+354"
  },
  {
    "label": "+91-India",
    "value": "+91"
  },
  {
    "label": "+62-Indonesia",
    "value": "+62"
  },
  {
    "label": "+98-Iran, Islamic Republic of Persian Gulf",
    "value": "+98"
  },
  {
    "label": "+964-Iraq",
    "value": "+964"
  },
  {
    "label": "+353-Ireland",
    "value": "+353"
  },
  {
    "label": "+44-Isle of Man",
    "value": "+44"
  },
  {
    "label": "+972-Israel",
    "value": "+972"
  },
  {
    "label": "+39-Italy",
    "value": "+39"
  },
  {
    "label": "+1876-Jamaica",
    "value": "+1876"
  },
  {
    "label": "+81-Japan",
    "value": "+81"
  },
  {
    "label": "+44-Jersey",
    "value": "+44"
  },
  {
    "label": "+962-Jordan",
    "value": "+962"
  },
  {
    "label": "+77-Kazakhstan",
    "value": "+77"
  },
  {
    "label": "+254-Kenya",
    "value": "+254"
  },
  {
    "label": "+686-Kiribati",
    "value": "+686"
  },
  {
    "label": "+850-Korea, Democratic People's Republic of Korea",
    "value": "+850"
  },
  {
    "label": "+82-Korea, Republic of South Korea",
    "value": "+82"
  },
  {
    "label": "+965-Kuwait",
    "value": "+965"
  },
  {
    "label": "+996-Kyrgyzstan",
    "value": "+996"
  },
  {
    "label": "+856-Laos",
    "value": "+856"
  },
  {
    "label": "+371-Latvia",
    "value": "+371"
  },
  {
    "label": "+961-Lebanon",
    "value": "+961"
  },
  {
    "label": "+266-Lesotho",
    "value": "+266"
  },
  {
    "label": "+231-Liberia",
    "value": "+231"
  },
  {
    "label": "+218-Libyan Arab Jamahiriya",
    "value": "+218"
  },
  {
    "label": "+423-Liechtenstein",
    "value": "+423"
  },
  {
    "label": "+370-Lithuania",
    "value": "+370"
  },
  {
    "label": "+352-Luxembourg",
    "value": "+352"
  },
  {
    "label": "+853-Macao",
    "value": "+853"
  },
  {
    "label": "+389-Macedonia",
    "value": "+389"
  },
  {
    "label": "+261-Madagascar",
    "value": "+261"
  },
  {
    "label": "+265-Malawi",
    "value": "+265"
  },
  {
    "label": "+60-Malaysia",
    "value": "+60"
  },
  {
    "label": "+960-Maldives",
    "value": "+960"
  },
  {
    "label": "+223-Mali",
    "value": "+223"
  },
  {
    "label": "+356-Malta",
    "value": "+356"
  },
  {
    "label": "+692-Marshall Islands",
    "value": "+692"
  },
  {
    "label": "+596-Martinique",
    "value": "+596"
  },
  {
    "label": "+222-Mauritania",
    "value": "+222"
  },
  {
    "label": "+230-Mauritius",
    "value": "+230"
  },
  {
    "label": "+262-Mayotte",
    "value": "+262"
  },
  {
    "label": "+52-Mexico",
    "value": "+52"
  },
  {
    "label": "+691-Micronesia, Federated States of Micronesia",
    "value": "+691"
  },
  {
    "label": "+373-Moldova",
    "value": "+373"
  },
  {
    "label": "+377-Monaco",
    "value": "+377"
  },
  {
    "label": "+976-Mongolia",
    "value": "+976"
  },
  {
    "label": "+382-Montenegro",
    "value": "+382"
  },
  {
    "label": "+1664-Montserrat",
    "value": "+1664"
  },
  {
    "label": "+212-Morocco",
    "value": "+212"
  },
  {
    "label": "+258-Mozambique",
    "value": "+258"
  },
  {
    "label": "+95-Myanmar",
    "value": "+95"
  },
  {
    "label": "+264-Namibia",
    "value": "+264"
  },
  {
    "label": "+674-Nauru",
    "value": "+674"
  },
  {
    "label": "+977-Nepal",
    "value": "+977"
  },
  {
    "label": "+31-Netherlands",
    "value": "+31"
  },
  {
    "label": "+599-Netherlands Antilles",
    "value": "+599"
  },
  {
    "label": "+687-New Caledonia",
    "value": "+687"
  },
  {
    "label": "+64-New Zealand",
    "value": "+64"
  },
  {
    "label": "+505-Nicaragua",
    "value": "+505"
  },
  {
    "label": "+227-Niger",
    "value": "+227"
  },
  {
    "label": "+234-Nigeria",
    "value": "+234"
  },
  {
    "label": "+683-Niue",
    "value": "+683"
  },
  {
    "label": "+672-Norfolk Island",
    "value": "+672"
  },
  {
    "label": "+1670-Northern Mariana Islands",
    "value": "+1670"
  },
  {
    "label": "+47-Norway",
    "value": "+47"
  },
  {
    "label": "+968-Oman",
    "value": "+968"
  },
  {
    "label": "+92-Pakistan",
    "value": "+92"
  },
  {
    "label": "+680-Palau",
    "value": "+680"
  },
  {
    "label": "+970-Palestinian Territory, Occupied",
    "value": "+970"
  },
  {
    "label": "+507-Panama",
    "value": "+507"
  },
  {
    "label": "+675-Papua New Guinea",
    "value": "+675"
  },
  {
    "label": "+595-Paraguay",
    "value": "+595"
  },
  {
    "label": "+51-Peru",
    "value": "+51"
  },
  {
    "label": "+63-Philippines",
    "value": "+63"
  },
  {
    "label": "+872-Pitcairn",
    "value": "+872"
  },
  {
    "label": "+48-Poland",
    "value": "+48"
  },
  {
    "label": "+351-Portugal",
    "value": "+351"
  },
  {
    "label": "+1939-Puerto Rico",
    "value": "+1939"
  },
  {
    "label": "+974-Qatar",
    "value": "+974"
  },
  {
    "label": "+40-Romania",
    "value": "+40"
  },
  {
    "label": "+7-Russia",
    "value": "+7"
  },
  {
    "label": "+250-Rwanda",
    "value": "+250"
  },
  {
    "label": "+262-Reunion",
    "value": "+262"
  },
  {
    "label": "+590-Saint Barthelemy",
    "value": "+590"
  },
  {
    "label": "+290-Saint Helena, Ascension and Tristan Da Cunha",
    "value": "+290"
  },
  {
    "label": "+1869-Saint Kitts and Nevis",
    "value": "+1869"
  },
  {
    "label": "+1758-Saint Lucia",
    "value": "+1758"
  },
  {
    "label": "+590-Saint Martin",
    "value": "+590"
  },
  {
    "label": "+508-Saint Pierre and Miquelon",
    "value": "+508"
  },
  {
    "label": "+1784-Saint Vincent and the Grenadines",
    "value": "+1784"
  },
  {
    "label": "+685-Samoa",
    "value": "+685"
  },
  {
    "label": "+378-San Marino",
    "value": "+378"
  },
  {
    "label": "+239-Sao Tome and Principe",
    "value": "+239"
  },
  {
    "label": "+966-Saudi Arabia",
    "value": "+966"
  },
  {
    "label": "+221-Senegal",
    "value": "+221"
  },
  {
    "label": "+381-Serbia",
    "value": "+381"
  },
  {
    "label": "+248-Seychelles",
    "value": "+248"
  },
  {
    "label": "+232-Sierra Leone",
    "value": "+232"
  },
  {
    "label": "+65-Singapore",
    "value": "+65"
  },
  {
    "label": "+421-Slovakia",
    "value": "+421"
  },
  {
    "label": "+386-Slovenia",
    "value": "+386"
  },
  {
    "label": "+677-Solomon Islands",
    "value": "+677"
  },
  {
    "label": "+252-Somalia",
    "value": "+252"
  },
  {
    "label": "+27-South Africa",
    "value": "+27"
  },
  {
    "label": "+211-South Sudan",
    "value": "+211"
  },
  {
    "label": "+500-South Georgia and the South Sandwich Islands",
    "value": "+500"
  },
  {
    "label": "+34-Spain",
    "value": "+34"
  },
  {
    "label": "+94-Sri Lanka",
    "value": "+94"
  },
  {
    "label": "+249-Sudan",
    "value": "+249"
  },
  {
    "label": "+597-Surivalue",
    "value": "+597"
  },
  {
    "label": "+47-Svalbard and Jan Mayen",
    "value": "+47"
  },
  {
    "label": "+268-Swaziland",
    "value": "+268"
  },
  {
    "label": "+46-Sweden",
    "value": "+46"
  },
  {
    "label": "+41-Switzerland",
    "value": "+41"
  },
  {
    "label": "+963-Syrian Arab Republic",
    "value": "+963"
  },
  {
    "label": "+886-Taiwan",
    "value": "+886"
  },
  {
    "label": "+992-Tajikistan",
    "value": "+992"
  },
  {
    "label": "+255-Tanzania, United Republic of Tanzania",
    "value": "+255"
  },
  {
    "label": "+66-Thailand",
    "value": "+66"
  },
  {
    "label": "+670-Timor-Leste",
    "value": "+670"
  },
  {
    "label": "+228-Togo",
    "value": "+228"
  },
  {
    "label": "+690-Tokelau",
    "value": "+690"
  },
  {
    "label": "+676-Tonga",
    "value": "+676"
  },
  {
    "label": "+1868-Trinidad and Tobago",
    "value": "+1868"
  },
  {
    "label": "+216-Tunisia",
    "value": "+216"
  },
  {
    "label": "+90-Turkey",
    "value": "+90"
  },
  {
    "label": "+993-Turkmenistan",
    "value": "+993"
  },
  {
    "label": "+1649-Turks and Caicos Islands",
    "value": "+1649"
  },
  {
    "label": "+688-Tuvalu",
    "value": "+688"
  },
  {
    "label": "+256-Uganda",
    "value": "+256"
  },
  {
    "label": "+380-Ukraine",
    "value": "+380"
  },
  {
    "label": "+971-United Arab Emirates",
    "value": "+971"
  },
  {
    "label": "+44-United Kingdom",
    "value": "+44"
  },
  {
    "label": "+1-United States",
    "value": "+1"
  },
  {
    "label": "+598-Uruguay",
    "value": "+598"
  },
  {
    "label": "+998-Uzbekistan",
    "value": "+998"
  },
  {
    "label": "+678-Vanuatu",
    "value": "+678"
  },
  {
    "label": "+58-Venezuela, Bolivarian Republic of Venezuela",
    "value": "+58"
  },
  {
    "label": "+84-Vietnam",
    "value": "+84"
  },
  {
    "label": "+1284-Virgin Islands, British",
    "value": "+1284"
  },
  {
    "label": "+1340-Virgin Islands, U.S.",
    "value": "+1340"
  },
  {
    "label": "+681-Wallis and Futuna",
    "value": "+681"
  },
  {
    "label": "+967-Yemen",
    "value": "+967"
  },
  {
    "label": "+260-Zambia",
    "value": "+260"
  },
  {
    "label": "+263-Zimbabwe",
    "value": "+263"
  }
]
export default function Index() {
  const Navigate = useNavigate()
  // const [currListAll, setcurrListAll] = useState([]);
  // const [selectERR, setSelectERR] = useState("");
  // const toAmt = useRef(0)
  // const [selectCheck, setselectCheck] = useState(false)
  // const [fromCurrency, setfromCurrency] = useState()
  const [loaderActSSO, setloaderActSSO] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [minAmt, setMinAmt] = useState(true)

  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNo] = useState("");
  // const [dob, setDOB] = useState("");
  const [nationality, setNationality] = useState("");
  const [cCode, setCcode] = useState("+93");
  const [check, setCheck] = useState(false);

  const [isPasswordValid, setPasswordValid] = useState(false);

  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [referralErr, setReferralErr] = useState("")
  const [fullNameErr, setFullNameErr] = useState("");
  const [mobileNumberErr, setMobileNoErr] = useState("");
  const [dobErr, setDOBErr] = useState("");
  const [check18, setCheck18] = useState(false)
  const [nationalityErr, setNationalityErr] = useState("");
  const [cCodeErr, setcCodeErr] = useState("");
  const [referralID, setReferral] = useState();
  const [checkERR, setCheckERR] = useState("");
  const [captchaErr, setCaptchaErr] = useState();
  const [isauthencate, setisauthencate] = useState(false);
  const [tokenCode, setTokenCode] = useState();

  const [isValidEmail, setValidEmail] = useState(false);
  const [isValidName, setValidName] = useState(false);
  const [isValidRefe, setValidRefe] = useState(true);
  const [loaderAct, setloaderAct] = useState(false);
  // const [country, setCountry] = useState([]);
  const [filterText, setFilterText] = useState("")
  // const [afterChnage, setafterChnage] = useState(false)

  const [captchaVal, setCaptchaVal] = useState(null)

  const handleCaptchaChange = (value) => {
    setCaptchaVal(value)
  }

  const [state, setstates] = useState({
    dateTwo: '',
  })


  const onChangeTwo = (as) => {
    console.log("date show", as)
    let now = parseInt((new Date()).getTime() / 1000) - 567993600;
    const timeVal = parseInt(Date.parse(new Date(as)) / 1000);

    if (as !== "") {
      if (timeVal < now) {
        setDOBErr("");
        setCheck18(false)
        setstates({ dateTwo: as })
      } else {
        setDOBErr("Must be 18 years old or above");
        setstates({ dateTwo: as })
        setCheck18(true)
      }

    } else {
      setDOBErr(" This field is required.");
    }
  }

  useEffect(() => {
    let isApiSubscribed = true
    function start() {
      gapi.client.init({
        clientId: ssoId,
        scope: '',
      });
    }

    if (isApiSubscribed) {
      gapi.load('client:auth2', start);
      let params = new URL(document.location).searchParams;
      let id = params.get("id");
      setReferral(id);
    }

    return () => {
      isApiSubscribed = false
    }

  }, []);


  // you can access the token like this


  const onSuccess = async (response) => {
    let data = qs.stringify({
      email: response.profileObj.email,
      fullname: response.profileObj.name,
      googleid: response.tokenId
    });
    setEmail(response.profileObj.email)
    setloaderActSSO(true)
    let result = await googleSignup(data);
    if (result) {
      // console.log(result, "resultresultresultresult")
      if (result.success === true) {
        if (result.isTFA !== true) {
          let userInfo = await getUserDetails(result.data);
          // console.log("check user info", userInfo)
          if (userInfo.data.data.userDetails.email !== "" && userInfo.data.data.userDetails.email !== undefined) {
            if (userInfo.data.data.userDetails.fullName !== undefined && userInfo.data.data.userDetails.fullName !== "") {
              if (userInfo.data.data.userDetails.nationality !== undefined && userInfo.data.data.userDetails.nationality !== "") {
                if (userInfo.data.data.userDetails.dob !== undefined && userInfo.data.data.userDetails.dob !== "") {
                  if (userInfo.data.data.userDetails.countryCode !== undefined && userInfo.data.data.userDetails.countryCode !== "") {
                    if (userInfo.data.data.userDetails.mob !== undefined && userInfo.data.data.userDetails.mob !== "") {
                      setloaderActSSO(false)
                      Swal.fire({
                        showConfirmButton: false,
                        text: result.msg,
                        button: false,
                        background: 'black',
                        icon: "success",
                        timer: 3000,
                      });
                      setTimeout(function () {
                        Navigate("/dashboard");
                      }, 3000);
                    }
                    else {
                      setloaderActSSO(false)
                      Swal.fire({
                        showConfirmButton: false,
                        text: result.msg,
                        button: false,
                        background: 'black',
                        icon: "success",
                        timer: 3000,
                      });
                      setTimeout(function () {
                        Navigate("/profile");
                      }, 3000);
                    }
                  }
                  else {
                    setloaderActSSO(false)
                    Swal.fire({
                      showConfirmButton: false,
                      text: result.msg,
                      button: false,
                      background: 'black',
                      icon: "success",
                      confirmButtonText: 'Close',
                      timer: 3000,
                    });
                    setTimeout(function () {
                      Navigate("/profile");
                    }, 3000);
                  }

                }
                else {
                  setloaderActSSO(false)
                  Swal.fire({
                    showConfirmButton: false,
                    text: result.msg,
                    button: false,
                    background: 'black',
                    icon: "success",
                    confirmButtonText: 'Close',
                    timer: 3000,
                  });
                  setTimeout(function () {
                    Navigate("/profile");
                  }, 3000);
                }

              }
              else {
                setloaderActSSO(false)
                Swal.fire({
                  showConfirmButton: false,
                  text: result.msg,
                  button: false,
                  background: 'black',
                  icon: "success",
                  confirmButtonText: 'Close',
                  timer: 3000,
                });
                setTimeout(function () {
                  Navigate("/profile");
                }, 3000);
              }
            }
            else {
              setloaderActSSO(false)
              Swal.fire({
                showConfirmButton: false,
                text: result.msg,
                button: false,
                background: 'black',
                icon: "success",
                confirmButtonText: 'Close',
                timer: 3000,
              });
              setTimeout(function () {
                Navigate("/profile");
              }, 3000);
            }
          }
          else {
            setloaderActSSO(false)
            Swal.fire({
              showConfirmButton: false,
              text: result.msg,
              button: false,
              background: 'black',
              icon: "success",
              confirmButtonText: 'Close',
              timer: 3000,
            });
            setTimeout(function () {
              Navigate("/profile");
            }, 3000);
          }
        }
        else {
          // console.log("noooo")
          setisauthencate(true)
        }

      } else {
        setloaderActSSO(false)
        Swal.fire({
          showConfirmButton: false,
          text: result.msg,
          button: false,
          background: 'black',
          icon: "error",
          confirmButtonText: 'Close',
          timer: 3000,
        });
      }

    } else {
      setloaderActSSO(false)
      Swal.fire({
        text: "Error while processing your request please try again later",
        button: false,
        background: "black",
        icon: "error",
        timer: 2000,
      });
    }

  };
  const onFailure = response => {
    // console.log('FAILED', response);
  };
  // const onLogoutSuccess = () => {
  //   // console.log('SUCESS LOG OUT');
  // };

  const handleOnChangeEmail = (event) => {
    setEmail(event.target.value);
    let emailIDVal = /^(?!.{255})(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let emailIDTrue = emailIDVal.test(event.target.value);
    if (emailIDTrue) {
      // console.log("emailIDTrue", emailIDTrue)
      let email1 = event.target.value.split("@")
      let email2 = email1[email1.length - 1].split(".")
      if (email2.length < 4) {
        let email3 = identical(email2)
        if (!email3) {
          setValidEmail(true);
          setEmailErr('')
        }
        else {
          setEmailErr('Invalid Domain Names')
        }
      }
      else {
        setEmailErr('Invalid Domain Names')
      }
    } else {
      if (event.target.value === "") {
        setValidEmail(false);
        setEmailErr(" This field is required");
      } else {
        setValidEmail(false);
        setEmailErr(" Invalid Email");
      }
    }
  };
  const identical = (myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      for (var j = 0; j < myArray.length; j++) {
        if (i !== j) {
          if (myArray[i] === myArray[j]) {
            return true; // means there are duplicate values
          }
        }
      }
    }
    return false; // means there are no duplicate values.
  }

  const handleOnChangeFullName = (event) => {
    setFullName(event.target.value);
    let fullNameValue = /^[a-zA-Z ]{1,256}$/;
    let fullNameValueTrue = fullNameValue.test(event.target.value);
    if (fullNameValueTrue) {
      setFullNameErr("");
      setValidName(true)
    } else {
      if (event.target.value === "") {
        setFullNameErr(" This field is required.");
      } else {
        setFullNameErr(" Must contain only alphabets.");
      }
    }

  };

  const handleOnChangeMobileNumber = (event) => {
    setMobileNo(event.target.value)
    let mobileNumberValue = /^[0-9]{9,20}$/
    let mobileNumberTrue = mobileNumberValue.test(event.target.value);
    if (mobileNumberTrue) {
      setMobileNoErr("");
    } else {
      if (event.target.value === "") {
        setMobileNoErr(" This field is required.");
      } else {
        setMobileNoErr(" Minimum mobile number length 9 digits");
      }
    }
  };

  const handleOnChangePassword = (event) => {
    setPassword(event.target.value);
    let passVal = /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d@#$^!%*?&_]{6,}$/;
    let passValTrue = passVal.test(event.target.value);
    if (passValTrue) {
      setPasswordValid(true);
      setPasswordErr("");
    } else {
      if (event.target.value === "") {
        setPasswordErr(" This field is required.");
      } else {
        setPasswordErr(
          " Must contain at least one uppercase and lowercase letter, and at least 6 or more characters"
        );
      }
    }
  };

  const handleOnChangeNationality = (value) => {
    // console.log("vale", value)
    if (value !== "") {
      setNationalityErr("");
    } else {
      setNationalityErr(" This field is required.");
    }
  };

  // const handleOnChangeCcode = (value) => {
  //   // console.log("value", value, cCode)
  //   if (value !== "") {
  //     setcCodeErr("");
  //   } else {
  //     setcCodeErr(" This field is required.");
  //   }
  // };

  const handleOnChangeConfirmPassword = (value) => {
    if (value !== "") {
      setConfirmPasswordErr("");
    } else {
      setConfirmPasswordErr(" This field is required.");
    }
  };

  const handleOnCheck = (event) => {
    setCheck(!check)
  };

  const handleRefrral = (event) => {
    let referrlcheck = /^[A-Za-z0-9]*$/;
    let referralValuefull = referrlcheck.test(event.target.value);
    // console.log("results", referralValuefull)
    if (referralValuefull) {
      setReferral(event.target.value)
      setValidRefe(true)
      setReferralErr("")
    }
    // else{
    //   setValidRefe(false)
    //   setReferralErr(" This field is required.");
    // }
    else {
      if (event.target.value === "") {
        setValidRefe(false);
        setReferralErr(" This field is required");
      } else {
        setValidRefe(false);
        setReferralErr(" Invalid referral");
      }
    }
  }

  // const handleChange = async (selectedOption) => {
  //   // console.log("frgre",(selectedOption.value).split(" ")[0])
  //   setfromCurrency(selectedOption.value)
  //   // setFullNameCrr((selectedOption.value).split(" ")[1]);
  //   // setFullNameCrr1((selectedOption.value).split(" ")[2] !== null && (selectedOption.value).split(" ")[2] !== '' &&  (selectedOption.value).split(" ")[2] !== undefined ? (selectedOption.value).split(" ")[2] : '');
  //   // setFullNameCrr2((selectedOption.value).split(" ")[3] !== null && (selectedOption.value).split(" ")[3] !== '' &&  (selectedOption.value).split(" ")[3] !== undefined ? (selectedOption.value).split(" ")[3] : '');
  //   // console.log("on slect chnage", 1, toCurrency, selectedOption.value)
  //   // if (toCurrency && (selectedOption.value).split(" ")[0]) {
  //   setselectCheck(true);
  //   // setloaderAct(true)
  //   // let res = await changeCurrToSelected(1, toCurrency, (selectedOption.value).split(" ")[0]);
  //   // if(res){
  //   //     setloaderAct(false)
  //   //     console.log("res", res)
  //   //     setgetCurrentPriceSelected(res.data.estimated_amount)
  //   //     console.log("to from cal", toAmt.current.value, fromAmt.current.value, getCurrentPriceSelected)
  //   //     fromAmt.current.value = toAmt.current.value * res.data.estimated_amount;
  //   // }
  //   // else{
  //   //     Swal.fire({
  //   //         text: "Error while processing your request please try again later",
  //   //         button: false,
  //   //         background: "black",
  //   //         icon: "error",
  //   //         timer: 2000,
  //   //     });
  //   // }
  //   // }
  // }

  // const getCurrToSelected = async () => {
  //   // console.log("tokenPr", tokenPr)
  //   if (toAmt.current.value !== '' && toAmt.current.value >= 0) {
  //     // settotalGoltToken((toAmt.current.value / tokenPr).toFixed(8))
  //     // fromAmt.current.value = (toAmt.current.value * getCurrentPriceSelected).toFixed(8);
  //     if (toAmt.current.value >= 50) {
  //       setMinAmt(true)
  //     }
  //     else {
  //       setMinAmt(false)
  //     }
  //   }
  //   else {
  //     toAmt.current.value = '';
  //     // fromAmt.current.value = '';
  //     // settotalGoltToken(0)
  //     setMinAmt(true)
  //   }
  // }

  // const fatchCurrencyAll = async () => {
  //   const res = await getCurrencyList();
  //   // console.log("fatchCurrencyAll", res)
  //   let data = [];
  //   for (let i = 0; i < res.data.currencies.length; i++) {
  //     data[i] = { "value": res.data.currencies[i].code + ' ' + res.data.currencies[i].name, "label": <div className='optionlist'><img src={"https://nowpayments.io/" + res.data.currencies[i].logo_url} height="28px" width="28px" alt="" /> <div className='bigName'>{res.data.currencies[i].name}&nbsp;&nbsp;<span className='bigNamesmall'>{"(" + res.data.currencies[i].code + ")"}</span></div></div> }
  //   }
  //   setcurrListAll(data);
  //   // console.log(data)
  // };


  const register = async () => {
    let MyDate = new Date(state.dateTwo);
    let MyDateString = MyDate.getFullYear() + "-" + ("0" + (MyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + MyDate.getDate()).slice(-2);
    setCheckERR("");
    if (email !== "" && email !== undefined) {
      if (fullName !== undefined && fullName !== "") {
        if (password !== undefined && password !== "") {
          if (confirmPassword !== undefined && confirmPassword !== "") {
            if (cCode !== undefined && cCode !== "") {
              if (check18 === false) {
                if (captchaVal !== '' && captchaVal !== undefined && captchaVal !== null) {
                  if (isValidEmail) {
                    if (isPasswordValid) {
                      if (isValidName) {
                        if (isValidRefe) {
                          if (check) {
                            setloaderAct(true)
                            let data;

                            data = {
                              email: email,
                              fullname: fullName,
                              password: password,
                              re_password: confirmPassword,
                              countryCode: cCode,
                              captcha: captchaVal
                            };
                            if (mobileNumber !== undefined && mobileNumber !== "") {
                              data["mob"] = mobileNumber
                            }
                            if (nationality !== undefined && nationality !== "" && nationality !== 'Nationality') {
                              data["nationality"] = nationality
                            }
                            if (MyDateString !== undefined && MyDateString !== "" && MyDateString !== 'NaN-aN-aN') {
                              data["dob"] = MyDateString
                            }
                            if (referralID) {
                              data["referralid"] = referralID
                            }

                            let response = await signup(qs.stringify(data));
                            if (!response) {
                              Swal.fire({
                                text: "Error while processing your request please try again later",
                                button: false,
                                background: "black",
                                icon: "error",
                                timer: 2000,
                              });
                            } else {
                              if (response.success === true) {
                                Swal.fire({
                                  showConfirmButton: false,
                                  text: "Thank you, to complete the registration process, please check your email for the verification link.",
                                  button: false,
                                  background: 'black',
                                  icon: "success",
                                  confirmButtonText: 'Close',
                                  timer: 4000,
                                });
                                setTimeout(function () {
                                  Navigate("/dashboard");                            
                                }, 4000);
                                setloaderAct(false)
                                setEmail('');
                                setPassword('');
                                setConfirmPassword('');
                                setFullName('');
                                setMobileNo('');
                                // setDOB('DOB');
                                setNationality();
                                setCaptchaVal(null)
                                setCaptchaErr("")
                                setstates({ dateTwo: '' })
                                setNationality('')
                                setValidEmail(false)
                                setCheck(false)
                                window.grecaptcha.reset();
                              } else {
                                setloaderAct(false)
                                setCaptchaVal(null)
                                window.grecaptcha.reset();
                                Swal.fire({
                                  showConfirmButton: false,
                                  text: response.msg,
                                  button: false,
                                  background: 'black',
                                  icon: "error",
                                  confirmButtonText: 'Close',
                                  timer: 4000,
                                });
                              }
                            }

                          } else {
                            setCheckERR(' This field is required')
                          }

                        }
                        else {
                          setReferralErr(' Enter valid referral address')
                        }
                      }
                      else {
                        setFullNameErr(' Full Name contain only alphabets')
                      }

                    } else {
                      setPasswordErr(" Password is invalid");
                    }
                  } else {
                    setEmailErr(" Email not Verified");
                  }
                } else {
                  setCaptchaErr(" This field is required")
                }
                // }
                // else {
                //     Swal.fire({
                //         showConfirmButton: false, button: false,
                //         text: 'Please enter amount in usd',
                //         button: false,
                //         background: 'black',
                //         icon: "error",
                //         confirmButtonText: 'Close',
                //         timer: 4000,
                //     });
                // }
                // } else {
                //   setSelectERR(' This field is required')
                // }

              } else {
                setDOBErr(" Must be 18 years old or above.");
              }

              // } else {
              //   setMobileNoErr(" This field is required");
              // }
            }
            else {
              setcCodeErr("Countery code required");
            }

            // } else {
            //   setNationalityErr(" This field is required");
            // }
          } else {
            setConfirmPasswordErr(" This field is required");
          }
        } else {
          setPasswordErr(" This field is required");
        }
      } else {
        setFullNameErr(" This field is required");
      }
    } else {
      setEmailErr(" This field is required");
    }
  };


  const onChangeCont = option => {
    setFilterText(option);
    setCcode(option.value)
  };


  const handleWebchat = () => {
    const zohoChat = document.querySelector(".zsiq_floatmain");
    const zohoChatModel = document.querySelector(".zls-sptwndw");
    zohoChat.classList.add("zsiqfanim");
    zohoChatModel.classList.add("siqanim");
    zohoChatModel.classList.remove("siqhide");
    zohoChatModel.style.cssText = `
          top: unset;
          bottom: 0;
      `
  }
  const customStyles = {
    singleValue: (provided) => ({
      ...provided,
      color: '#0000',
    }),
  }

  const verify2faLogin = async () => {
    if (tokenCode) {
      setloaderAct(true)
      let resLogin1 = await login2FAApi(email, tokenCode);
      if (resLogin1) {
        // setloaderAct(false)
        if (resLogin1.data.success) {

          let userInfo = await getUserDetails(resLogin1.data.data);
          // console.log("check user info",userInfo)
          if (userInfo.data.data.userDetails.email !== "" && userInfo.data.data.userDetails.email !== undefined) {
            if (userInfo.data.data.userDetails.fullName !== undefined && userInfo.data.data.userDetails.fullName !== "") {
              if (userInfo.data.data.userDetails.nationality !== undefined && userInfo.data.data.userDetails.nationality !== "") {
                if (userInfo.data.data.userDetails.dob !== undefined && userInfo.data.data.userDetails.dob !== "") {
                  if (userInfo.data.data.userDetails.countryCode !== undefined && userInfo.data.data.userDetails.countryCode !== "") {
                    if (userInfo.data.data.userDetails.mob !== undefined && userInfo.data.data.userDetails.mob !== "") {
                      setloaderAct(false)
                      Swal.fire({
                        showConfirmButton: false,
                        text: resLogin1.data.msg,
                        background: 'black',
                        icon: "success",
                        confirmButtonText: false,
                        timer: 3000,
                      });
                      setTimeout(function () {
                        Navigate('/dashboard')
                      }, 3000);
                    } else {
                      setloaderAct(false)
                      Swal.fire({
                        text: resLogin1.data.msg,
                        background: 'black',
                        icon: "success",
                        confirmButtonText: false,
                        timer: 3000,
                      });
                      setTimeout(function () {
                        Navigate('/profile')
                        // Navigate({
                        //   pathname: '/buy-token',
                        //   search: `?currency=${fromCurrency}&amount=${toAmt.current.value}`,
                        // });
                      }, 3000);
                    }
                  }
                  else {
                    setloaderAct(false)
                    Swal.fire({
                      showConfirmButton: false,
                      text: resLogin1.data.msg,
                      background: 'black',
                      icon: "success",
                      confirmButtonText: false,
                      timer: 3000,
                    });
                    setTimeout(function () {
                      Navigate("/profile");
                      // Navigate({
                      //   pathname: '/buy-token',
                      //   search: `?currency=${fromCurrency}&amount=${toAmt.current.value}`,
                      // });
                    }, 3000);
                  }

                }
                else {
                  setloaderAct(false)
                  Swal.fire({
                    showConfirmButton: false,
                    text: resLogin1.data.msg,
                    background: 'black',
                    icon: "success",
                    confirmButtonText: false,
                    timer: 3000,
                  });
                  setTimeout(function () {
                    Navigate("/profile");
                    // Navigate({
                    //   pathname: '/buy-token',
                    //   search: `?currency=${fromCurrency}&amount=${toAmt.current.value}`,
                    // });
                  }, 3000);
                }

              }
              else {
                setloaderAct(false)
                Swal.fire({
                  showConfirmButton: false,
                  text: resLogin1.data.msg,
                  background: 'black',
                  icon: "success",
                  confirmButtonText: false,
                  timer: 3000,
                });
                setTimeout(function () {
                  Navigate("/profile");
                  // Navigate({
                  //   pathname: '/buy-token',
                  //   search: `?currency=${fromCurrency}&amount=${toAmt.current.value}`,
                  // });
                }, 3000);
              }
            }
            else {
              setloaderAct(false)
              Swal.fire({
                showConfirmButton: false,
                text: resLogin1.data.msg,
                confirmButtonText: false,
                background: 'black',
                icon: "success",
                timer: 3000,
              });
              setTimeout(function () {
                Navigate("/profile");
                // Navigate({
                //   pathname: '/buy-token',
                //   search: `?currency=${fromCurrency}&amount=${toAmt.current.value}`,
                // });
              }, 3000);
            }
          }
          else {
            setloaderAct(false)
            Swal.fire({
              showConfirmButton: false,
              text: resLogin1.data.msg,
              confirmButtonText: false,
              background: 'black',
              icon: "success",
              timer: 3000,
            });
            setTimeout(function () {
              Navigate("/profile");
              // Navigate({
              //   pathname: '/buy-token',
              //   search: `?currency=${fromCurrency}&amount=${toAmt.current.value}`,
              // });
            }, 3000);
          }


          setloaderAct(false)
          // Swal.fire({ showConfirmButton:false,  text: resLogin1.data.msg, icon: 'success', background: 'black',confirmButtonText: false,  timer: 3000 });
          setTokenCode('')
          // setTimeout(function () {
          //     Navigate("/dashboard");
          // }, 3000);


        }
        else {
          setloaderAct(false)
          Swal.fire({ showConfirmButton: false, text: resLogin1.data.msg, icon: 'error', background: 'black', confirmButtonText: false, timer: 3000 });
        }
      }
      else {
        Swal.fire({
          text: "Error while processing your request please try again later",
          background: "black",
          icon: "error",
          timer: 2000,
        });
      }

    }
    else {
      setloaderAct(false)
      Swal.fire({ text: 'Please enter token code', icon: 'error', background: 'black', confirmButtonText: false, timer: 3000 });
    }
  }

  const submitOnEnter = (event) => {
    if (event.key === "Enter") {
      register();
    }
  }
  const submitOnEnterTwoFA = (event) => {
    if (event.key === "Enter") {
      verify2faLogin();
    }
  }
  // const getcountryCode = async () => {
  //   setCountry(countryCode);
  // }

  // useEffect(() => {
  //   getcountryCode();
  //   // fatchCurrencyAll();

  // }, []);


  return (
    <>
      <Helmet>
        <style>{"body { background-color: #000; } html{background-color: #000;}"}</style>
        <title>Register | Golteum</title>
      </Helmet>
      <div className="cssForAll">
        <div className="login-wrap ">
          <div className="start-box">
            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
          </div>
          <div className="mainSection">
            <div className="container">
              <div className="row firstrow">
                <div className="col-lg-6 col-md-12">
                  <div className="sec2Col">
                    <div className="row deskTopcss">
                      <div className="col-md-12">
                        <div className='middletext'>
                          <h3>We accept two payment methods:</h3>
                          <p>1. &nbsp;Pay with Crypto from ANY Wallet [150+ cryptocurrencies supported]</p>
                          <p>2. Pay with Metamask [ETH, BNB, USDT supported]&nbsp; <img className="mmsize" src="images/metamasksmall.png" alt="" /></p>
                        </div>
                        <div className="stepSec">
                          <h2>
                            Join the Golteum Presale
                          </h2>
                          <p>1. &nbsp;Signup</p>
                          <p>2. Email verification</p>
                          <p>3. Login</p>
                          <p>4. Buy token via dashboard</p>
                          <p>5. Get purchase details via email</p>
                          <p>6. See transaction history via dashboard</p><br></br>
                          <p>Contract Address:</p>
                          <p className="addfont"><a href="https://etherscan.io/address/0xf72be912c9e3683d58a5fc0bbbbe07390be39b88" target='_blank' style={{ wordBreak: "break-all" }}>0xf72be912c9e3683d58a5fc0bbbbe07390be39b88</a></p>
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                        <div className="leftTocImg">
                          <h2>
                            Distribution will take place after the Presale Ends
                          </h2>
                          <img src={DistributionImg} alt="" />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div>
                    <div className="row secondinnerflex">
                      <div className="col-md-12">
                        {!isauthencate
                          ?
                          <div className="login-box signup-form">
                            <div className="login-logo">
                              <a href="https://golteum.io/">
                                <img className="logoSize" src="images/logo.png" alt="" />
                              </a>
                              <h4>Register your account!</h4>

                            </div>

                            <div className="login-form">
                              <div className="row">
                                <div className="col-md-6 px-0">

                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        name=""
                                        value={fullName}
                                        className="form-control"
                                        onChange={(event) =>
                                          handleOnChangeFullName(event)
                                        }
                                        placeholder="Enter full name"
                                        onKeyUp={submitOnEnter}
                                      />
                                      <div>
                                        <span style={{ color: "red" }}>{fullNameErr}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group emailVaild">
                                      <input
                                        type="text"
                                        name=""
                                        className="form-control"
                                        value={email}
                                        onChange={(event) => handleOnChangeEmail(event)}
                                        placeholder="Enter Email"
                                        onKeyUp={submitOnEnter}
                                      />
                                      <div>
                                        <span style={{ color: "red" }}>{emailErr}</span>
                                      </div>
                                      {isValidEmail ? <img className="hideVerified" src="images/check.svg" alt="" /> : ''}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group password-must-hv">
                                      <input
                                        type="password"
                                        name=""
                                        className="form-control"
                                        value={password}
                                        placeholder="Enter Password"
                                        onChange={(event) => handleOnChangePassword(event)}
                                        onKeyUp={submitOnEnter}
                                      />
                                      <div>
                                        <span style={{ color: "red" }}>{passwordErr}</span>
                                      </div>
                                      {/* <div
                    className="password-alert"
                    id="showDiv"
                    style={{ display: "none" }}
                  >
                    <h6>The password must have</h6>
                    <p className="">
                      <i
                        className="fa fa-times-circle"
                        aria-hidden="true"
                      ></i>{" "}
                      Be at least 8 characters in length
                    </p>
                    <p>
                      <i
                        className="fa fa-times-circle"
                        aria-hidden="true"
                      ></i>{" "}
                      Contain both upper and lowercase alphabetic characters
                      (e.g. A-Z, a-z)
                    </p>
                    <p>
                      <i
                        className="fa fa-times-circle"
                        aria-hidden="true"
                      ></i>{" "}
                      Have at least one numerical character (e.g. 0-9)
                    </p>
                    <p>
                      <i
                        className="fa fa-times-circle"
                        aria-hidden="true"
                      ></i>{" "}
                      Have at least one special character (e.g.
                      ~!@#$%^&*()_-+=)
                    </p>
                  </div> */}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="password"
                                        name=""
                                        className="form-control"
                                        value={confirmPassword}
                                        onChange={(event) =>
                                          handleOnChangeConfirmPassword(
                                            setConfirmPassword(event.target.value)
                                          )
                                        }
                                        placeholder="Enter re-password"
                                        onKeyUp={submitOnEnter}
                                      />
                                      <div>
                                        <span style={{ color: "red" }}>{confirmPasswordErr}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6 px-0">
                                  <div className="col-md-12">
                                    <div className="form-group tel-group commonSelect">
                                      <p className="cunteryCode">{cCode}</p>
                                      <Select id="ccCode"
                                        className="styled-selectcc"
                                        onChange={onChangeCont}
                                        styles={customStyles}
                                        placeholder={false}
                                        options={newCountryCodes}
                                        value={filterText.value1}
                                      />
                                      {/* <select className="styled-select"
                          onChange={(event) =>
                            handleOnChangeCcode(
                              setCcode(event.target.value)
                            )
                          }
                        >
                          <option value=""></option>
                          {
                            country.map((item) => {
                              return (
                                <option value={item.dial_code}>{item.dial_code}-{item.name} </option>
                              )
                            })
                          }
                        </select> */}
                                      <input
                                        type="number"
                                        name=""

                                        value={mobileNumber}
                                        className="form-control"
                                        onChange={(event) =>
                                          handleOnChangeMobileNumber(event)
                                        }
                                        placeholder="Phone number"
                                        // onKeyUp={submitOnEnter}
                                        onKeyPress={(event) => { if ((/[^0-9.]$/).test(event.key)) { event.preventDefault(); } }}
                                      />
                                      <div>
                                        <span style={{ color: "red" }}>{mobileNumberErr}</span>
                                      </div>
                                      <div>
                                        <span style={{ color: "red" }}>{cCodeErr}</span>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-md-12">
                      <div className="form-group tel-group commonSelect">
                        <p className="cunteryCode">{cCode}</p>
                        <select className="styled-select"
                          onChange={(event) =>
                            handleOnChangeCcode(
                              setCcode(event.target.value)
                            )
                          }
                        >
                          <option value=""></option>
                          {
                            country.map((item) => {
                              return (
                                <option value={item.dial_code}>{item.dial_code}-{item.name} </option>
                              )
                            })
                          }
                        </select>
                        <input
                          type="number"
                          name=""

                          value={mobileNumber}
                          className="form-control"
                          onChange={(event) =>
                            handleOnChangeMobileNumber(event)
                          }
                          placeholder="Phone number"
                          // onKeyUp={submitOnEnter}
                          onKeyPress={(event) => { if ((/[^0-9.]$/).test(event.key)) { event.preventDefault(); } }}
                        />
                        <div>
                          <span style={{ color: "red" }}>{mobileNumberErr}</span>
                        </div>
                        <div>
                          <span style={{ color: "red" }}>{cCodeErr}</span>
                        </div>
                      </div>
                    </div> */}

                                  <div className="col-md-12">
                                    <div className="form-group dob-box">
                                      {/* <input
                      name=""
                      id="date"
                      className="form-control"
                      value={dob}
                      type={type}
                      placeholder="DOB"
                      onFocus={() => setType('date')}
                      onBlur={() => setType('text')}
                      onChange={(event) =>
                        handleOnChangeDOB(setDOB(event.target.value))
                      }
                    /> */}
                                      {/* <p className="react-date-picker--closed">close</p>
                        <p className="react-date-picker--open">open</p> */}

                                      <DatePicker
                                        className="datapicker12"
                                        value={state.dateTwo}
                                        maxDate={new Date()}
                                        onChange={(event) => { onChangeTwo(event) }}

                                      />
                                      <p className={state.dateTwo === '' || state.dateTwo === null ? "placeText" : 'hideafter'}>DOB</p>
                                      <div>
                                        <span style={{ color: "red" }}>{dobErr}</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12" style={{ visibility: "hidden", height: "0" }}>
                                    <div className="form-group commonSelect ">

                                      <select
                                        className="form-control styled-select"
                                        onChange={(event) =>
                                          handleOnChangeNationality(
                                            setNationality(event.target.value)
                                          )
                                        }
                                        required>
                                        {
                                          nationality === '' ? <option value="" disabled selected hidden>Nationality</option> : ''
                                        }

                                        {nationality_list.map((item) => {
                                          return nationality === item.name ? (<option value={item.name} selected>{item.name}</option>) : (<option value={item.name}>{item.name}</option>)
                                        })}
                                      </select>
                                      <div>
                                        <span style={{ color: "red" }}>{nationalityErr}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        name=""
                                        className="form-control"
                                        placeholder="Enter Referral ID"
                                        value={referralID}
                                        onChange={handleRefrral}
                                        onKeyUp={submitOnEnter}
                                      />
                                      <div>
                                        <span style={{ color: "red" }}>{isValidRefe}</span>
                                      </div>
                                      <div>
                                        <span style={{ color: "red" }}>{referralErr}</span>
                                      </div>
                                    </div>

                                  </div>

                                </div>

                                <div className="col-md-12">
                                  <div className="form-check mb-4">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={check}
                                      id="invalidCheck3"
                                      onChange={(event) => handleOnCheck(event)}
                                      required
                                    />
                                    <label className={check ? "form-check-label textColor" : 'form-check-label'} htmlFor="invalidCheck3">
                                      By clicking here, I state that I have read and understood the <a href={terms} target="_blank" rel="noreferrer">terms and conditions</a> and <a href={privacyPolicy} target="_blank" rel="noreferrer">privacy policy</a>.
                                    </label>
                                    <div>
                                      <span style={{ color: "red" }}>{checkERR}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='captcha'>
                                <Captcha handleCaptchaChange={handleCaptchaChange} />
                              </div>
                              <div className='text-center'>
                                <span style={{ color: "red" }} >{captchaErr}</span>
                              </div>
                              {loaderAct ? <button className="btn btn-yellow w-100 mt-4" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> :
                                <button className="btn btn-yellow w-100 mt-4" onClick={register}>Signup</button>
                              }
                              {/* <button className="btn btn-yellow w-100 mt-4" onClick={emailOTP}>Signup</button>               */}



                              <div className="or-box">
                                <span>OR</span>
                              </div>
                              <div className="social-login">
                                <div>
                                  {
                                    loaderActSSO
                                      ?
                                      <button className="btn btn-yellow w-100 mt-4" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button>
                                      :
                                      <GoogleLogin
                                        clientId={ssoId}
                                        onSuccess={onSuccess}
                                        buttonText="Signup with Google"
                                        onFailure={onFailure}
                                        cookiePolicy={"single_host_origin"}
                                      />
                                  }
                                  {/* <GoogleLogout
                clientId={ssoId}
                onLogoutSuccess={onLogoutSuccess}
              /> */}
                                </div>
                                {/* )} */}
                                {/* {loginStatus && ( */}
                                <div>
                                  {/* <h2>Welcome {name}</h2> */}
                                  {/* <h2>Email: {email}</h2> */}
                                  {/* <img src={url} alt="" /> */}
                                  {/* <GoogleLogout
                  clientId="858371677869-2035ce792bvu6s2splrqek98dq88sqd3.apps.googleusercontent.com"
                  buttonText="Logout"
                  onLogoutSuccess={logout}
                /> */}
                                </div>
                                {/* )} */}
                              </div>
                              <div className="account-new">
                                <p>
                                  Already have an account? <Link to="/">Login now</Link>
                                </p>
                              </div>
                            </div>
                          </div>
                          :
                          <div className='login-box'>
                            <div className="login-logo authclass">
                              <Link to="/">
                                <img className="logoSize" src="images/logo.png" alt="" />
                              </Link>
                              <h4>Authenticate Your Account</h4>
                              <p>Please enter Authentication code from google Authenticator</p>

                            </div>
                            <div className="form-group">
                              <input type="number" className='form-control' placeholder="Enter Token Code" value={tokenCode} onChange={(event) => { setTokenCode(event.target.value) }} onKeyUp={submitOnEnterTwoFA} />
                              {loaderAct ? <button className="btn btn-yellow w-100 mt-4" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> : <button className="btn btn-yellow w-100 mt-4" onClick={verify2faLogin}>Submit</button>}
                              <p className='text-center mt-3'>Forgot or Lost 2FA? Please contact our live <a href="#" onClick={handleWebchat} rel="noopener">support</a></p>
                            </div>
                          </div>
                        }
                      </div>
                      {/* <div className="col-md-12">
                        <div className="deskImage">
                          <img src={laptopDesk} alt="" />
                        </div>
                      </div> */}
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>





          <div className="footer-item">
            <h4>JOIN OUR COMMUNITY</h4>
            <div className="social-login-ic">
              <a href="https://twitter.com/golteum?s=20&t=Kirb38vwigNjUq0GgWCyUA" target="_blank" rel="noreferrer">
                <i className='bi bi-twitter'></i>
              </a>
              <a href="https://t.me/golteum" target="_blank" rel="noreferrer">
                <i className='bi bi-telegram'></i>
              </a>
              <a href="https://instagram.com/golteum?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
                <i className='bi bi-instagram'></i>
              </a>
              <a href="https://www.facebook.com/profile.php?id=100082846137280" target="_blank" rel="noreferrer">
                <i className='bi bi-facebook'></i>
              </a>
            </div>
            <div className="link-f">
              <a href={privacyPolicy} target="_blank" rel="noreferrer" >PRIVACY POLICY</a>
              {/* <Link to="/privacy-policy">PRIVACY POLICY</Link> */}
              {/* <Link to="/term">TERMS OF USE</Link> */}
            </div>
            <div className='txtds'>
              <p>Golteum is committed to ensuring the security and protection of the personal information that we process, and to provide a compliant and consistent approach to data protection. We will only be using your contact details to better serve you during the presale and secure your GLTM tokens at the TGE. However please note you are not obliged to leave your contact details and we will be proving all users the option to remove their data after the TGE.</p>
            </div>
            <div className="copy-r">© 2024 Golteum.io - All rights reserved.</div>
          </div>
        </div>
      </div>
    </>
  );
}
