/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { userLogin, googleSignup, login2FAApi, getUserDetails, privacyPolicy, ssoId } from 'components/helper/userHelper';
import Swal from 'sweetalert2';
import '../Css/Custom.css'
import { gapi } from 'gapi-script';
import { GoogleLogin } from "react-google-login";
import Captcha from 'components/Common/Captcha';

export default function Index() {
    const Navigate = useNavigate();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [emailErr, setEmailErr] = useState();
    const [passwordErr, setPasswordErr] = useState();
    const [isValidEmail, setValidEmail] = useState(false);
    const [loaderAct, setloaderAct] = useState(false);
    const [loaderActSSO, setloaderActSSO] = useState(false);
    const [isauthencate, setisauthencate] = useState(false);
    const [tokenCode, setTokenCode] = useState();
    const [captchaErr, setCaptchaErr] = useState();

    const [captchaVal, setCaptchaVal] = useState(null)

    const handleCaptchaChange = (value) => {
        setCaptchaVal(value)
    }

    useEffect(() => {
        let isAPISubscribed = true;
        function start() {
            gapi.client.init({
                clientId: ssoId,
                scope: '',
            });
        }
        if (isAPISubscribed) {
            gapi.load('client:auth2', start);
        }

        return () => {
            gapi.load('client:auth2', start);
        }

    }, []);


    // **you can access the token like this**


    const onSuccess = async (response) => {
        let data = qs.stringify({
            email: response.profileObj.email,
            fullname: response.profileObj.name,
            googleid: response.tokenId
        });
        setloaderActSSO(true)
        let result = await googleSignup(data);
        if (!result) {
            setloaderActSSO(false)
            Swal.fire({
                text: "Error while processing your request please try again later",
                button: false,
                background: "black",
                icon: "error",
                timer: 2000,
            });
        } else {
            if (result.success === true) {
                if (result.isTFA !== true) {
                    // console.log("hiiiiii")
                    let userInfo = await getUserDetails(result.data);
                    if (userInfo.data.data.userDetails.email !== "" && userInfo.data.data.userDetails.email !== undefined) {
                        if (userInfo.data.data.userDetails.fullName !== undefined && userInfo.data.data.userDetails.fullName !== "") {
                            if (userInfo.data.data.userDetails.nationality !== undefined && userInfo.data.data.userDetails.nationality !== "") {
                                if (userInfo.data.data.userDetails.dob !== undefined && userInfo.data.data.userDetails.dob !== "") {
                                    if (userInfo.data.data.userDetails.countryCode !== undefined && userInfo.data.data.userDetails.countryCode !== "") {
                                        if (userInfo.data.data.userDetails.mob !== undefined && userInfo.data.data.userDetails.mob !== "") {
                                            setloaderActSSO(false)
                                            Swal.fire({
                                                showConfirmButton: false,
                                                text: result.msg,
                                                background: 'black',
                                                icon: "success",
                                                confirmButtonText: false,
                                                timer: 3000,
                                            });
                                            setTimeout(function () {
                                                Navigate('/dashboard')
                                            }, 3000);
                                        }
                                        else {
                                            setloaderActSSO(false)
                                            Swal.fire({
                                                text: result.msg,
                                                background: 'black',
                                                icon: "success",
                                                confirmButtonText: false,
                                                timer: 3000,
                                            });
                                            setTimeout(function () {
                                                Navigate('/profile')
                                            }, 3000);
                                        }
                                    }
                                    else {
                                        setloaderActSSO(false)
                                        Swal.fire({
                                            showConfirmButton: false,
                                            text: result.msg,
                                            background: 'black',
                                            icon: "success",
                                            confirmButtonText: false,
                                            timer: 3000,
                                        });
                                        setTimeout(function () {
                                            window.location.href = window.location.origin + "/profile";
                                        }, 3000);
                                    }

                                }
                                else {
                                    setloaderActSSO(false)
                                    Swal.fire({
                                        showConfirmButton: false,
                                        text: result.msg,
                                        background: 'black',
                                        icon: "success",
                                        confirmButtonText: false,
                                        timer: 3000,
                                    });
                                    setTimeout(function () {
                                        window.location.href = window.location.origin + "/profile";
                                    }, 3000);
                                }

                            }
                            else {
                                setloaderActSSO(false)
                                Swal.fire({
                                    showConfirmButton: false,
                                    text: result.msg,
                                    background: 'black',
                                    icon: "success",
                                    confirmButtonText: false,
                                    timer: 3000,
                                });
                                setTimeout(function () {
                                    window.location.href = window.location.origin + "/profile";
                                }, 3000);
                            }
                        }
                        else {
                            setloaderActSSO(false)
                            Swal.fire({
                                showConfirmButton: false,
                                text: result.msg,
                                confirmButtonText: false,
                                background: 'black',
                                icon: "success",
                                timer: 3000,
                            });
                            setTimeout(function () {
                                window.location.href = window.location.origin + "/profile";
                            }, 3000);
                        }
                    }
                    else {
                        setloaderActSSO(false)
                        Swal.fire({
                            showConfirmButton: false,
                            text: result.msg,
                            confirmButtonText: false,
                            background: 'black',
                            icon: "success",
                            timer: 3000,
                        });
                        setTimeout(function () {
                            Navigate("/profile");
                        }, 3000);
                    }
                }
                else {
                    // console.log("noooo")
                    setEmail(response.profileObj.email)
                    setisauthencate(true)
                }
            } else {
                setloaderActSSO(false)
                Swal.fire({
                    text: result.msg,
                    confirmButtonText: false,
                    background: 'black',
                    icon: "error",
                    timer: 3000,
                });
            }
        }
    };
    const onFailure = response => {
        // console.log('FAILED', response);
    };
    // const onLogoutSuccess = () => {
    //     console.log('SUCESS LOG OUT');
    // };


    const handleOnChangeEmail = (event) => {
        setEmail(event.target.value)
        let emailIDVal = /^(?!.{255})(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailIDTrue = emailIDVal.test(event.target.value);
        if (emailIDTrue) {
            console.log("emailIDTrue", emailIDTrue)
            let email1 = event.target.value.split("@")
            let email2 = email1[email1.length - 1].split(".")
            if (email2.length < 4) {
                let email3 = identical(email2)
                if (!email3) {
                    setValidEmail(true);
                    setEmailErr('')
                }
                else {
                    setEmailErr('Invalid Domain Names')
                }
            }
            else {
                setEmailErr('Invalid Domain Names')
            }

        } else {
            console.log("emailIDTrue", emailIDTrue)
            if (event.target.value === "") {
                setValidEmail(false);
                setEmailErr('** This field is required')
            }
            else {
                setValidEmail(false);
                setEmailErr('** Invalid Email')
            }
        }
    }

    const identical = (myArray) => {
        for (var i = 0; i < myArray.length; i++) {
            for (var j = 0; j < myArray.length; j++) {
                if (i !== j) {
                    if (myArray[i] === myArray[j]) {
                        return true; // means there are duplicate values
                    }
                }
            }
        }
        return false; // means there are no duplicate values.
    }

    const handleOnChangePassword = (event) => {
        setPassword(event.target.value);
        if (event.target.value === "") {
            setPasswordErr('** This field is required')
        } else {
            setPasswordErr('')
        }
    }

    const handleWebchat = () => {
        const zohoChat = document.querySelector(".zsiq_floatmain");
        const zohoChatModel = document.querySelector(".zls-sptwndw");
        zohoChat.classList.add("zsiqfanim");
        zohoChatModel.classList.add("siqanim");
        zohoChatModel.classList.remove("siqhide");
        zohoChatModel.style.cssText = `
            top: unset;
            bottom: 0;
        `
    }

    const verify2faLogin = async () => {
        // console.log("emailemail",email)
        if (tokenCode) {
            setloaderAct(true)
            let resLogin1 = await login2FAApi(email, tokenCode);
            if (!resLogin1) {
                setloaderAct(false)
                Swal.fire({
                    text: "Error while processing your request please try again later",
                    button: false,
                    background: "black",
                    icon: "error",
                    timer: 2000,
                });
            } else {
                if (resLogin1.data.success) {

                    let userInfo = await getUserDetails(resLogin1.data.data);
                    if (userInfo.data.data.userDetails.email !== "" && userInfo.data.data.userDetails.email !== undefined) {
                        if (userInfo.data.data.userDetails.fullName !== undefined && userInfo.data.data.userDetails.fullName !== "") {
                            if (userInfo.data.data.userDetails.nationality !== undefined && userInfo.data.data.userDetails.nationality !== "") {
                                if (userInfo.data.data.userDetails.dob !== undefined && userInfo.data.data.userDetails.dob !== "") {
                                    if (userInfo.data.data.userDetails.countryCode !== undefined && userInfo.data.data.userDetails.countryCode !== "") {
                                        if (userInfo.data.data.userDetails.mob !== undefined && userInfo.data.data.userDetails.mob !== "") {
                                            setloaderAct(false)
                                            Swal.fire({
                                                showConfirmButton: false,
                                                text: resLogin1.data.msg,
                                                background: 'black',
                                                icon: "success",
                                                confirmButtonText: false,
                                                timer: 3000,
                                            });
                                            setTimeout(function () {
                                                Navigate('/dashboard')
                                            }, 3000);
                                        }
                                        else {
                                            setloaderAct(false)
                                            Swal.fire({
                                                text: resLogin1.data.msg,
                                                background: 'black',
                                                icon: "success",
                                                confirmButtonText: false,
                                                timer: 3000,
                                            });
                                            setTimeout(function () {
                                                Navigate('/profile')
                                            }, 3000);
                                        }
                                    }
                                    else {
                                        setloaderAct(false)
                                        Swal.fire({
                                            showConfirmButton: false,
                                            text: resLogin1.data.msg,
                                            background: 'black',
                                            icon: "success",
                                            confirmButtonText: false,
                                            timer: 3000,
                                        });
                                        setTimeout(function () {
                                            Navigate("/profile");
                                        }, 3000);
                                    }

                                }
                                else {
                                    setloaderAct(false)
                                    Swal.fire({
                                        showConfirmButton: false,
                                        text: resLogin1.data.msg,
                                        background: 'black',
                                        icon: "success",
                                        confirmButtonText: false,
                                        timer: 3000,
                                    });
                                    setTimeout(function () {
                                        Navigate("/profile");
                                    }, 3000);
                                }

                            }
                            else {
                                setloaderAct(false)
                                Swal.fire({
                                    showConfirmButton: false,
                                    text: resLogin1.data.msg,
                                    background: 'black',
                                    icon: "success",
                                    confirmButtonText: false,
                                    timer: 3000,
                                });
                                setTimeout(function () {
                                    Navigate("/profile");
                                }, 3000);
                            }
                        }
                        else {
                            setloaderAct(false)
                            Swal.fire({
                                showConfirmButton: false,
                                text: resLogin1.data.msg,
                                confirmButtonText: false,
                                background: 'black',
                                icon: "success",
                                timer: 3000,
                            });
                            setTimeout(function () {
                                Navigate("/profile");
                            }, 3000);
                        }
                    }
                    else {
                        setloaderAct(false)
                        Swal.fire({
                            showConfirmButton: false,
                            text: resLogin1.data.msg,
                            confirmButtonText: false,
                            background: 'black',
                            icon: "success",
                            timer: 3000,
                        });
                        setTimeout(function () {
                            Navigate("/profile");
                        }, 3000);
                    }


                    setloaderAct(false)
                    // Swal.fire({ showConfirmButton:false,  text: resLogin1.data.msg, icon: 'success', background: 'black',confirmButtonText: false,  timer: 3000 });
                    setTokenCode('')
                    // setTimeout(function () {
                    //     Navigate("/dashboard");
                    // }, 3000);


                }
                else {
                    setloaderAct(false)
                    Swal.fire({ showConfirmButton: false, text: resLogin1.data.msg, icon: 'error', background: 'black', confirmButtonText: false, timer: 3000 });
                }
            }
            if (resLogin1) {
                // setloaderAct(false)

            }
            else {
                Swal.fire({
                    text: "Error while processing your request please try again later",
                    button: false,
                    background: "black",
                    icon: "error",
                    timer: 2000,
                });
            }
        }
        else {
            setloaderAct(false)
            Swal.fire({ text: 'Please enter token code', showConfirmButton: false, icon: 'error', background: 'black', confirmButtonText: false, timer: 3000 });
        }
    }

    const login = async () => {
        if (email !== '' && email !== undefined) {
            if (password !== '' && password !== undefined) {
                if (captchaVal !== '' && captchaVal !== undefined && captchaVal !== null) {
                    if (isValidEmail) {
                        setloaderAct(true)
                        let data = qs.stringify({
                            'email': email,
                            'password': password,
                            'captcha': captchaVal
                        });
                        let response = await userLogin(data);
                        if (!response) {
                            Swal.fire({
                                text: "Error while processing your request please try again later",
                                button: false,
                                background: "black",
                                icon: "error",
                                timer: 2000,
                            });
                        } else {
                            if (response.isTFA !== true) {
                                if (response.success === true) {
                                    Swal.fire({ text: response.msg, icon: 'success', background: 'black', confirmButtonText: false, showConfirmButton: false, timer: 3000 });
                                    if (response.isAdminCreated === true) {
                                        setCaptchaVal(null)
                                        window.grecaptcha.reset();
                                        setTimeout(function () {
                                            Navigate('/profile?id=5');
                                        }, 3000);
                                    }
                                    else {
                                        setCaptchaVal(null)
                                        window.grecaptcha.reset();
                                        setTimeout(function () {
                                            Navigate('/dashboard');
                                        }, 3000);
                                    }

                                } else {
                                    Swal.fire({ showConfirmButton: false, text: response.msg, icon: 'error', background: 'black', confirmButtonText: false, timer: 3000 });
                                }
                            }
                            else {
                                setisauthencate(true)
                            }

                            setloaderAct(false)
                            // setEmail('')
                            setPassword('')
                            // setValidEmail(false)
                            setCaptchaVal(null)
                            setCaptchaErr("")
                            window.grecaptcha.reset();
                        }

                    } else {
                        setEmailErr(`** Please include an '@' in the email address. ${email} is missing an '@'`)
                    }
                } else {
                    setCaptchaErr(" This field is required")
                }

            } else {
                setPasswordErr('** This field is required')
            }
        } else {
            setEmailErr('** This field is required')
        }
    }
    const submitOnEnter = (event) => {
        if (event.key === "Enter") {
            login();
        }
    }
    const submitOnEnterTwoFA = (event) => {
        if (event.key === "Enter") {
            verify2faLogin();
        }
    }
    return (
        <>
            <Helmet>
                <style>{"body { background-color: #000; }html{background-color: #000;}"}</style>
                <title>Login | Golteum</title>
            </Helmet>

            <div className="cssForAll">
                <div className="login-wrap ">
                    <div className="start-box">
                        <div id="stars"></div>
                        <div id="stars2"></div>
                        <div id="stars3"></div>
                    </div>


                    <div className="login-box">
                        {!isauthencate ?
                            <div className=''>
                                <div className="login-logo">
                                    <a href="https://golteum.io/">
                                        <img className="logoSize" src="images/logo.png" alt="" />
                                    </a>
                                    <h4>Login to your account!</h4>
                                </div>
                                <div className="login-form">
                                    <div className="form-group">
                                        <input type="text" name="" className={isValidEmail ? 'form-control is-valid' : 'form-control'} value={email} onChange={handleOnChangeEmail} onKeyUp={submitOnEnter} placeholder="Email" />
                                        <div>
                                            <span style={{ color: "red" }} >{emailErr}</span>
                                        </div>
                                    </div>

                                    {/* <div className="form-group">
                                <input type="text" name="" className="form-control" placeholder="Enter OTP" />
                            </div> */}
                                    <div className="form-group">
                                        <input type="password" name="" className="form-control" value={password} onChange={handleOnChangePassword} placeholder="Password" onKeyUp={submitOnEnter} />
                                        <div>
                                            <span style={{ color: "red" }} >{passwordErr}</span>
                                        </div>
                                    </div>
                                    <div className="forgot-link">
                                        <Link to="/forgot-password">Forgot your password?</Link>
                                    </div>
                                    <div className='captcha'>
                                        <Captcha handleCaptchaChange={handleCaptchaChange} />
                                    </div>
                                    <div className='text-center'>
                                        <span style={{ color: "red" }} >{captchaErr}</span>
                                    </div>

                                    {loaderAct ? <button className="btn btn-yellow w-100 mt-4" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> : <button className="btn btn-yellow w-100 mt-4" onClick={login}>Login</button>}


                                    <div className="or-box">
                                        <span>OR</span>
                                    </div>
                                    <div className="social-login">

                                        {
                                            loaderActSSO
                                                ?
                                                <button className="btn btn-yellow w-100 mt-4" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button>
                                                :
                                                <GoogleLogin
                                                    clientId={ssoId}
                                                    onSuccess={onSuccess}
                                                    buttonText="Login with Google"
                                                    onFailure={onFailure}
                                                    cookiePolicy={"single_host_origin"}
                                                />
                                        }

                                    </div>


                                    <div className="account-new">
                                        <p>Don’t have an account? <Link to="/register">Sign up here</Link></p>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className=''>
                                <div className="login-logo authclass">
                                    <Link to="/">
                                        <img className="logoSize" src="images/logo.png" alt="" />
                                    </Link>
                                    <h4>Authenticate Your Account</h4>
                                    <p>Please enter Authentication code from google Authenticator</p>

                                </div>
                                <div className="form-group">
                                    <input type="number" className='form-control' placeholder="Enter Token Code" value={tokenCode} onChange={(event) => { setTokenCode(event.target.value) }} onKeyUp={submitOnEnterTwoFA} />
                                    {loaderAct ? <button className="btn btn-yellow w-100 mt-4" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> : <button className="btn btn-yellow w-100 mt-4" onClick={verify2faLogin}>Submit</button>}
                                    <p className='text-center mt-3'>Forgot or Lost 2FA? Please contact our live <a href="#" onClick={handleWebchat} rel="noopener">support</a></p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="footer-item">
                        <h4>JOIN OUR COMMUNITY</h4>
                        <div className="social-login-ic">
                            <a href="https://twitter.com/golteum?s=20&t=Kirb38vwigNjUq0GgWCyUA" target="_blank" rel="noreferrer">
                                <i className='bi bi-twitter'></i>
                            </a>
                            <a href="https://t.me/golteum" target="_blank" rel="noreferrer">
                                <i className='bi bi-telegram'></i>
                            </a>
                            <a href="https://instagram.com/golteum?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
                                <i className='bi bi-instagram'></i>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100082846137280" target="_blank" rel="noreferrer">
                                <i className='bi bi-facebook'></i>
                            </a>
                        </div>
                        <div className="link-f">
                            <a href={privacyPolicy} target="_blank" rel="noreferrer">PRIVACY POLICY</a>
                            {/* <Link to="/privacy-policy">PRIVACY POLICY</Link> */}
                            {/* <Link to="/term">TERMS OF USE</Link> */}
                        </div>
                        <div className='txtds'>
                            <p>Golteum is committed to ensuring the security and protection of the personal information that we process, and to provide a compliant and consistent approach to data protection. We will only be using your contact details to better serve you during the presale and secure your GLTM tokens at the TGE. However please note you are not obliged to leave your contact details and we will be proving all users the option to remove their data after the TGE.</p>
                        </div>
                        <div className="copy-r">
                            © 2024 Golteum.io - All rights reserved.
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
