import React, { useState, useEffect } from 'react'
import { updatePassword, getUserDetails } from "components/helper/userHelper";
import Swal from 'sweetalert2';
import { event } from 'jquery';
import { useNavigate } from "react-router-dom";

import { useUser } from 'components/UserContext';

export default function Password(props) {
    const { users } = useUser();
    const [warningCheck, setWarningCheck] = useState(false)
    const [loaderAct, setloaderAct] = useState(false);
    const [isPasswordValid, setPasswordValid] = useState(false);
    const [passwordoldErr, setPasswordoldErr] = useState("");
    const [passwordErr, setPasswordErr] = useState("");
    const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
    const navigate = useNavigate();
    const [pass, setpass] = useState({
        oldPass: "",
        newPass: "",
        cNewPass: ""
    })
    useEffect(() => {
        let isAPISubscribed = true;
        if(isAPISubscribed){
            if(users.isAdminCreated === ""){

            }else{
                setWarningCheck(users.isAdminCreated)
            }
            
        }
        return () =>{
            isAPISubscribed = false
        }
    }, [users.isAdminCreated]);

    const handleInput = (key, value) => {
        setpass({ ...pass, [key]: value })
    }
    const handleOnChangePassword = (event) => {
        // setPassword(event.target.value);
        let passVal = /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d@#$^!%*?&_]{6,}$/;
        let passValTrue = passVal.test(event.target.value);
        if (passValTrue) {
            setPasswordValid(true);
            setPasswordErr("");
        } else {
            if (event.target.value === "") {
                setPasswordErr(" This field is required.");
            } else {
                setPasswordErr(
                    " Must contain at least one uppercase and lowercase letter, and at least 6 or more characters"
                );
            }
        }
    };

    const handleOnChangeConfirmPassword = (value) => {
        if (value !== "") {
            setConfirmPasswordErr("");
        } else {
            setConfirmPasswordErr(" This field is required.");
        }
    };

    const handleOnChangeoldPassword = (value) => {
        if (value !== "") {
            setPasswordoldErr("");
        } else {
            setPasswordoldErr(" This field is required.");
        }
    };

    const updatePassword_fn = async () => {
        if (pass.oldPass !== undefined && pass.oldPass !== "") {
            if (pass.newPass !== undefined && pass.newPass !== "") {
                if (pass.cNewPass !== undefined && pass.cNewPass !== "") {
                    if( pass.oldPass !== pass.newPass){
                        if (isPasswordValid) {
                            // console.log("dvdv", pass.oldPass, pass.newPass, pass.cNewPass)
                            setloaderAct(true)
                            let result = await updatePassword(pass.oldPass, pass.newPass, pass.cNewPass);
                            if(!result){
                                setloaderAct(false)
                                Swal.fire({
                                    text: "Error while processing your request please try again later",
                                    button: false,
                                    background: "black",
                                    icon: "error",
                                    timer: 2000,
                                });
                            }else{
                                setloaderAct(false)
                                if (result.data.success === true) {
                                    // console.log("right")
                                    Swal.fire({ showConfirmButton:false, button:false, 
                                        text: result.data.msg,
                                        // button: false,
                                        background: 'black',
                                        icon: "success",
                                        timer: 3000,
                                    });
                                    setpass({ 
                                        oldPass: "",
                                        newPass: "",
                                        cNewPass: ""
                                    })
                                    setWarningCheck(users.isAdminCreated)
                                    // fatchUserDetails()
                                }else {
                                    // console.log("wrong")
                                    Swal.fire({ showConfirmButton:false, button:false, 
                                        text: result.data.msg,
                                        // button: false,
                                        background: 'black',
                                        icon: "error",
                                        timer: 4000,
                                    });
                                }
                            }
                        }
                        else {
                            setPasswordErr(" Password is invalid");
                        }
                    }
                    else{
                        Swal.fire({ showConfirmButton:false, button:false, 
                            text: "New password cannot be the same as your old password",
                            // button: false,
                            background: 'black',
                            icon: "error",
                            timer: 4000,
                        });
                    }
                }
                else {
                    setConfirmPasswordErr(" This field is required");
                }
            }
            else {
                setPasswordErr(" This field is required");
            }
        }
        else {
            setPasswordoldErr(" This field is required");
        }



    }

    // const fatchUserDetails = async () => {
    //     let res = await getUserDetails();
    //     if(res){
    //         if (res.data.success) {
    //             setWarningCheck(res.data.data.userDetails.isAdminCreated)
    //         }
    //     }
    // }

    return (
        <>
            <div className={props.toggleState === 5 ? "content active-content col-md-8" : "content col-md-8"}>
                <div className="card-cust p-4">
                    {warningCheck ? <p className='hidewarining2'>Please chanage your password, First</p> :  ''}
                    <h4>Change Password</h4>
                    <div className="form-group">
                        <label>Old password</label>
                        <input type="password" name="" className="form-control" placeholder="Old password" value={pass.oldPass} onChange={(event => { handleInput("oldPass", event.target.value) })} onKeyUp={(event) => { handleOnChangeoldPassword(event) }}/>
                        <div>
                            <span style={{ color: "red", marginBottom: "20px" }}>{passwordoldErr}</span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>New password</label>
                        <input type="password" name="" className="form-control" placeholder="New password" value={pass.newPass} onChange={(event => { handleInput("newPass", event.target.value) })} onKeyUp={(event) => { handleOnChangePassword(event) }} />
                        <div>
                            <span style={{ color: "red" }}>{passwordErr}</span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>New re-password</label>
                        <input type="password" name="" className="form-control" placeholder="New re-password" value={pass.cNewPass} onChange={(event => { handleInput("cNewPass", event.target.value) })} onKeyUp={(event) => { handleOnChangeConfirmPassword(event) }} />
                        <div>
                            <span style={{ color: "red" }}>{confirmPasswordErr}</span>
                        </div>
                    </div>

                    {loaderAct ? <button className="btn btn-grd mt-2" style={{ color: "#000" }} disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> : <button className="btn btn-grd mt-2" onClick={updatePassword_fn}>Update</button>}
                </div>
            </div>
        </>
    )
}
