import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
// import { getUserDetails } from "components/helper/userHelper";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useUser } from 'components/UserContext';
import { logMeOut } from '../../../helper/userHelper'
import logoutImg from './img/logout.svg'
import referralImg from './img/referral.svg'
import nav2 from './img/nav2.svg'
import nav3 from './img/nav3.svg'

export default function Header(props) {
    const { users } = useUser();
    // console.log("testing provider", users)
    const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
    const [isLoading, setIsLoading] = useState(false);
    const [loaderAct, setloaderAct] = useState(false);
    const [profileName, setProfileName] = useState('User!!');
    const [profileImage, setProfileImage] = useState();
    const [profileEmail, setProfileEmail] = useState('user!!@gmail.com');
    const [tokenBal, setTokenBal] = useState('0.00000000');
    const navigate = useNavigate();

    const toggleIsLoading = () => {
        if (isLoading) {
            setIsLoading(current => !current);
            if (props.hamBurger) {
                props.handleHamBurger()
            }
        }
        else {
            setIsLoading(current => !current);
            if (props.hamBurger) {
                props.handleHamBurger()
            }
        }
    };

    useEffect(() => {
        if (props.hamBurger) {
            setIsLoading(false);
        }
    }, [props.hamBurger]);

    const Logout = async() => {
        setloaderAct(true)
        const isLoggedOut = await logMeOut();
        if(isLoggedOut){
            setloaderAct(false)
            Swal.fire({
                showConfirmButton: false,
                text: 'Logged out Successfully',
                button: false,
                background: 'black',
                icon: "success",
                timer: 2000,
            });
            setTimeout(function () {
                navigate('/')
            }, 2000);
            
        }else{
            setloaderAct(false)
            setTimeout(function () {
                navigate('/')
            }, 2000);
            Swal.fire({
                text: "Logged out Successfully",
                button: false,
                background: "black",
                icon: "success",
                timer: 2000,
            });
        }
    }
    useEffect(() => {
        let isAPISubscribed = true;
        if(isAPISubscribed){
            setProfileName(users.fullname)
            setProfileEmail(users.email)
            setTokenBal(users.tokenBal)
        }
        return () =>{
            isAPISubscribed = false
        }
    }, [users.fullname, users.email, users.tokenBal]);


    return (
        <>
            <header>
                <div className="header-container">
                    {
                        isMobile
                            ?
                            <>
                                <div className="head-profile dropdown ml-auto">
                                    <div className="mobile-toggle" onClick={props.handleHamBurger}>
                                        {props.hamBurger ?
                                            <>
                                                <p className="closeHumburger my-1 " >X</p>
                                            </>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28"><path fill="none" d="M0 0h24v24H0z" /><path fill="#fff" d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z" /></svg>
                                        }
                                    </div>
                                    <div className="logo">
                                        <Link to="/dashboard">
                                            <img className='logoSize' src="images/logo.png" alt=""/>
                                        </Link>
                                    </div>
                                    <div className="drop-in">
                                        <span >Welcome!  {profileName}</span>
                                        <div className="pro-photo" onClick={toggleIsLoading}>
                                            <figure id="dropdownMenuButton" >
                                                <img src={nav2} alt=""/>
                                            </figure>
                                        </div>
                                        {props.hamBurger ? '' : isLoading ?
                                            <div className="dropdown-menu show" aria-labelledby="dropdownMenuButton">
                                                <div className="user-info">
                                                    <div className="user-t">
                                                        <h4>{profileEmail}</h4>
                                                        {/* <span>(DEMO457)</span> */}
                                                    </div>
                                                    <div className="user-b">
                                                        <span>TOKEN BALANCE</span>
                                                        <h4>{Number(tokenBal).toFixed(8)} $GLTM</h4>
                                                    </div>
                                                </div>
                                                <div className="menu-drop">
                                                    <Link className="dropdown-item" to="/profile" onClick={toggleIsLoading}><img src={nav2} alt=""/> My Profile</Link>
                                                    <Link className="dropdown-item" to="/profile?id=3" onClick={toggleIsLoading}><img src={referralImg} alt=""/> Referral</Link>
                                                    <Link className="dropdown-item" to="/buy-token" onClick={toggleIsLoading}><img src={nav3} alt=""/> Buy Token</Link>
                                                    {loaderAct ?  <a className="dropdown-item" onClick={Logout} href="#" rel="noopener"><img src={logoutImg}  alt="" /> Logout &nbsp;&nbsp;<img className="loaderImg" src="images/loader2.gif" style={{backgroundColor: "#3666E0", border: "1px solid #3666E0", borderRadius: "50%"}} alt="" /></a> : <a className="dropdown-item" onClick={Logout} href="#" rel="noopener"><img src={logoutImg} alt="" /> Logout</a>}
                                                </div>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="logo">
                                    <Link to="/dashboard">
                                        <img className='logoSize' src="images/logo.png" alt=""/>
                                    </Link>
                                </div>
                                <div className="head-profile dropdown ml-auto">
                                    <div className="drop-in">
                                        <span >Welcome!  {profileName}</span>
                                        <div className="pro-photo" onClick={toggleIsLoading}>
                                            <figure id="dropdownMenuButton" >
                                                <img src={nav2} alt=""/>
                                            </figure>
                                        </div>
                                        {isLoading ?
                                            <div className="dropdown-menu show" aria-labelledby="dropdownMenuButton">
                                                <div className="user-info">
                                                    <div className="user-t">
                                                        <h4>{profileEmail}</h4>
                                                        {/* <span>(DEMO457)</span> */}
                                                    </div>
                                                    <div className="user-b">
                                                        <span>TOKEN BALANCE</span>
                                                        <h4>{Number(tokenBal).toFixed(8)} $GLTM</h4>
                                                    </div>
                                                </div>
                                                <div className="menu-drop">
                                                    <Link className="dropdown-item" to="/profile" onClick={toggleIsLoading}><img src={nav2} alt=""/> My Profile</Link>
                                                    <Link className="dropdown-item" to="/profile?id=3" onClick={toggleIsLoading}><img src={referralImg} alt=""/> Referral</Link>
                                                    <Link className="dropdown-item" to="/buy-token" onClick={toggleIsLoading}><img src={nav3} alt=""/> Buy Token</Link>
                                                    {loaderAct ?  <a className="dropdown-item" onClick={Logout} href="#" rel="noopener"><img src={logoutImg}  alt="" /> Logout &nbsp;&nbsp;<img className="loaderImg" style={{backgroundColor: "#3666E0", border: "1px solid #3666E0", borderRadius: "50%"}} src="images/loader2.gif" alt="" /></a> : <a className="dropdown-item" onClick={Logout} href="#" rel="noopener"><img src={logoutImg} alt="" /> Logout</a>}
                                                </div>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                    <div className="mobile-toggle" onClick={props.handleHamBurger}>
                                        {props.hamBurger ?
                                            <>
                                                <p className="closeHumburger my-1 " >X</p>
                                            </>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28"><path fill="none" d="M0 0h24v24H0z" /><path fill="#fff" d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z" /></svg>
                                        }
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </header>
        </>
    )
}
