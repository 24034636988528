import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Loader(props) {
	const { text, show } = props;
	return (
		<div className="ajax-loader" style={show ? { display: "flex" } : { display: "none" }}>
			<div style={{ textAlign: "center" }}>
				{/* <img src="images/logoLoader.png" className="img-responsive mainLogo" alt="" /> */}
				<Box sx={{ display: 'flex' }}>
					<CircularProgress />
				</Box>
			</div>
		</div>
	)
}

export default Loader