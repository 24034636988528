/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react'
import { Helmet } from "react-helmet";
import '../../Css/Custom.css'
import '../../Css/Responsive.css'
// import Header from '../Common/Header';
// import Sidebar from '../Common/Sidebar';
import Swal from 'sweetalert2';
import Footer from '../Common/Footer';
// import CardRight from '../Common/CardRight';
import { contactSupport } from 'components/helper/userHelper';

// import { terms } from 'components/helper/userHelper';

export default function Index() {

    const [showLoader, setShowLoader] = useState(false);

    const [nameErr, setnameErr] = useState('')
    const [emailErr, setEmailErr] = useState('')
    const [ctnErr, setCtnErr] = useState('')
    const [check, setCheck] = useState(false);
    const [checkERR, setCheckERR] = useState("");
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        content: ""
    })

    const [chatBox, setChatBox] = useState(false);
    const [emailSupport, setEmailSupport] = useState(false);

    const handleInput = (key, value) => {
        setUserData({ ...userData, [key]: value })
    }

    const handleOnChangeFullName = (value) => {
        // console.log("value", value, cCode)
        if (value !== "") {
            setnameErr("");
        } else {
            setnameErr(" This field is required.");
        }
    };

    const handleOnChangeEmail = (event) => {
        let emailIDVal = /^(?!.{255})(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const domainValue = ['live', 'yahoo', 'google', 'codebird', 'gmail','srphysio'];
        const emailLastValue = ['com', 'net', 'in', 'io','biz']
        let emailIDTrue = emailIDVal.test(event.target.value);
        if (emailIDTrue) {
            // let emailValue = event.target.value;
            // let splitValue = emailValue.split("@")[1].split(".");
            // let userDomain = splitValue[0];
            // let lastValue = splitValue[1];

            // const domainCheck = domainValue.indexOf(userDomain);
            // if (domainCheck !== -1) {
            //     const lastCheck = emailLastValue.indexOf(lastValue);
            //     if (lastCheck !== -1) {
                    setEmailErr("")
            //     } else {
            //         setEmailErr("Domain must be .com, .net, .in, .io, .biz")
            //     }
            // } else {
            //     setEmailErr("Domain must be @google, @yahoo, @liv")
            // }

        } else {
            if (event.target.value === "") {
                setEmailErr("This field is required")
            }
            else {
                setEmailErr("Invalid Email")
            }
        }
    }

    const handleOnChangeContent = (value) => {
        // console.log("value", value)
        if (value !== "") {
            if ((value.target.value).length <= 150) {
                setCtnErr("");
            }
            else {
                setCtnErr("No more than 150 characters!");
            }
        } else {
            setCtnErr(" This field is required.");
        }
    };
    const handleOnCheck = (event) => {
        setCheck(!check)
    };

    const handleWebchat = () => {
        const zohoChat = document.querySelector(".zsiq_floatmain");
        const zohoChatModel = document.querySelector(".zls-sptwndw");
        zohoChat.classList.add("zsiqfanim");
        zohoChatModel.classList.add("siqanim");
        zohoChatModel.classList.remove("siqhide");
        zohoChatModel.style.cssText = `
            top: unset;
            bottom: 0;
        `
    }

    const SubmitConatctForm = async () => {
        // console.log("aasasasas",userData.name.trim(),"fef")
        setCheckERR("");
        // console.log("info", userData)
        if (userData.name.trim() !== undefined && userData.name.trim() !== "") {
            if (userData.email !== undefined && userData.email !== "") {
                if (userData.content !== undefined && userData.content !== "") {
                    if (userData.content.length <= 150) {
                        if (check) {
                            setShowLoader(true);
                            let res = await contactSupport(userData)
                            if (res) {
                                // console.log("res", res)
                                if (res.success === true) {
                                    Swal.fire({
                                        showConfirmButton: false,
                                        text: "Your request was successfully submitted",
                                        button: false,
                                        background: 'black',
                                        icon: "success",
                                        confirmButtonText: 'Close',
                                    });
                                    setShowLoader(false)
                                    setCheck(false)
                                    setUserData({
                                        name: "",
                                        email: "",
                                        content: ""
                                    })
                                }
                                else {
                                    setShowLoader(false)
                                    Swal.fire({
                                        showConfirmButton: false,
                                        text: res.msg,
                                        button: false,
                                        background: 'black',
                                        icon: "error",
                                        confirmButtonText: 'Close',
                                    });
                                }
                            }
                            else {
                                Swal.fire({
                                    text: "Error while processing your request please try again later",
                                    button: false,
                                    background: "black",
                                    icon: "error",
                                    timer: 2000,
                                });
                            }

                        }
                        else {
                            setCheckERR(' This Checkbox is required')
                        }
                    }
                    else {
                        setCtnErr("No more than 150 characters!");
                    }
                }
                else {
                    setCtnErr("This field is required")
                }
            }
            else {
                setEmailErr("This field is required")
            }
        }
        else {
            setnameErr("This field is required")
        }
    }

    return (
        <>
            <Helmet>
                <title>Support | Golteum</title>
            </Helmet>
            <div className="dash-container">
                <div className="page-tit">
                    <h4>Support</h4>
                </div>

                <div className="dash-container-in">
                    {/* <h2>Help Topic</h2> */}
                    <div className="row dash-home mt-5">
                        <div className="col-md-12 admin-support">
                            <h2 className="text-center"> Get in Touch</h2>
                            <div className=" text-center d-flex mt-3 boxs">
                                <div className={chatBox ? 'support_button_active chat-box' : 'chat-box'} onClick={() => {
                                    setEmailSupport(false)
                                    setChatBox(true)
                                }}>
                                    <a href="#" rel="noopener"><i className="bi mt-1 bi-chat-left-dots"></i>
                                        Email Support</a>
                                </div>
                                <div className={emailSupport ? 'support_button_active chat-box' : 'chat-box'} onClick={() => {
                                    setChatBox(false)
                                    setEmailSupport(true)
                                }}>
                                    <a href="#" rel="noopener"> <i className="bi mt-1 bi-envelope-check"></i>Live Chat</a>

                                </div>
                            </div>
                            {chatBox && <div className="login-box main-box mb-5">
                                <div className="login-logo">
                                    <form>
                                        <label className="mt-5">Name</label>
                                        <input className="mt-1" type="text" placeholder="Enter Your Name" name="" value={userData.name.trimStart()} onChange={(event => { handleInput("name", event.target.value) })} onKeyUp={(event) => { handleOnChangeFullName(event) }} />
                                        <div className='text-left'>
                                            <span style={{ color: "red" }}>{nameErr}</span>
                                        </div>
                                        <label className="mt-3">Email</label>
                                        <input className="mt-1" type="text" placeholder="Enter Your Valid Email address" name="" value={userData.email} onChange={(event => { handleInput("email", event.target.value) })} onKeyUp={(event) => { handleOnChangeEmail(event) }} />
                                        <div className='text-left'>
                                            <span style={{ color: "red" }}>{emailErr}</span>
                                        </div>
                                        <label className="mt-4">Message</label>
                                        <textarea className="mt-1 textarea w-100" placeholder="Enter Your Message" value={userData.content} onChange={(event => { handleInput("content", event.target.value) })} onKeyUp={(event) => { handleOnChangeContent(event) }} ></textarea>
                                        <div className='text-left'>
                                            <span style={{ color: "red" }}>{ctnErr}</span>
                                        </div>
                                    </form>
                                    <div className="privacy-div mt-3">
                                        <input style={{ margin: "3px 0px 0px 4px" }} type="checkbox" checked={check} id="invalidCheck3" onChange={(event) => handleOnCheck(event)} className="form-check-input" name="" />
                                        <label htmlFor="invalidCheck3"> I accept the <a href="https://golteum.io/term" target="_blank" style={{ color: "#3666e0" }} rel="noreferrer">Terms of Service</a>
                                        </label>
                                    </div>
                                    <div className='text-left'>
                                        <span style={{ color: "red" }}>{checkERR}</span>
                                    </div>
                                    <div className=" mt-1">
                                        {showLoader ? <button className="btn btn-yellow w-100 mt-4" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> :
                                            <button className="btn btn-yellow w-100 mt-4" type="" onClick={SubmitConatctForm}>Submit</button>
                                        }
                                    </div>
                                </div>
                            </div>}

                            {emailSupport && <div className="login-box support_boxes main-box mb-5">

                                <div className='chatbox_top'>
                                    <h1>Live Webchat</h1>
                                    <i className="bi bi-chat-left-fill"></i>
                                </div>
                                <div className='chatbox_body mt-3'>
                                    <div className='chatbox_left'>
                                        <h5>Our friendly webchat team are ready to help</h5>
                                        <ul>
                                            <li><i className="bi bi-check-circle"></i>Immediate response</li>
                                            <li><i className="bi bi-check-circle"></i>Real time chat</li>
                                            <li><i className="bi bi-check-circle"></i>Knowledgeable agents</li>
                                        </ul>
                                    </div>
                                    <div className='chatbox_right'>
                                        <button className='btn btn-yellow w-100 mt-4' onClick={handleWebchat}>Start Webchat Now</button>
                                    </div>
                                </div>

                            </div>}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
