import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from '../../Layout/header';
import Footer from '../../Layout/footer';
import '../Css/Custom.css'
import { verification } from "components/helper/userHelper";
import Loader from 'components/Common/Loader';
// import Swal from 'sweetalert2';

export default function Verification() {

    let params = new URL(document.location).searchParams;
    let token = params.get("token");
    let key = params.get("key");
    const Navigate = useNavigate();
    const [successfull, isSuccessfull] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        let isApiSubscribed = true
        const signUp = async () => {
            // console.log("tokken key",token,key)
            if(token !== null &&  key !== null){
                setShowLoader(true)
                let isVerified = await verification(token,key);
                if(isVerified){
                    setShowLoader(false)
                    // console.log("check api res ", isVerified )
                    if (isVerified) {
                        isSuccessfull(true);
                    } else {
                        isSuccessfull(false);
                        Navigate("/404") ;
                    }
                }

            }
            else{
                Navigate("/") ;
            }
            
        }
        if(isApiSubscribed){
            signUp();
        }
        
        return () =>{
            isApiSubscribed = false;
        }
    }, [token, key, Navigate])
    return (
        <>
            {successfull ?
            <>
            <Loader show={showLoader} />
            <Header />
            <Helmet>
                <style>{"body { background-color: #000; }"}</style>
            </Helmet>
            <div className='container-fluid d-flex justify-content-center align-item-center' style={{ height: '475px', marginTop: "80px", alignItems: "center", backgroundColor: '#000' }}>
                <div className="row">
                    <div className="col-12 color-white text-center" style={{ padding: "" }} >
                         <h1 className='' style={{ color: 'white' }}>
                            Thank you for confirming. Please click <Link to='/' style={{color:"green"}}>here</Link> to login.
                        </h1> 
                    </div>
                </div> 
            </div>
            <Footer/>
            </>
            : ''}
        </>
    )
}