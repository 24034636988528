import React ,{useEffect} from 'react'
import Header from 'components/Layout/header'
import Footer from 'components/Layout/footer'
import { ontop } from 'components/Common/article'
export default function Index() {
    useEffect(() => {
        ontop();
    }, [])
    return (
        <>
            <Header />
            <div className='warper '>
                <div className="site_content" id="home">
                    <div className="inner-head">
                        <h4>Golteum Terms of Use</h4>
                    </div>
                    <div className="inner-page-cont">
                        <div className="container">
                            <h4>ACCEPTANCE</h4>
                            <p> Please read these Terms and Service ("Terms", "Terms and Service") carefully before using the www.golteum.io websiteoperated by Golteum Ltd ("us", "we", or "our", "Golteum"). These Terms explain our obligations to you, and your obligations to us. By accessing and using the Service you (the customer "You", "your" and "Customer") will automatically be taken to have read, understood and accepted these Terms and Privacy Policy. If you do not agree to be bound by the Terms please cease to access and use this Service and any of the content found within it. </p><br />
                            <h4>ELIGIBILITY</h4>
                            <p>By visiting or using Service you represent and warrant to Golteum and agree that:</p>
                            <p>1. You are of legal age (18 years old or older) and legal competence.</p>
                            <p>2. if Customer is a company, (i) Customer is duly organized and validly existing under the applicable laws of the jurisdiction of its organization; (ii) execution and delivery of this Terms have been duly authorized by Customer; and (iii) each person executing and delivering these Terms and other transactions contemplated on behalf of Customer has been duly authorized by Customer to do so.</p>
                            <p>3. It is legal to use Service in your country of residence. You represent and warrant that by using the Service you do not violate any laws or regulations applicable to you.</p>
                            <h4>GENERAL</h4>
                            <p>$GLTM token ("Token") is a digital asset created by Golteum. A Utility Token based on the ERC20 token standard and has many features which be utilised on the Golteum platform. </p>
                            <h4>FEES</h4>
                            <p>No transaction fees apply to Token at presale stage, however we will apply a tax fee buy and sell system once we are listed, further details can be found in the white paper.</p><br />
                            <p>RISK DISCLOSURE</p>
                            <p>Cryptocurrencies, tokens and digital assets are extremely volatile and carry high degree of risk. Price movements for cryptocurrencies, tokens and digital assets, are influenced by, among other things: changing supply-demand relationships, sentiment and news releases that often cause large price fluctuations. By using the</p><br />
                            <p>Services you represent and warrant that:</p>
                            <p>1. You have knowledge and experience in cryptocurrencies, tokens and digital assets.</p>
                            <p>2. You fully understand the risks associated with cryptocurrencies, tokens and digital assets.</p>
                            <p>Further, you accept that you bear sole liability for all losses resulting from your use of cryptocurrencies, tokens (including the Golteum token).</p>
                            <br />
                            <h4>NO ADVISE</h4>
                            <p>Golteum does not provide any investment advice or render any opinion to the Customer as to whether the purchase of Token is prudent, timely or suitable. Nothing in the www.golteum.io website shall be construed a financial advise to you. You are solely responsible for determining whether your Token purchase, sale or transaction is appropriate based on your personal objectives, financial circumstances and risk tolerance. You must make your own investigations regarding the accuracy, reliability and completion of information provided and consult your professional adviser regarding your specific situation, including your financial situation, objectives and goals.</p>

                            <p>SYSTEM AVAILABILITY</p>
                            <p>Golteum will use reasonable efforts to ensure that the Service is available at all times, except for reasonable periods of time for maintenance.</p>
                            <h4>TAXES</h4>
                            <p>Customer is solely responsible for payment of any and all taxes owed as a result of using Service. Customer shall indemnify and hold Golteum harmless for any and all taxes, penalties, or interest claimed by any agency of government for payments made to Customer pursuant to this Terms.</p>
                            <br />
                            <h4>USE RESTRICTIONS</h4>
                            <p>Your permission to use the Site is conditioned upon the following use, posting and conduct restrictions: You agree that you will not under any circumstances:</p>
                            <p>use the Service is applicable laws at the country of your location do not permit You to use the Service;</p>
                            <p>use the Service for any unlawful purpose or for the promotion of illegal activities;</p>
                            <p>use the Servcie in a way that is detrimental to us, our customers or employees;</p>
                            <p>disparage, tarnish, or otherwise harm, in our opinion, us and/or the Service;</p>
                            <p>use the Service to hide proceeds of illegal activities, or otherwise lauder illegally obtained cryptocurrency, tokens or digital assets;</p>
                            <p>distribute any part or parts of the Service without our explicit written permission (we grant the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of creating publicly-available searchable indices but retain the right to revoke this permission at any time on a general or specific basis);</p>
                            <p>harass, abuse or harm another person or group;</p>
                            <p>interfere or attempt to interfere with the proper functioning of the Service;</p>
                            <p>make any automated use of the Service or the related systems, or take any action that we deem to impose or to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure;</p>
                            <p>bypass any robot exclusion headers or other measures we take to restrict access to the Service, or use any software, technology, or device to scrape, spider, or crawl the Service or harvest or manipulate data;</p>
                            <p>use the Service as part of any effort to compete with us;</p>
                            <p>circumvent, disable or otherwise interfere with any security-related features of the Service or features that prevent or restrict use or copying of content, or enforce limitations on use of the Service or the content accessible via the Service.</p>
                            <h4>IRREVERSIBLE TRANSACTIONS</h4>
                            <p>Exercise extreme caution and double check all information you are entering when purchasing/selling Token at www.golteum.io website, any cryptocurrency exchange or when transferring Token to another account. By definition, cryptocurrency, token or digital asset transactions are final. Once the transaction has been carried out it cannot be reversed. You agree not to hold us liable for your monetary loss resulting from such error.</p>
                            <h4>INTELLECTUAL PROPERTY</h4>
                            <p>Unless otherwise indicated, www.golteum.io website is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the www.golteum.io website (collectively, the "Content") and the trademarks, logos contained therein (the "Marks") are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights. The Content and the Marks are provided on the Site "AS IS" for your information and personal use only. Except as expressly provided in these Terms of Service, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission. Provided that you are eligible to use the www.golteum.io website, you are granted a limited license to access and use the www.golteum.io website and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the www.golteum.io website, the Content and the Marks.</p>

                            <h4>LINKS TO OTHER SITES</h4>
                            <p>Links to non-Golteum websites are provided solely as pointers to websites that may be useful to Golteum Customers. Golteum has no control over the content on such websites. If You choose to visit to a website not controlled by Golteum, We make no warranties, either expressed or implied, concerning the content of such site, including the accuracy, completeness, reliability, or suitability thereof for any particular purpose, nor does Golteum warrant that that such site or content is devoid of viruses or other contamination. Golteum does not guarantee the authenticity of documents on the Internet. Links to non-Golteum sites do not imply any endorsement of or responsibility for the opinions, ideas, products, information, or services offered at such sites, or any representation regarding the content at such sites. You agree and acknowledge that we do not endorse the services offered on third-party websites and you shall hold us harmless from any harm caused by such website. Additionally, you shall hold us harmless from any losses sustained by you or harm caused to you relating to or resulting in any way from any third-party website.</p>
                            <h4>TERMINATION</h4>
                            <p>Golteum may terminate you access to this Website at any time in the event of breach of these Terms. All provisions of the Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
                            <h4>DISCLAIMER OF LIABILITY</h4>
                            <p>Golteum makes no warranty that the functionality of www.golteum.io website will be uninterrupted. Furthermore, we do not guarantee that the information provided on www.golteum.io website is correct, complete or up-to-date.</p>
                            <p>Golteum will not be held liable for confirmation of any transaction on any blockchain network as we hold no control over any blockchain network.</p>
                            <p>Golteum will not be liable for any consequential, incidental, special, direct, or indirect damages (including but not limited to lost profits, losses or damages that result from use or loss of use of the Golteum services and third party content, inconvenience or delay). this is true even if Golteum has been advised of the possibility of such damages or losses. Golteum will not be liable to you or anyone else (whether under contract, tort (including negligence) or otherwise) for any loss resulting from a cause over which Golteum does not have direct control. This includes, but not limited to, failure of electronic or mechanical equipment or communications lines (including telephone, cable and internet), script error, severe or extraordinary weather (including flood, earthquake, or other act of god), fire, war, insurrection, terrorist act, riot, labor dispute and other labor problems, accident, emergency or action of government. Golteum will not be liable for losses resulting from incorrect information entered when when purchasing, selling or transferring Token.</p><br />
                            <h4>IDEMNITY</h4>
                            <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys fees and expenses, made by any third party due to or arising out of: (1) use of the Service; (2) breach of these Terms; (3) any breach of your representations and warranties set forth in these Terms; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the www.golteum.io website. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defence and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defence of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>
                            <h4>CHANGES AND MODIFICATIONS</h4>
                            <p>We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Service at any time and for any reason. We will alert you about any changes by updating the "Last updated" date of these Terms of Service, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms of Service to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Service by your continued use of the www.golteum.io website after the date such revised Terms of Service are posted.</p><br />
                            <h4>JURISDICTION AND APPLICABLE LAW</h4>
                            <p>Except to the extent any applicable law provides otherwise, the Agreement and any access to or use of our Services will be governed by the laws of the British Virgin Islands (BVI). The proper venue for any disputes arising out of or relating to the Agreement and any access to or use of our Services will be in the courts located in the BVI.</p>
                            <h4>CONTACT</h4>
                            <p>If you have any questions relating to these Terms, please contact us via email on Send Mail: <a href="#" rel="noopener">info@golteum.io</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
