/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Watch } from 'react-loader-spinner'

const Timer = (props) => {

    let { start, end } = props;
    const now = Math.floor(((new Date()).getTime()) / 1000);
    const calculateTimeLeft = () => {


        let number = 0;
        if (start > now) {
            number = Number(start) - Number(now);
        } else {
            number = Number(end) - Number(now);
        }
        return number;
    };

    let [count, setCount] = useState(calculateTimeLeft());
    useEffect(() => {
        setTimeout(() => {
            setCount(calculateTimeLeft());
        }, 1000);
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (Number(count) > 0) {
                setCount(--count);
            }
        }, 1000);
        return () => clearInterval(interval);

    }, [count]);

    return (
        <><span style={{ textAlign: "center" }}>
            {/* {count > 0 ? (parseInt(start) > parseInt(now)) ? ('Start after ') : ('Time left ') : null} */}
            {count > 0 ?
                <div className="timeshow">
                {/* <img src="./images/timerIcon.png" className="timerimgc" /> */}
                <Watch
                                height="22"
                                width="22"
                                radius="40"
                                color="#9132EF"
                                ariaLabel="watch-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                <div className="timerPay row">
                    {/* <div className="col-md-3">
                        <div className="time-in timer-red">
                            <div className="time-bold">
                                {Math.floor(count / (60 * 60 * 24))}
                                <span>Days</span>
                            </div>
                        </div>
                    </div> */}
                        <div className="time-in">
                            <div className="time-bold">
                                00
                                <span>:</span>
                            </div>
                        </div>
                        <div className="time-in">
                            <div className="time-bold">
                                00
                                <span>:</span>
                            </div>
                        </div>
                        <div className="time-in">
                            <div className="time-bold">
                                {
                                    Math.floor((count % (60 * 60)) / (60)) >= 10 ? Math.floor((count % (60 * 60)) / (60)) : '0' + Math.floor((count % (60 * 60)) / (60))
                                }
                                <span>:</span>
                            </div>
                        </div>
                        <div className="time-in">
                            <div className="time-bold">
                                {Math.floor((count % (60))) >= 10 ? Math.floor((count % (60))) : '0' + Math.floor((count % (60)))}
                                <span></span>
                            </div>
                        </div>
                </div>
                </div>
               :
                <div className="timerPay row">
                    {/* <div className="col-md-3">
                        <div className="time-in timer-red">
                            <div className="time-bold">
                                00
                                <span>Days</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="time-in">
                            <div className="time-bold">
                                00
                                <span>Hours</span>
                            </div>
                        </div>
                    </div> */}
                    <div className="time-in">
                        <div className="time-bold">
                            00
                            <span>:</span>
                        </div>
                    </div>
                    <div className="time-in">
                        <div className="time-bold">
                            00
                            <span>:</span>
                        </div>
                    </div>
                    <div className="time-in">
                        <div className="time-bold">
                            00 
                           <span></span>
                        </div>
                    </div>
                </div>
            }
        </span></>
    );
};
export default Timer;