import React from "react";
import styled from "styled-components";

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width:150px;
  padding: 5px 10px!important;
  border-radius: 0;
  border: 1px solid hsla(0,0%,100%,.2901960784313726)!important;
  margin: 15px 0 15px!important;
  color: #fff!important;
  outline: none;
  background: #0000;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <form autoComplete="off">
    <Input
      id="search"
      type="text"
      autoComplete="off"
      placeholder="Search..."
      value={filterText}
      onChange={onFilter}
    />
    {/* <ClearButton onClick={onClear}>X</ClearButton> */}
    </form>
  </>
);

export default FilterComponent;
