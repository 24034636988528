import React from 'react'
import { privacyPolicy } from 'components/helper/userHelper'
export default function Footer() {
    return (
        <>
            <div className="dash-footer">
                <div className="row m-0">
                    <div className="col-md-4 p-0">
                        <div className="link-foot">
                            {/* <Link to="/privacy-policy">PRIVACY POLICY</Link> */}
                            <a href={privacyPolicy} rel="noopener">PRIVACY POLICY</a>
                            {/* <a href="#">TERMS OF USE</a> */}
                        </div>
                    </div>
                    <div className="col-md-4 p-0 link-c">
                        <span>© 2024 Golteum.io - All rights reserved.</span>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="social-login-ic">
                            <a href="https://twitter.com/golteum?s=20&t=Kirb38vwigNjUq0GgWCyUA" target="_blank" rel="noreferrer">
                                <i className='bi bi-twitter'></i>
                            </a>
                            <a href="https://t.me/golteum" target="_blank" rel="noreferrer">
                                <i className='bi bi-telegram'></i>
                            </a>
                            <a href="https://instagram.com/golteum?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
                                <i className='bi bi-instagram'></i>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100082846137280" target="_blank" rel="noreferrer">
                                <i className='bi bi-facebook'></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
