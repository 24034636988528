import React from "react";
import { Routes, Route } from "react-router-dom";
import Success from "components/PaymentStatus/Success";
const Index = () => {
  return (
      <Routes>
        <Route path="/" element={<Success />} />
      </Routes>
  );
};

export default Index;
