/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import '../../Css/Custom.css'
import Swal from 'sweetalert2';

import '../../Css/Responsive.css'
// import Header from '../Common/Header';
// import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
// import CardRight from '../Common/CardRight';
import { buyTokenTrans } from 'components/helper/userHelper';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Loader from 'components/Common/Loader';
import { CSVLink } from "react-csv";

export default function Index() {

    const [selectedTxs, setSelectedTxs] = useState([])
    const [copybtn, setcopybtn] = useState(false)
    const onCopyAdd = (val) => {
        console.log("val",val)
        Swal.fire({
            text: val + " copied",
            button: false,
            background: "black",
            icon: "success",
            timer: 2000,
        });
        // setcopybtn(true)
        // setTimeout(function () {
        //     setcopybtn(false)
        // }, 2000);
    };

    const transactionTable = {
        columns: [
            {
                name: "Serial",
                selector: row => row.id,
                sortable: true,
                maxWidth:"100px",
                minWidth:"100px",
            },
            {
                name: "Tnx ID",
                selector: row => row.paymentId ? (
                    <CopyToClipboard text={row.paymentId} className="  p-0"  onCopy={()=>{onCopyAdd(row.paymentId)}}>
                        <button style={{ border:"0", color: "#fff", backgroundColor: "#0000" }}>{row.paymentId} <i className="fa fa-clone copyto cpotyHover" style={{color:"#fff"}} aria-hidden="true"></i></button> 
                    </CopyToClipboard>
                    ) : '',
                button: true,
                maxWidth:"140px",
                minWidth:"140px",
                sortable: true
            },
            {
                name: "Tokens",
                selector: row => row.tokens,
                sortable: true,
                maxWidth:"170px",
                minWidth:"170px",
            },
            {
                name: "Amount",
                selector: row => row.amount,
                sortable: true,
                maxWidth:"150px",
                minWidth:"150px",
            },
            {
                name: "USD Amount",
                selector: row => row.priceAmount,
                sortable: true,
                maxWidth:"150px",
                minWidth:"150px",
                
            },
            {
                name: "Status",
                selector: row => row.status === "FINISHED" ? <span className="badge badge-pill badge-success">{row.status}</span> : row.status === "WAITING" ? <span className="badge badge-pill badge-warning">{row.status}</span> : <span className="badge badge-pill badge-danger">{row.status}</span>,
                sortable: true,
                className: 'red blue',
                style: {textTransform:'uppercase'} ,
                maxWidth:"150px",
                minWidth:"150px",
            },
            {
                name: "Date",
                selector: row => row.date,
                sortable: true,
                maxWidth:"220px",
                minWidth:"220px",
            },
        ],
        data: []
    }
    const headers1 = [
        { label: "Serial", key: "id" },
        { label: "Tnx ID", key: "paymentId" },
        { label: "Tokens", key: "tokens" },
        { label: "Amount", key: "amount" },
        { label: "USD Amount", key: "type" },
        { label: "Status", key: "status" },
        { label: "Date", key: "date" }
    ];

    const [transactionData, setTransactionData] = useState(transactionTable);
    const [showLoader, setShowLoader] = useState(false);
    const [reload, setReload] = useState(true);


    useEffect(() => {
        let isApiSubscribed = true;
        const getData = async () => {
            if (isApiSubscribed && reload) {
                setShowLoader(true)
                const transaction = await buyTokenTrans();
                let data = [];
                console.log("trasav", transaction);
                if (transaction.length) {
                    let id = 1;
                    for (let i = transaction.length; i > 0; i--) {
                        console.log("transaction[i - 1].payCurrency",transaction[i - 1].payCurrency , transaction[i - 1].payAmount)
                        let payAmount = transaction[i - 1].payAmount !== null && transaction[i - 1].payAmount !== '' && transaction[i - 1].payAmount !== undefined ?  Number(transaction[i - 1].payAmount).toFixed(5) : "-";
                        let payCurrency = transaction[i - 1].payCurrency !== null && transaction[i - 1].payCurrency !== '' && transaction[i - 1].payCurrency !== undefined ? transaction[i - 1].payCurrency.toUpperCase() : ' ';
                        let tokens = transaction[i - 1].token !== null && transaction[i - 1].token !== '' && transaction[i - 1].token !== undefined ? Number(transaction[i - 1].token).toFixed(4) + ' $GLTM' : '-' 
                        let priceAmount = transaction[i - 1].priceAmount  !== null && transaction[i - 1].priceAmount  !== '' && transaction[i - 1].priceAmount  !== undefined ? transaction[i - 1].priceAmount : '-'
                        let details = {
                            "id":id,
                            "paymentId": transaction[i - 1].paymentId,
                            "tokens": tokens ,
                            "amount": payAmount + ' ' +  payCurrency,
                            "priceAmount": priceAmount,
                            "type": transaction[i - 1].type,
                            "hash": '-',
                            "status": transaction[i - 1].status,
                            "date": new Date(transaction[i - 1].date).toLocaleString(),
                        }
                        id++;
                        data.push(details)
                    }
                }
                // console.log("kkdkdkdkdkdkddkdkdkdkk",data)
                setTransactionData({ ...transactionData, data: data });


                setShowLoader(false)
            }
        }
        getData();
        return () => {
            isApiSubscribed = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    const handleSelected = (event) =>{
        console.log(event.selectedRows)
        setSelectedTxs(event.selectedRows)
        // setSelectedPayment(selectedPayment=>{
        //     return{
        //         ...selectedPayment,
        //         count:event.selectedCount,
        //         data:event.selectedRows
        //     }
        // })
        // getBulkSenderUpload(event)
        // const newObject = {"Serial" : event.selectedCount-1, "Address" : event.selectedRows[event.selectedCount].wallet, "Amount" : event.selectedRows[event.selectedCount].tokens }
    }


    return (
        <>
            <Helmet>
                <title>Transaction | Golteum</title>
            </Helmet>
            <Loader show={showLoader} />
            <div className="dash-container">
                <div className="page-tit">
                    <h4>Transaction History</h4>
                </div>

                <div className="dash-container-in">
                    <div className="row dash-home">
                        <div className="col-md-12 d-block">
                            <div className="card-cust table-cust-in tnxtble mt-3 mb-3">
                                {(transactionData.data.length > 0) ? (
                                    <>
                                        <CSVLink className="downloadBtn" headers={headers1} filename="Transaction History.csv" data={selectedTxs.length > 0 ? selectedTxs :  transactionData["data"]}>Download&nbsp;&nbsp;<i className="fa fa-download" aria-hidden="true"></i></CSVLink>
                                        <DataTableExtensions {...transactionData}>
                                            <DataTable
                                                columns={transactionData["columns"]}
                                                data={transactionData["data"]}
                                                defaultSortField="Serial"
                                                // sortIcon={<SortIcon />}
                                                defaultSortAsc={true}
                                                pagination
                                                className='txTable'
                                                highlightOnHover
                                                dense
                                                selectableRows
                                                onSelectedRowsChange={handleSelected}
                                            />
                                        </DataTableExtensions>
                                    </>
                                ) : <div style={{overflow:"auto"}}><table className="table-cust table dt-responsive nowrap">
                                    <thead>
                                        <tr className='text-center' >
                                            <th>Serial</th>
                                            <th style={{ maxWidth:"100px", minWidth:"100px",}}>Tnx ID</th>
                                            <th>Tokens</th>
                                            <th>Amount</th>
                                            <th style={{ maxWidth:"150px", minWidth:"150px",}}>USD Amount</th>
                                            <th>To</th>
                                            {/* <th>Type</th>
                                            <th>Tnx Hash</th> */}
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="7"><p style={{ textAlign: "center" }}>No Data Found</p></td>
                                        </tr>
                                    </tbody>
                                </table></div>}
                                {/* <table id="datatable" className="table-cust table dt-responsive nowrap" style={{ borderCollapse: "collapse", borderSpacing: "0", width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Position</th>
                                            <th>Office</th>
                                            <th>Age</th>
                                            <th>Start date</th>
                                            <th>Salary</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Tiger Nixon</td>
                                            <td>System Architect</td>
                                            <td>Edinburgh</td>
                                            <td>61</td>
                                            <td>2011/04/25</td>
                                            <td>$320,800</td>
                                        </tr>
                                        <tr>
                                            <td>Garrett Winters</td>
                                            <td>Accountant</td>
                                            <td>Tokyo</td>
                                            <td>63</td>
                                            <td>2011/07/25</td>
                                            <td>$170,750</td>
                                        </tr>
                                        <tr>
                                            <td>Ashton Cox</td>
                                            <td>Junior Technical Author</td>
                                            <td>San Francisco</td>
                                            <td>66</td>
                                            <td>2009/01/12</td>
                                            <td>$86,000</td>
                                        </tr>
                                        <tr>
                                            <td>Cedric Kelly</td>
                                            <td>Senior Javascript Developer</td>
                                            <td>Edinburgh</td>
                                            <td>22</td>
                                            <td>2012/03/29</td>
                                            <td>$433,060</td>
                                        </tr>
                                        <tr>
                                            <td>Airi Satou</td>
                                            <td>Accountant</td>
                                            <td>Tokyo</td>
                                            <td>33</td>
                                            <td>2008/11/28</td>
                                            <td>$162,700</td>
                                        </tr>
                                        <tr>
                                            <td>Brielle Williamson</td>
                                            <td>Integration Specialist</td>
                                            <td>New York</td>
                                            <td>61</td>
                                            <td>2012/12/02</td>
                                            <td>$372,000</td>
                                        </tr>
                                        <tr>
                                            <td>Herrod Chandler</td>
                                            <td>Sales Assistant</td>
                                            <td>San Francisco</td>
                                            <td>59</td>
                                            <td>2012/08/06</td>
                                            <td>$137,500</td>
                                        </tr>
                                        <tr>
                                            <td>Airi Satou</td>
                                            <td>Accountant</td>
                                            <td>Tokyo</td>
                                            <td>33</td>
                                            <td>2008/11/28</td>
                                            <td>$162,700</td>
                                        </tr>
                                        <tr>
                                            <td>Brielle Williamson</td>
                                            <td>Integration Specialist</td>
                                            <td>New York</td>
                                            <td>61</td>
                                            <td>2012/12/02</td>
                                            <td>$372,000</td>
                                        </tr>
                                        <tr>
                                            <td>Herrod Chandler</td>
                                            <td>Sales Assistant</td>
                                            <td>San Francisco</td>
                                            <td>59</td>
                                            <td>2012/08/06</td>
                                            <td>$137,500</td>
                                        </tr>
                                        <tr>
                                            <td>Airi Satou</td>
                                            <td>Accountant</td>
                                            <td>Tokyo</td>
                                            <td>33</td>
                                            <td>2008/11/28</td>
                                            <td>$162,700</td>
                                        </tr>
                                        <tr>
                                            <td>Brielle Williamson</td>
                                            <td>Integration Specialist</td>
                                            <td>New York</td>
                                            <td>61</td>
                                            <td>2012/12/02</td>
                                            <td>$372,000</td>
                                        </tr>
                                        <tr>
                                            <td>Herrod Chandler</td>
                                            <td>Sales Assistant</td>
                                            <td>San Francisco</td>
                                            <td>59</td>
                                            <td>2012/08/06</td>
                                            <td>$137,500</td>
                                        </tr>
                                    </tbody>
                                </table> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
