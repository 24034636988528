import React from "react";
import { Routes, Route } from "react-router-dom";
// import Login from "components/User/Login/Index";
import Term from "components/Term/Index"

const Index = () => {
  return (
      <Routes>
        <Route path="/" element={<Term />} />
      </Routes>
  );
};

export default Index;
