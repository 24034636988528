import { event } from 'jquery';
import React, { useState, useEffect } from 'react'
import Web3 from 'web3';
import { addWalletAddress, getUserDetails, buyTokenTrans, changeCurrToSelected, changeWalletAddress, walletAddStatus, sendRequestAdd } from 'components/helper/userHelper';
import Swal from 'sweetalert2';
import Loader from 'components/Common/Loader';
import { useNavigate } from "react-router-dom";
import { useUser } from 'components/UserContext';
import cont1 from './img/cont1.png'
import cont2 from './img/cont2.png'
import cont3 from './img/cont3.png'
import walletImg from './img/wallet.png'

export default function CardRight() {
    const { users } = useUser();
    const navigate = useNavigate();
    const [loaderAct1, setloaderAct1] = useState(false);
    const [loaderAct2, setloaderAct2] = useState(false);
    const [loaderAct3, setloaderAct3] = useState(false);
    const [walletAdd, setWalletAdd] = useState();
    const [totalUsd, settotalUsd] = useState(0)
    const [totalEth, settotalEth] = useState(0)
    const [totalBTC, settotalBTC] = useState(0)
    const [requestBtn, setRequestBtn] = useState(false)
    const [user, setUser] = useState({
        wallet: "",
        tokenBal: 0,
    });
    const [OnLoader, setOnLoader] = useState(false)
    const [addressStatus, setAddressStatus] = useState(false)
    const [otpCode, setOtpCode] = useState('')
    const [txId, setTxId] = useState('')

    useEffect(() => {
        let isAPISubscribed = true;
        if (isAPISubscribed) {
            setUser({
                wallet: users.wallet,
                tokenBal: users.tokenBal,
            })
            getTotalUsd()
            statusAdd()
        }
        return () => {
            isAPISubscribed = false
        }
    }, [users.wallet, users.tokenBal]);

    const getTotalUsd = async () => {
        let totalUsd = 0;
        const usdInfo = await buyTokenTrans();
        if(usdInfo){
            if (usdInfo.length) {
                for (let i = usdInfo.length; i > 0; i--) {
                    //waiting finished
                    if (usdInfo[i - 1].status === 'FINISHED') {
                        // console.log("add")
                        totalUsd = totalUsd + usdInfo[i - 1].priceAmount;
                    }
    
                }
                if (totalUsd > 0) {
                    let inETH = await changeCurrToSelected(totalUsd, 'usd', 'eth')
                    if(inETH){
                        // console.log("inbtc", inETH)
                        if (inETH.data.estimated_amount) {
                            settotalEth(Number(inETH.data.estimated_amount).toFixed(5))
                            // console.log("type of ETH", typeof(inETH.data.estimated_amount))
                            let inBTC = await changeCurrToSelected(totalUsd, 'usd', 'btc')
                            if(inBTC){
                                if (inBTC.data.estimated_amount) {
                                    settotalBTC(Number(inBTC.data.estimated_amount).toFixed(5))
                                    // console.log("type of ETH", typeof(inBTC.data.estimated_amount))
                                }
                            }
                            else{
                                console.log("Error while processing your request please try again later")
                            }
                        }
                    }
                    else{
                        console.log("Error while processing your request please try again later")
                    }
                }
            }
            settotalUsd(totalUsd);
        }
    }

    const addWalletAdd = async () => {

        let result = Web3.utils.isAddress(walletAdd)
        if (result) {
            setloaderAct1(true)
            let res = await addWalletAddress(walletAdd);
            if (res) {
                if (res.data.success) {
                    setloaderAct1(false)
                    Swal.fire({
                        showConfirmButton: false, button: false,
                        text: res.data.msg,
                        button: false,
                        confirmButtonText: 'Close',
                        background: 'black',
                        icon: "success",
                        timer: 4000,
                    });
                    setUser({
                        wallet: walletAdd,
                    })
                }
                else {
                    setloaderAct1(false)
                    Swal.fire({
                        showConfirmButton: false, button: false,
                        text: res.data.msg,
                        button: false,
                        confirmButtonText: 'Close',
                        background: 'black',
                        icon: "error",
                        timer: 4000,
                    });
                }
                // console.log("res", res)
            }
            else {
                Swal.fire({
                    text: "Error while processing your request please try again later",
                    button: false,
                    background: "black",
                    icon: "error",
                    timer: 2000,
                });
            }


        }
        else {
            setloaderAct1(false)
            Swal.fire({
                showConfirmButton: false, button: false,
                text: 'Please enter valid address',
                button: false,
                confirmButtonText: 'Close',
                background: 'black',
                icon: "error",
                timer: 4000,
            });
        }
    }

    const requestForChangeFn = async () => {
        // console.log("addrss",walletAdd)
        let result = Web3.utils.isAddress(walletAdd)
        if (result) {
            if (otpCode !== '') {
                setloaderAct2(true)
                let res = await changeWalletAddress(walletAdd, txId, otpCode);
                if (res) {
                    if (res.data.success) {
                        setloaderAct2(false)
                        Swal.fire({
                            showConfirmButton: false, button: false,
                            text: res.data.msg,
                            button: false,
                            confirmButtonText: 'Close',
                            background: 'black',
                            icon: "success",
                            timer: 4000,
                        });
                        setWalletAdd('')
                        setOtpCode('')
                        setRequestBtn(false)
                        setAddressStatus(true)
                    }
                    else {
                        setloaderAct2(false)
                        Swal.fire({
                            showConfirmButton: false, button: false,
                            text: res.data.msg,
                            button: false,
                            confirmButtonText: 'Close',
                            background: 'black',
                            icon: "error",
                            timer: 4000,
                        });
                    }
                    // console.log("res", res)
                }
                else {
                    Swal.fire({
                        text: "Error while processing your request please try again later",
                        button: false,
                        background: "black",
                        icon: "error",
                        timer: 2000,
                    });
                }

            }
            else {
                setloaderAct2(false)
                Swal.fire({
                    showConfirmButton: false, button: false,
                    text: 'Please enter valid OTP',
                    button: false,
                    confirmButtonText: 'Close',
                    background: 'black',
                    icon: "error",
                    timer: 4000,
                });
            }
        }
        else {
            setloaderAct2(false)
            Swal.fire({
                showConfirmButton: false, button: false,
                text: 'Please enter valid address',
                button: false,
                confirmButtonText: 'Close',
                background: 'black',
                icon: "error",
                timer: 4000,
            });
        }
    }

    const requestForChnage = async () => {
        setloaderAct3(true)
        let res = await sendRequestAdd();
        if(!res){
            setloaderAct3(false)
            Swal.fire({
                text: "Error while processing your request please try again later",
                button: false,
                background: "black",
                icon: "error",
                timer: 2000,
            });
        }else{
            if (res.data.success) {
                setloaderAct3(false)
                setTxId(res.data.data.tx_id)
                Swal.fire({
                    showConfirmButton: false, button: false,
                    text: res.data.msg,
                    confirmButtonText: 'Close',
                    background: 'black',
                    icon: "success",
                    timer: 4000,
                });
                setRequestBtn(true);
                setWalletAdd('')
                setAddressStatus(false)
            }else {
                setloaderAct3(false)
                Swal.fire({
                    showConfirmButton: false, 
                    button: false,
                    text: res.data.msg,
                    confirmButtonText: 'Close',
                    background: 'black',
                    icon: "error",
                    timer: 4000,
                });
            }
        }

    }

    const statusAdd = async () => {
        let res = await walletAddStatus();
        if(res){
            setAddressStatus(res.success)
        }
    }
    
    return (
        <>
            <Loader text="" show={OnLoader} />
            <div className="card-cust">
                <div className="token-blc">
                    <figcaption>
                        {/* <span>Token balance</span> */}
                        <h4>Token Balance</h4>
                        {/* <p>0.00000000 $GLTM</p> */}
                        <p>{Number(user.tokenBal).toFixed(0)} $GLTM</p>
                    </figcaption>
                    <figure>
                        <img className="logoSize" src="images/logoLoader.png" alt=""/>
                    </figure>
                </div>
                <div className="contribution-sec">
                    <h4>Your Contribution in:</h4>
                    <div className="row heightSpan">
                        <div className="col-md-4">
                            <article>
                                <figure>
                                    <img src={cont1} alt=""/>
                                </figure>
                                <span>{totalUsd ? totalUsd : '0'} USD</span>
                            </article>
                        </div>
                        <div className="col-md-4">
                            <article>
                                <figure>
                                    <img src={cont2} alt=""/>
                                </figure>
                                <span>{totalBTC ? totalBTC : "0"} BTC</span>
                            </article>
                        </div>
                        <div className="col-md-4">
                            <article>
                                <figure>
                                    <img src={cont3} alt=""/>
                                </figure>
                                <span>{totalEth ? totalEth : "0"} ETH</span>
                            </article>
                        </div>
                    </div>
                </div>
                <div className="wallet-opt">
                    <img src={walletImg} alt=""/>
                    <h4>Your Receiving Wallet (ERC20)</h4>
                    {
                        user.wallet
                            ?
                            <>
                                {requestBtn
                                    ?
                                    <>
                                        <div className="form-group">
                                            <input type="text" name="" className="form-control" placeholder="Add Your Wallet Address" value={walletAdd} onChange={(event) => { setWalletAdd(event.target.value) }} />
                                            <input type="number" name="" className="form-control mt-3" placeholder="Enter OTP Code" value={otpCode} onChange={(event) => { setOtpCode(event.target.value) }} />
                                        </div>
                                        {loaderAct2 ? <button className="btn btn-grd" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> : <button type="button" className="btn btn-grd" onClick={requestForChangeFn} >Request For Update Wallet</button>}
                                        <span className="wt-warning">Warning: Please enter your ERC20 address only.</span>
                                    </>
                                    :
                                    <>
                                        <div className="form-group">
                                            <input type="text" name="" className="form-control" placeholder="Add Your Wallet Address" value={user.wallet.substring(0, 10) + '...' + user.wallet.slice(-10)} disabled />
                                        </div>
                                        {loaderAct3 ? <button className="btn btn-grd" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> : <button className="btn btn-grd" onClick={requestForChnage}>Request Change</button>}
                                    </>
                                }
                                {addressStatus ? <span className="wt-pending">New address under review for approval.</span> : ''}

                            </>
                            :
                            <>
                                <div className="form-group">
                                    <input type="text" name="" className="form-control" placeholder="Add Your Wallet Address" value={walletAdd} onChange={(event) => { setWalletAdd(event.target.value) }} />
                                </div>
                                {loaderAct1 ? <button className="btn btn-grd" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> : <button type="button" className="btn btn-grd" onClick={addWalletAdd} >Add Your Wallet Address</button>}
                                <span className="wt-warning">Warning: Please enter your ERC20 address only.</span>
                            </>
                    }
                    {/* <img className="" src="images/MetaMask.png" alt="" style={{width:"70px",paddingTop:"10px"}}/> */}
                </div>
            </div>
        </>
    )
}
