import React, { useState } from 'react'
import { waitListAdd } from 'components/helper/userHelper';
import Swal from 'sweetalert2';

export default function Waitlist(props) {
  const [loaderAct, setloaderAct] = useState(false);

  const waitListAdd_fn = async() =>{
    if(props.userInfo.email){
      setloaderAct(true)
      let result = await waitListAdd( props.userInfo.email);
      if(!result){
        setloaderAct(false)
        Swal.fire({
          text: "Error while processing your request please try again later",
          button: false,
          background: "black",
          icon: "error",
          timer: 2000,
        });
      }else{
        setloaderAct(false)
        if (result.data.success === true) {
            Swal.fire({ showConfirmButton:false, button:false, 
                text: result.data.msg,
                // button: false,
                background: 'black',
                icon: "success",
                timer: 3000,
              });
        }else{
          setloaderAct(false)
            Swal.fire({ showConfirmButton:false, button:false, 
                text: result.data.msg,
                // button: false,
                background: 'black',
                icon: "error",
                timer: 4000,
              });
        }
      }
    }
  }

  return (
    <>
      <div className={props.toggleState === 4 ? "content active-content col-md-8" : "content col-md-8"}>
        <div className="waitlist-sec">
          <div className="card-cust p-4">
            <h4>Waitlist</h4>
            <div className="form-group mt-4">
              <input type="text" name="" placeholder="Enter Email Address" className="form-control" value={props.userInfo.email} disabled/>
              {loaderAct ? <button className="btn btn-grd" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> : <button className="btn btn-grd" onClick={waitListAdd_fn}>Subscribe</button>}
            </div>
            <div className="form-group mt-4 waitlist-msg">
            <span className="wt-warning">Please subscribe to the waitlist in order to receive an email notification 72hrs before the next stage is live.</span>
            </div>
            {/* <div className="check-list">
              <div className="form-check mb-2">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label" for="flexCheckDefault">
                    Notification Subscribe
                  </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1" />
                  <label className="form-check-label" for="flexCheckDefault1">
                    News Letter Subscribe
                  </label>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
