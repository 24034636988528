import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Swal from 'sweetalert2';
import '../Css/Custom.css'
import { resetPassword, forgetPasswordVerification, privacyPolicy } from "components/helper/userHelper";
import qs from 'qs';
import Captcha from 'components/Common/Captcha';
import Loader from 'components/Common/Loader';

function ResetPassword() {
    let params = new URL(document.location).searchParams;
    let token = params.get("token");
    let key = params.get("key");
    const Navigate = useNavigate()
    const Password = useRef();
    const cPassword = useRef();
    const [loaderAct, setloaderAct] = useState(false);
    const [captchaErr, setCaptchaErr] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [formData, setFormData] = useState({
        password: "",
        passwordErr: "",
        isPasswordValid: false,
        confirmPassword: "",
        confirmPasswordErr: false,
    })
    const [captchaVal, setCaptchaVal] = useState(null)

    const handleCaptchaChange = (value) => {
        setCaptchaVal(value)
    }

    const handleOnChangePassword = (event) => {
        let passVal = /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d@#$^!%*?&_]{6,}$/;
        let passValTrue = passVal.test(event.target.value);
        if (passValTrue) {
            setFormData(formData => {
                return {
                    ...formData,
                    password: event.target.value,
                    isPasswordValid: true,
                    passwordErr: ""
                }
            })
        } else {
            if (event.target.value === "") {
                setFormData(formData => {
                    return {
                        ...formData,
                        password: event.target.value,
                        isPasswordValid: false,
                        passwordErr: " This field is required"
                    }
                })
            } else {
                setFormData(formData => {
                    return {
                        ...formData,
                        password: event.target.value,
                        isPasswordValid: false,
                        passwordErr: " Must contain at least one uppercase and lowercase letter, and at least 6 or more characters"
                    }
                })
            }
        }
    };
    const handleOnChangeConfirmPassword = (value) => {
        if (value !== "") {
            setFormData(formData => {
                return {
                    ...formData,
                    confirmPasswordErr: false,
                }
            })
        } else {
            setFormData(formData => {
                return {
                    ...formData,
                    confirmPasswordErr: " This field is required.",
                }
            })
        }
    };


    
    useEffect(() => {
        let isAPISubscribed = true;
        const verify = async () => {
            if (token !== null && key !== null) {
                let data = qs.stringify({
                    'token': token,
                    'key': key
                });
                setShowLoader(true)
                let response = await forgetPasswordVerification(data);
                if(!response){
                    setShowLoader(false)
                    Swal.fire({
                        text: "Error while processing your request please try again later",
                        button: false,
                        background: "black",
                        icon: "error",
                        timer: 2000,
                    });
                }else{
                    setShowLoader(false)
                    // console.log('verification', response)
                    if (response.success === true) {
                    } else {
                        Navigate('/404');
                    }
                }
            } else {
               Navigate('/');
            }
        }
        if(isAPISubscribed){
            verify();
        }
        
        return ()=>{
            isAPISubscribed = false
        }
    }, [Navigate, key, token])

    const resetFn = async () => {
        if (formData.password !== undefined && formData.password !== "") {
            if (formData.confirmPassword !== undefined && formData.confirmPassword !== "") {
                if (cPassword.current.value === Password.current.value) {
                    if (captchaVal !== '' && captchaVal !== undefined) {
                        if (formData.isPasswordValid) {
                            setloaderAct(true)

                            let data = qs.stringify({
                                token: token,
                                key: key,
                                password: formData.password,
                                re_password: formData.confirmPassword,
                                captcha: captchaVal
                            });
                            // console.log(tokenID)
                            // console.log(formData.password)
                            // console.log(formData.confirmPassword)
                            let response = await resetPassword(data);
                            if(!response){
                                Swal.fire({
                                    text: "Error while processing your request please try again later",
                                    button: false,
                                    background: "black",
                                    icon: "error",
                                    timer: 2000,
                                });
                                setCaptchaVal(null)
                                window.grecaptcha.reset();
                                setloaderAct(false)
                            }else{
                                if (response.data.success === true) {
                                    Swal.fire({ showConfirmButton:false,
                                        text: response.data.msg,
                                        button: false,
                                        background: 'black',
                                        icon: "success",
                                        confirmButtonText: 'Close',
                                    });
                                   
                                    setTimeout(function () {
                                        Navigate('/');
                                    }, 3000);
                                    setFormData(formData => {
                                        return {
                                            ...formData,
                                            password: "",
                                            confirmPassword: "",
                                        }
                                    })
                                    setloaderAct(false)
                                    setCaptchaVal(null)
                                    setCaptchaErr("")
                                    window.grecaptcha.reset();
                                } else {
                                    setloaderAct(false)
                                    Swal.fire({ showConfirmButton:false,
                                        text: response.data.msg,
                                        button: false,
                                        background: 'black',
                                        icon: "error",
                                        confirmButtonText: 'Close',
                                    });
                                    setCaptchaVal(null)
                                    window.grecaptcha.reset();
                                }
                            }
                        } else {
                            setFormData(formData => {
                                return {
                                    ...formData,
                                    passwordErr: " Password is invalid",
                                }
                            })
                        }
                    } else {
                        setCaptchaErr(" This field is required")
                    }

                } else {
                    setFormData(formData => {
                        return {
                            ...formData,
                            confirmPasswordErr: " Password not matched",
                        }
                    })
                }
            } else {
                setFormData(formData => {
                    return {
                        ...formData,
                        confirmPasswordErr: " This field is required",
                    }
                })
            }
        } else {
            setFormData(formData => {
                return {
                    ...formData,
                    passwordErr: " This field is required",
                }
            })
        }
    };
    const submitOnEnter = (event) => {
        if (event.key === "Enter") {
            resetFn();
        }
    }
    return (
        <>
            <Loader show={showLoader} />
            <Helmet>
                <style>{"body { background-color: #000; }"}</style>
            </Helmet>
            <div className="cssForAll">
                <div className="login-wrap">
                    <div className="start-box">
                        <div id="stars"></div>
                        <div id="stars2"></div>
                        <div id="stars3"></div>
                    </div>
                    <div className="login-box main-box">
                        <div className="login-logo">
                            <div className="logo-form"> <a href="#" rel="noopener">
                                <img className="logoSize" src="images/logo.png" alt=""/>
                            </a></div>
                            <div className="lable-innput ">
                                <label className="mt-4" style={{ color: "#88898D" }}>New Password</label>
                                <input type="password" placeholder="Enter New Password" name=""
                                    onChange={handleOnChangePassword}
                                    value={formData.password}
                                    ref={Password}
                                    onKeyUp={submitOnEnter}
                                />
                                <div>
                                    <span style={{ color: "red", display: "block", textAlign: 'left', marginTop: '10px' }}>{formData.passwordErr}</span>
                                </div>
                                <label className="mt-4" style={{ color: "#88898D" }}>New Re-Password</label>
                                <input type="password" placeholder="Enter New Re-Password" name=""
                                    onChange={(event) => handleOnChangeConfirmPassword(
                                        setFormData(formData => {
                                            return {
                                                ...formData,
                                                confirmPassword: event.target.value,
                                            }
                                        })
                                    )}
                                    value={formData.confirmPassword}
                                    ref={cPassword}
                                    onKeyUp={submitOnEnter}
                                />
                                <div>
                                    <span style={{ color: "red", display: "block", textAlign: 'left', marginTop: '10px' }}>{formData.confirmPasswordErr}</span>
                                </div>
                            </div>
                            <div className='captcha'>
                                <Captcha handleCaptchaChange={handleCaptchaChange} />
                            </div>
                            <div className='text-center'>
                                <span style={{ color: "red" }} >{captchaErr}</span>
                            </div>
                            {loaderAct ? <button className="btn btn-yellow w-100 mt-4" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> :
                                <button className="btn btn-yellow w-100 mt-4" onClick={resetFn}>Reset password</button>
                            }
                        </div>
                    </div>
                    <div className="footer-item">
                        <h4>JOIN OUR COMMUNITY</h4>
                        <div className="social-login-ic">
                            <a href="https://twitter.com/golteum?s=20&t=Kirb38vwigNjUq0GgWCyUA" target="_blank" rel="noreferrer">
                                <i className='bi bi-twitter'></i>
                            </a>
                            <a href="https://t.me/golteum" target="_blank" rel="noreferrer">
                                <i className='bi bi-telegram'></i>
                            </a>
                            <a href="https://instagram.com/golteum?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
                                <i className='bi bi-instagram'></i>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100082846137280" target="_blank" rel="noreferrer">
                                <i className='bi bi-facebook'></i>
                            </a>
                        </div>
                        <div className="link-f">
                            <a href={privacyPolicy} target="_blank" rel="noreferrer">PRIVACY POLICY</a>
                            {/* <Link to="/privacy-policy">PRIVACY POLICY</Link> */}
                            {/* <Link to="/term">TERMS OF USE</Link> */}
                        </div>
                        <div className="copy-r">
                            © 2024 Golteum.io - All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ResetPassword