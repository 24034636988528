/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import { landingInfo } from "components/helper/userHelper";
import { Link } from "react-router-dom";

const Header = (props) => {

  const now = Math.floor(((new Date()).getTime()) / 1000);
  const [user, setUser] = useState({
    startTime: "0",
    endTime: "0",
    tokenPrice: "0",
    stageNumber: "0",
    tokenAmount: "0",
    amtSold: "0",
  });

  // useEffect(() => {
  //   fatchLandingInfo();
  // }, []);

  // const fatchLandingInfo = async () => {
  //   // setOnLoader(true)
  //   let res = await landingInfo();
  //   if (res.data.success) {
  //     // console.log("asasasaas", res.data)
  //     setUser({
  //       startTime: res.data.data.StageData[0].startTime,
  //       endTime: res.data.data.StageData[0].endTime,
  //       tokenPrice: res.data.data.StageData[0].tokenPrice,
  //       stageNumber: res.data.data.StageData[0].stageNumber,
  //       tokenAmount: res.data.data.StageData[0].totalTokens,
  //       amtSold: res.data.data.StageData[0].remainingTokens,
  //     });
  //   }
  // }

  return (
    <header>
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <Link className="navbar-brand" to="/">
            <img className="logoSize New-logo" src="images/GLTM-Logo.png" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
               <span className="my-1 mx-2 close">X</span>
       <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="menu-btn d-flex mr-auto">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    aria-current="page"
                    data-page="home"
                    rel="noopener"
                  >
                    home
                  </a>
                </li>
             
            
                <li className="nav-item">
                  <a className="nav-link" href="#platform" data-page="platform" rel="noopener">
                    platform
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#token-nav" data-page="token-nav" rel="noopener">
                    token
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#roadmap" data-page="roadmap" rel="noopener">
                    roadmap
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#whitepaper" data-page="whitepaper" rel="noopener">
                    whitepaper
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#faq" data-page="faq" rel="noopener">
                    faq<span className="capitaltext">s</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-page="waitlist">
                   $10k competition
                  </a>
                  {/* <Link className="nav-link" to="/waitlist">
                    $10k competition
                  </Link> */}
                </li>
              </ul>
            </div>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 right-btns">
              {user.startTime === "0" ?
                <li className="nav-item btns mr-2">
                  <Link className="nav-link btn btn-yellow" to="/dashboard">
                    Dashboard&nbsp;&nbsp;
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </Link>
                </li>
                :
                <>
      
                  {user.startTime > now ? 
                    <li className="nav-item btns mr-2">
                    <Link className="nav-link btn btn-yellow" to="/join-waitlist">
                    Join The Waitlist&nbsp;&nbsp;
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </Link>
                  </li>
                  : 
                  <>
                  <li className="nav-item btns mr-2">
                    <Link className="nav-link btn btn-yellow" to="/">
                      Log in&nbsp;&nbsp;
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <li className="nav-item btns mr-2">
                    <Link className="nav-link btn btn-yellow" to="/register">
                      Sign up&nbsp;&nbsp;
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </Link>
                  </li>
                  </>
                   }

                </>
              }

            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
