import axios from 'axios';
import qs from "qs"
import Web3 from "web3";

export const ACTIVE_NETWORK_eth = 1;
export const ACTIVE_NETWORK_bsc = 56;
// export const web3 = new Web3(Web3.givenProvider || "https://goerli.infura.io/v3/");
export const web3 = new Web3(Web3.givenProvider);

const mainAxios = axios.create({
    baseURL : "https://golteom.io",
    withCredentials : true,
    headers : {
    'X-Frame-Options':'SAMEORIGIN',
    'Access-Control-Allow-Origin': "https://golteom.io",
    'Access-Control-Allow-Credentials': true,
    'Content-Type': 'application/x-www-form-urlencoded',
    },
    
})

const subAxios = axios.create({
    headers :{

    }
})
//Setting Axios
axios.defaults.baseURL = "https://golteom.io";
axios.defaults.withCredentials = true;
axios.defaults.headers['X-Frame-Options'] = 'SAMEORIGIN';

// testnet api link 
// export const paymentApi = "https://api-sandbox.nowpayments.io/v1/"

// live api link
export const paymentApi = "https://api.nowpayments.io/v1/"


// dev environment
// export const baseURL = "https://devapi.golteum.io/api/"
// export const tokenApiKey = "8BJG007-4Z9M71V-QG9WHN7-DX34F3V"
// // export const tokenApiKey = "WTAHEKV-68V4VPG-JVWBGHZ-1XDQ4D0"
// export const terms = "https://dev.golteum.io/term"
// export const privacyPolicy = "https://dev.golteum.io/privacy-policy"
// export const ssoId = '858371677869-2035ce792bvu6s2splrqek98dq88sqd3.apps.googleusercontent.com'
// export const sitekeyCaptcha = '6Lc8bD4iAAAAACs1C50sOiUrPFB42Ec--nGC4B3K'


//test environment
export const baseURL = "https://api.golteum.io/api/"
export const tokenApiKey = "WTAHEKV-68V4VPG-JVWBGHZ-1XDQ4D0"
export const terms = "https://golteum.io/term"
export const privacyPolicy = "https://golteum.io/privacy-policy"
export const ssoId = '493945054970-ur4t5sqm9pbuold22qvnhjmamsi1ke7b.apps.googleusercontent.com'
export const sitekeyCaptcha = '6Lfrf1wjAAAAAIVdRyRrMTrB9MXOxruhQwzLQBJ-'

const contract_abi_usdc = [
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "authorizer",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            }
        ],
        "name": "AuthorizationCanceled",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "authorizer",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            }
        ],
        "name": "AuthorizationUsed",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_account",
                "type": "address"
            }
        ],
        "name": "Blacklisted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "newBlacklister",
                "type": "address"
            }
        ],
        "name": "BlacklisterChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "burner",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Burn",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "newMasterMinter",
                "type": "address"
            }
        ],
        "name": "MasterMinterChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "minter",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Mint",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "minter",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "minterAllowedAmount",
                "type": "uint256"
            }
        ],
        "name": "MinterConfigured",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "oldMinter",
                "type": "address"
            }
        ],
        "name": "MinterRemoved",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [],
        "name": "Pause",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "newAddress",
                "type": "address"
            }
        ],
        "name": "PauserChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "newRescuer",
                "type": "address"
            }
        ],
        "name": "RescuerChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_account",
                "type": "address"
            }
        ],
        "name": "UnBlacklisted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [],
        "name": "Unpause",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "CANCEL_AUTHORIZATION_TYPEHASH",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DOMAIN_SEPARATOR",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "PERMIT_TYPEHASH",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "RECEIVE_WITH_AUTHORIZATION_TYPEHASH",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "TRANSFER_WITH_AUTHORIZATION_TYPEHASH",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "authorizer",
                "type": "address"
            },
            {
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            }
        ],
        "name": "authorizationState",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_account",
                "type": "address"
            }
        ],
        "name": "blacklist",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "blacklister",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "authorizer",
                "type": "address"
            },
            {
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "cancelAuthorization",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "minter",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "minterAllowedAmount",
                "type": "uint256"
            }
        ],
        "name": "configureMinter",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "currency",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "decrement",
                "type": "uint256"
            }
        ],
        "name": "decreaseAllowance",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "increment",
                "type": "uint256"
            }
        ],
        "name": "increaseAllowance",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "tokenName",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "tokenSymbol",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "tokenCurrency",
                "type": "string"
            },
            {
                "internalType": "uint8",
                "name": "tokenDecimals",
                "type": "uint8"
            },
            {
                "internalType": "address",
                "name": "newMasterMinter",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "newPauser",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "newBlacklister",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "newName",
                "type": "string"
            }
        ],
        "name": "initializeV2",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "lostAndFound",
                "type": "address"
            }
        ],
        "name": "initializeV2_1",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_account",
                "type": "address"
            }
        ],
        "name": "isBlacklisted",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "isMinter",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "masterMinter",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "mint",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "minter",
                "type": "address"
            }
        ],
        "name": "minterAllowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "nonces",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "pause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "pauser",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "permit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "validAfter",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "validBefore",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "receiveWithAuthorization",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "minter",
                "type": "address"
            }
        ],
        "name": "removeMinter",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract IERC20",
                "name": "tokenContract",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "rescueERC20",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "rescuer",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "validAfter",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "validBefore",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "transferWithAuthorization",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_account",
                "type": "address"
            }
        ],
        "name": "unBlacklist",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "unpause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_newBlacklister",
                "type": "address"
            }
        ],
        "name": "updateBlacklister",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_newMasterMinter",
                "type": "address"
            }
        ],
        "name": "updateMasterMinter",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_newPauser",
                "type": "address"
            }
        ],
        "name": "updatePauser",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newRescuer",
                "type": "address"
            }
        ],
        "name": "updateRescuer",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "version",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]
const contract_abi_usdt = [
    {
        "constant": true,
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "name": "",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_upgradedAddress",
                "type": "address"
            }
        ],
        "name": "deprecate",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "deprecated",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_evilUser",
                "type": "address"
            }
        ],
        "name": "addBlackList",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_from",
                "type": "address"
            },
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "upgradedAddress",
        "outputs": [
            {
                "name": "",
                "type": "address"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "",
                "type": "address"
            }
        ],
        "name": "balances",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "maximumFee",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "_totalSupply",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [],
        "name": "unpause",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_maker",
                "type": "address"
            }
        ],
        "name": "getBlackListStatus",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "",
                "type": "address"
            },
            {
                "name": "",
                "type": "address"
            }
        ],
        "name": "allowed",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "who",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [],
        "name": "pause",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "getOwner",
        "outputs": [
            {
                "name": "",
                "type": "address"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "name": "",
                "type": "address"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "name": "",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "newBasisPoints",
                "type": "uint256"
            },
            {
                "name": "newMaxFee",
                "type": "uint256"
            }
        ],
        "name": "setParams",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "issue",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "redeem",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            },
            {
                "name": "_spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "name": "remaining",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "basisPointsRate",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "",
                "type": "address"
            }
        ],
        "name": "isBlackListed",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_clearedUser",
                "type": "address"
            }
        ],
        "name": "removeBlackList",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "MAX_UINT",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_blackListedUser",
                "type": "address"
            }
        ],
        "name": "destroyBlackFunds",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "name": "_initialSupply",
                "type": "uint256"
            },
            {
                "name": "_name",
                "type": "string"
            },
            {
                "name": "_symbol",
                "type": "string"
            },
            {
                "name": "_decimals",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Issue",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Redeem",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "name": "newAddress",
                "type": "address"
            }
        ],
        "name": "Deprecate",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "name": "feeBasisPoints",
                "type": "uint256"
            },
            {
                "indexed": false,
                "name": "maxFee",
                "type": "uint256"
            }
        ],
        "name": "Params",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "name": "_blackListedUser",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "_balance",
                "type": "uint256"
            }
        ],
        "name": "DestroyedBlackFunds",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "name": "_user",
                "type": "address"
            }
        ],
        "name": "AddedBlackList",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "name": "_user",
                "type": "address"
            }
        ],
        "name": "RemovedBlackList",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [],
        "name": "Pause",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [],
        "name": "Unpause",
        "type": "event"
    }
]

export const contract_address_usdc = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
export const contract_address_usdt = "0xdAC17F958D2ee523a2206206994597C13D831ec7";

export const contract_usdc = new web3.eth.Contract(contract_abi_usdc, contract_address_usdc);
export const contract_usdt = new web3.eth.Contract(contract_abi_usdt, contract_address_usdt);

export const logMeOut = async() =>{
    return new Promise((resolve, reject)=>{
  
        mainAxios.get(`${baseURL}logout`).then(function (response) {
            if(response.data.success){
                resolve(response)
            }else{
                resolve(false)
            }            
        }).catch(function (error) {
            resolve(false)
            console.error(error);
        });
    })
  }

export const fetchDummyStage = async() =>{
    return new Promise((resolve, reject)=>{
        mainAxios.get(`${baseURL}dummy-stage-data`).then(function (response) {
            if(response.data.success){
                resolve(response.data.data)
            }else{
                resolve(false)
            }
            
        }).catch(function (error) {
            resolve(false)
            console.error(error);
        });
    })
}

export const signup = (userInfo) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            mode: 'cors',
            url: baseURL + 'register',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: userInfo
        };

        mainAxios(config).then(function (response) {
            resolve(response.data)
        })
        .catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })


}

export const landingInfo = () => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            mode: 'cors',
            url: baseURL + 'get-landing-page',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };

        mainAxios(config).then(function (response) {
            resolve(response)
        })
        .catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })


}

export const googleSignup = (userInfo) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'login-with-google',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: userInfo
        };

        axios(config).then(function (response) {
            resolve(response.data)
        })
        .catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const emailOTPSend = (userInfo) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'register-mail-send',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: userInfo
        };

        axios(config).then(function (response) {
            resolve(response.data)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })

}

// export const emailOTPVerify = (userInfo) => {
//     return new Promise((resolve, reject) => {
//         const config = {
//             method: 'POST',
//             url: baseURL + 'verify-otp',
//             headers: {
//                 'Content-Type': 'application/x-www-form-urlencoded',
//             },
//             data: userInfo
//         };

//         axios(config).then(function (response) {
//             resolve(response.data)          
//         }).catch(function (error) {
//             console.log(error);
//         });
//     })

// }

export const userLogin = (userInfo) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'login',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: userInfo
        };

        mainAxios(config).then(function (response) {
            resolve(response.data)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const forgetPasswordGetOTP = (userInfo) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'forget-password-mail-send',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: userInfo
        };
        mainAxios(config).then(function (response) {
            resolve(response.data)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const resetPassword = (data) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'forget-password',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: data,
        };
        mainAxios(config).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const userForgetPassword= (userInfo) =>{
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'forget-password',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
            data: userInfo
        };

        mainAxios(config).then(function (response) {
            resolve(response.data)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const enableTwoFA= () =>{
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'enable-twofactor',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
        };

        mainAxios(config).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const verify2FA= (secret,token) =>{
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'save-and-verify-2FA',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
            data: qs.stringify({
                "secret": secret,
                "token": token
            })
        };
        console.log()
        mainAxios(config).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const login2FAApi = (email,token) =>{
    return new Promise((resolve, reject) => {

        const config = {
            method: 'POST',
            url: baseURL + 'verify-2FA',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: qs.stringify({
                "email": email,
                "token": token
            })
        };
        mainAxios(config).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const getUserDetails= () =>{
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: baseURL + 'get-dashboard-data',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
        
        };

        mainAxios(config).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const disabledTwoFA= () =>{
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: baseURL + 'disable-2FA',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
        };

        mainAxios(config).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const updateProfile = (name,dob,phone,nation,countryCode) =>{
    return new Promise((resolve, reject) => {

        const config = {
            method: 'POST',
            url: baseURL + 'update-profile',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
            data: qs.stringify({
                "username": name,
                "dob": dob,
                "mobile": phone,
                "nationality": nation,
                "countryCode":countryCode
            })
        };
        mainAxios(config).then(function (response) {
            
            // console.log("response",response)
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const updatePassword = (oldpass,newPass,cNewPass) =>{
    return new Promise((resolve, reject) => {

        const config = {
            method: 'POST',
            url: baseURL + 'reset-password',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: qs.stringify({
                "oldpassword": oldpass,
                "password": newPass,
                "re_password": cNewPass,
            })
        };
        mainAxios(config).then(function (response) {
            // console.log("response",response)
            
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const waitListAdd = ( email ) =>{
    // console.log("email",email)
    return new Promise((resolve, reject) => {

        const config = {
            method: 'post',
            url: baseURL + 'add-waitlist',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
            data: qs.stringify({
                "email": email
            })
        };

        mainAxios(config).then(function (response) {
            // console.log("response",response)
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

// // get currency list
export const getCurrencyList = () =>{
    return new Promise((resolve, reject) => {
        var config = {
            method: 'get',
            url: paymentApi + 'full-currencies',
            headers: { 
              'Content-Type': 'application/json',
              'x-api-key': tokenApiKey
            }
          };

          subAxios(config).then(function (response) {
            resolve(response)
        })
    })
}

// From selected currency to Usd
export const chnageCurrencyAmt = (amt,from,to) =>{
    return new Promise((resolve, reject) => {
        var config = {
            method: 'get',
            url: paymentApi + 'estimate?amount='+ amt +'&currency_from=' + from + '&currency_to=' + to +'',
            headers: { 
              'Content-Type': 'application/json',
              'x-api-key': tokenApiKey
            }
          };

          subAxios(config).then(function (response) {
            console.log("est",typeof(response.data.estimated_amount))
            if((typeof(response.data.estimated_amount) === "string" && !isNaN(response.data.estimated_amount)) || typeof(response.data.estimated_amount) === "number"){
                console.log("true")
                resolve(response)
            }else{
                console.log("false")
                resolve(false)
            }
            
        })
    })
}

// From Usd To selected currency
export const changeCurrToSelected = (amt,from,to) =>{
    return new Promise((resolve, reject) => {
        var config = {
            method: 'get',
            url: paymentApi + 'estimate?amount='+ amt +'&currency_from=' + from + '&currency_to=' + to +'',
            headers: { 
              'Content-Type': 'application/json',
              'x-api-key': tokenApiKey
            }
          };

          subAxios(config).then(function (response) {
            if((typeof(response.data.estimated_amount) === "string" && !isNaN(response.data.estimated_amount)) || typeof(response.data.estimated_amount) === "number"){
                console.log("true")
                resolve(response)
            }else{
                console.log("false")
                resolve(false)
            }
        })
    })
}

//make check minimum amount
export const checkMinimumAmt = (from, to) =>{
    return new Promise((resolve, reject) => {
        var config = {
            method: 'get',
            url: paymentApi + 'min-amount?currency_from=' + from + '&currency_to=' + to + '',
            headers: { 
              'Content-Type': 'application/json',
              'x-api-key': tokenApiKey
            }
        };
        subAxios(config).then(function (response) {
            resolve(response)
        })
    })
}


//make check updateMerchant amount
export const updateMerchant = (paymentId) =>{
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: paymentApi + 'payment/' + paymentId + '/update-merchant-estimate',
            headers: { 
              'Content-Type': 'application/json',
              'x-api-key': tokenApiKey
            }
        };
        subAxios(config).then(function (response) {
            resolve(response)
        })
    })
}

export const savePayment = async(paymentID, orderID) =>{
    return new Promise((resolve, reject)=>{
        const config = {
            method: 'POST',
            url: baseURL+"save-payment",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
            data: qs.stringify({
                "payment_id":paymentID,
                "order_id":orderID
            })
        };
        mainAxios(config).then(function (response) {
            // console.log("ressssss",response)
            resolve(response)
        }).catch(function (error) {
            console.log(error);
            resolve(false)
        });
    })
}

//pay invoice payement api 
export const payInvoicePayment = async(price_amount,price_currency) =>{
    let order_id = "BUY" + new Date().getTime();
    let payInfo = await savePaymentInfo( order_id );
    console.log("payInfo",payInfo)
    if(payInfo.data.success){
        return new Promise((resolve, reject) => {
            const config = {
                method: 'POST',
                url: paymentApi + 'payment',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': tokenApiKey, 
                },
                data: {
                    'price_amount': price_amount,
                    'price_currency': "usdc",
                    "pay_currency": price_currency,
                    'order_id': order_id,
                    "order_description": "Golteum Tokens"
                }
            };

            subAxios(config).then(async function (response) {
                console.log("savw info ", response)
                console.log("check type",typeof(response.data.order_id), typeof(response.data.pay_address), typeof(response.data.pay_currency), typeof(response.data.network), typeof(response.data.pay_amount), typeof(response.data.expiration_estimate_date), typeof(response.data.payment_id))
                if((typeof(response.data.order_id) === "string" || typeof(response.data.order_id) === "number") &&
                   (typeof(response.data.pay_address) === "string" || typeof(response.data.pay_address) === "number") &&
                   (typeof(response.data.pay_currency) === "string" || typeof(response.data.pay_currency) === "number") && 
                   (typeof(response.data.network) === "string" || typeof(response.data.network) === "number") &&
                   (typeof(response.data.pay_amount) === "string" || typeof(response.data.pay_amount) === "number") &&
                   (typeof(response.data.expiration_estimate_date) === "string" || typeof(response.data.expiration_estimate_date) === "number") &&
                   (typeof(response.data.payment_id) === "string" || typeof(response.data.payment_id) === "number")){
                    console.log("true")
                    const isSaved = await savePayment(response.data.payment_id, response.data.order_id )
                    if(isSaved.data.success){
                        resolve(response)
                    }
                }else{
                    console.log("false")
                    resolve(false)
                }


            }).catch(function (error) {
            console.log(error);
            resolve({
                data:{
                    payment_id:false,
                    msg:error.response.data.message
                }
            })
        });
        })
    }else if(payInfo.status === 204){
        payInvoicePayment(price_amount,price_currency)
    }else{
        return payInfo;
    }
    
}

//save payement api data in database
export const savePaymentInfo = (orderId) =>{
    // console.log("savePaymentInfo para ",orderId)
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'save-invoice',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
            data: qs.stringify({
                'order_id': orderId
            })
        };
        mainAxios(config).then(function (response) {
            resolve(response);    
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}


//verification by asish
export const verification = (token, key) => {
    return new Promise((resolve, reject) => {
        let userInfo = qs.stringify({
            'token': token,
            'key': key
        });
        const config = {
            method: 'POST',
            url: baseURL + 'verify-link',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data:userInfo
        };
        mainAxios(config).then(function (response) {
            if(response.data.success){
                resolve(true)
            }else{
                resolve(false)
            }
        })
        .catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}


// add wallet address
export const addWalletAddress = ( address ) =>{
    return new Promise((resolve, reject) => {

        const config = {
            method: 'POST',
            url: baseURL + 'add-wallet',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
            data: qs.stringify({
                "wallet": address
            })
        };
        console.log()
        mainAxios(config).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

// change wallet address
export const changeWalletAddress = ( address, txid, otpCode ) =>{
    return new Promise((resolve, reject) => {

        const config = {
            method: 'POST',
            url: baseURL + 'send-wallet-change-request',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
            data: qs.stringify({
                "wallet": address,
                "txid":txid,
                "otp":otpCode
            })
        };
        mainAxios(config).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}


export const sendRequestAdd = () => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: baseURL + 'wallet-change-mail',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
        };

        mainAxios(config).then(function (response) {
            
            resolve(response)
        })
        .catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}


export const reSendEmail = () => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: baseURL + 'resend-email',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
        };

        mainAxios(config).then(function (response) {
            resolve(response)
        })
        .catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

//get tranasction buy token
export const buyTokenTrans= () =>{
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: baseURL + 'get-user-transactions',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
        
        };

        mainAxios(config).then(function (response) {
            resolve(response.data.data)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}


//get refereral list
export const getReferralList= () =>{
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: baseURL + 'get-referral-details',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
        };

        mainAxios(config).then(function (response) {
            resolve(response.data)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

// add wallet address
export const activityLogs = ( value ) =>{
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'update-activity-log-status',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
            data: qs.stringify({
                "activity": value
            })
        };
        mainAxios(config).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}


export const joinWaitList = (data) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'joinWaitlist',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: data,
        };
        mainAxios(config).then(function (response) {
            resolve(response)
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

export const activityLog = async(token,systemId) =>{
    return new Promise((resolve, reject)=>{
        mainAxios.get(`${baseURL}get-user-activity`).then((response) => {
          if(response.data.success){
              resolve(response.data.data)
          }else{
              resolve(false)
          }
        }).catch(function (error) {
            resolve(false)
            console.log(error);
        });
    })
}

// ashsish
export const forgetPasswordVerification = (userInfo) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'forget-password-verify-link',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',  
            },
            data: userInfo
        };
        mainAxios(config).then(function (response) {
            resolve(response.data)
        })
        .catch(function (error) {
            console.log(error);
            resolve(false)
        });
    })
}

//constact support
export const contactSupport = (userInfo) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'POST',
            url: baseURL + 'contact-support',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
            data: qs.stringify({
                'email': userInfo.email,
                'name': userInfo.name,
                'message': userInfo.content
            })
        };
        mainAxios(config).then(function (response) {
            resolve(response.data)
        }).catch(function (error) {
            console.log(error);
            resolve(false)
        });
    })
}

//get refereral list
export const walletAddStatus= () =>{
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: baseURL + 'wallet-change-status',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
            },
        };

        mainAxios(config).then(function (response) {
            resolve(response.data)
        }).catch(function (error) {
            resolve(false)
        });
    })
}

//check presale
export const presaleCheck= () =>{
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: baseURL + 'check-presale-status',
        };
        mainAxios(config).then(function (response) {
            resolve(response.data)
        }).catch(function (error) {
            resolve(false)
        });
    })
}

export const verifyToken = () =>{
  return new Promise(async(resolve, reject)=>{
   const response = await mainAxios.get(`${baseURL}verify-token`)
   if(response.status === 200){
    resolve(true)
  }else{
    resolve(false)
  }
  })
}

export const generateOnfidoUrl = (tokenId) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: baseURL + 'generate-onfido-url',
            'token': tokenId,
        };
        mainAxios(config).then(function (response) {
            resolve(response.data)
        }).catch(function (error) {
            resolve(false)
        });
    })
}

