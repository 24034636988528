import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { sitekeyCaptcha } from 'components/helper/userHelper';

function Captcha({handleCaptchaChange}) {

    const googleCaptcha = (value) => {
        handleCaptchaChange(value)
    }

    return (
        <ReCAPTCHA
            sitekey={sitekeyCaptcha}
            className='recptchCss'
            onChange={googleCaptcha}
        />
    )
}

export default Captcha
