
import { Fragment, useRef, useState } from 'react'
import { Watch } from 'react-loader-spinner'
import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Countdown from 'react-countdown'
import { Grid } from 'react-loader-spinner'
import {Accordion, Card, Button} from 'react-bootstrap';


import { ThreeDots } from 'react-loader-spinner'
export default function Success() {
    const [open, setOpen] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
    const customAnimation = {
        mount: { scale: 1 },
        unmount: { scale: 0.9 },
    };
    const cancelButtonRef = useRef(null)
    return (
        <>
            <div className="bg-black md:min-h-screen w-full mx-auto justify-center items-center">
                <div className='fixed mx-auto text-[#dbdbdb] pt-80 max-w-9xl justify-center items-center textcenter w-full'>
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        type="button"
                        className="items-center justify-center mx-auto"
                        aria-controls="mobile-menu"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        <button
                            className="bg-gradient-to-b from-[#9f7c50] to-[#bf9a6f] via-[#9e794e] text-black font-bold
py-2 px-12 text-xs font-sans"
                        >
                            Buy Now
                        </button>
                    </button>
                </div>
                <div className='fixed mx-auto text-black max-w-9xl justify-center items-center text-center w-full'>
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        type="button"
                        className="items-center justify-center mx-auto"
                        aria-controls="mobile-menu"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className="block h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <Transition
                    show={isOpen}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    {(ref) => (
                        <div className="z-10 max-w-xl mx-auto items-center justify-between md:pt-12 pb-12 pt-5
duration-300">
                            <div className='ring-2 ring-[#9f7c50] shadow-inner backdrop-blur-3xl md:pb-8 pb-3 bg-
[#181b22] mx-3'>
                                <div ref={ref} className="md:px-10 pt-5 flex flex-col rounded-md px-3">
                                    <div className='flex justify-between flex-row items-center pb-4'>
                                        <p className='text-white tracking-wide font-bold'>Payment Details</p>
                                        <button
                                            onClick={() => setIsOpen(!isOpen)}
                                            type="button"
                                            className="items-center justify-center"
                                            aria-controls="mobile-menu"
                                            aria-expanded="false"
                                        >
                                            <span className="sr-only">Open main menu</span>
                                            <svg
                                                className="block h-6 w-6 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <p
                                        className='tracking-wider py-2 flex flex-row items-center justify-between text-xs duration200 text-[#dbdbdb]'
                                    >
                                        Transaction Hash
                                        <span className='cursor-pointer'>1aa87-2968ph7659-cssf45</span>
                                    </p>
                                    <p
                                        className='tracking-wider py-2 flex flex-row items-center justify-between text-xs duration200 text-[#dbdbdb]'
                                    >
                                        Golteum
                                        <span>$0.05 USDT</span>
                                    </p>
                                    <p
                                        className='tracking-wider py-2 flex flex-row items-center justify-between text-xs duration200 text-[#dbdbdb]'
                                    >
                                        Ethereum
                                        <div className='flex items-center justify-center gap-2'>
                                            <svg className='h-5 w-5 items-center justify-center bg-[#868686] rounded-full text-w'>
                                            </svg>
                                            <span className='flex text-[#bf9a6f] font-bold uppercase items-center justify-center'>
                                                0.15658642 ETH
                                            </span>
                                        </div>
                                    </p>
                                    <p
                                        className='tracking-wider py-2 flex flex-row items-center justify-between text-xs duration200 text-[#dbdbdb]'
                                    >
                                        Network
                                        <span className='font-bold text-white'>ERC-20</span>
                                    </p>
                                    <p
                                        className='tracking-wider py-2 flex flex-row items-center justify-between text-xs duration200 text-[#dbdbdb]'
                                    >
                                        Canceled in
                                        <div className='flex items-center justify-center gap-1'>
                                            <Watch
                                                height="22"
                                                width="22"
                                                radius="40"
                                                color="#9f7c50"
                                                ariaLabel="watch-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                            <Countdown className='bg-[#3a3a3a] px-1 py-1 font-bold' date={Date.now() + 1800000}
                                            />
                                        </div>
                                    </p>
                                </div>
                                <div className='items-center max-w-md justify-start mx-auto md:mt-8 mt-4'>
                                    <div className='border border-[#323232] bg-red-200 bg-opacity-20 mx-5'>
                                        <p
                                            className='text-[#ffffff] font-mono mx-auto text-xs items-center justify-center px-3
md:max-w-md md:tracking-widest py-2'
                                        >
                                            Sending the funds through a different chain other than Ethereum Chain (ERC20) will result
                                            in your funds being permanently lost.
                                        </p>
                                    </div>
                                </div>
                                <div className='border-b border-[#323232] md:py-5 py-2 max-w-xl mx-auto'></div>
                                <div ref={ref} className="px-10 md:pt-5 pt-2 flex flex-col">
                                    <p className='tracking-wider py-2 flex flex-row items-center justify-between text-xs
duration-200 text-[#dbdbdb]'>Send
                                        <span className='font-bold text-white text-sm bg-[#323232] py-1 px-4 cursorpointer'>0.15658642</span>
                                        <span>ETH to</span>
                                    </p>
                                    <div className='items-center md:w-80 justify-start mx-auto my-2 bg-[#323232] hover:bgopacity-80 duration-300'>
                                        <p
                                            className='text-[#ffffff] mx-auto text-xs items-center justify-center text-center px-3
tracking-widest py-3 cursor-pointer'
                                        >
                                            0x7efrt578ghf7895jktrtrrt5788
                                        </p>
                                    </div>
                                    <Fragment>
                                        <Accordion open={open === 1} animate={customAnimation}>
                                            <Accordion.Header className='text-[#181b22] border-[#181b22]' onClick={() =>
                                                handleOpen(1)}>
                                                <div className='flex gap-2 pt-3'>
                                                    <div className='bg-white'>
                                                        <Grid
                                                            height="16"
                                                            width="16"
                                                            color="#000"
                                                            ariaLabel="grid-loading"
                                                            radius="12.5"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={true}
                                                        />
                                                    </div>
                                                    <p className='text-white text-xs'>QR Code</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="flex mt-4 bg-[#bf9a6f] w-44 text-center mx-auto h-44">
                                                <h1 className='text-black items-center justify-center text-center mx-auto my-auto fontbold uppercase text-sm'>QR Code Scan</h1>
                                            </Accordion.Body>
                                        </Accordion>
                                    </Fragment>
                                    <p className='tracking-wider mt-5 py-2 flex flex-row items-center justify-between text-xs
duration-200 text-[#dbdbdb]'>
                                        Status
                                        <div className='flex gap-2 items-center justify-center'>
                                            <ThreeDots
                                                height="22"
                                                width="22"
                                                radius="9"
                                                color="#bf9a6f"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                            <span>Awaiting for payment</span>
                                        </div>
                                    </p>
                                    <p
                                        className='tracking-wider py-2 flex flex-row items-center justify-between text-xs duration200 text-[#dbdbdb]'
                                    >
                                        Coin
                                        <span>Golteum</span>
                                    </p>
                                    <p
                                        className='tracking-wider py-2 flex flex-row items-center justify-between text-xs duration200 text-[#dbdbdb]'
                                    >
                                        Quantity
                                        <span>1,000 GLTM</span>
                                    </p>
                                    <p
                                        className='tracking-wider py-2 flex flex-row items-center justify-between text-xs pb-5
duration-200 text-[#dbdbdb]'
                                    >
                                        Received
                                        <span>0.00000000 ETH</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                    )}
                </Transition>
            </div>
        </>
    );
}