/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import { useState } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import qs from "qs";
import '../Css/Custom.css'
import { forgetPasswordGetOTP, privacyPolicy } from 'components/helper/userHelper';
import Swal from 'sweetalert2';
import Captcha from 'components/Common/Captcha';

export default function Index() {
    const [email, setEmail] = useState("");
    const [isValidEmail, setValidEmail] = useState(false);
    const [emailErr, setEmailErr] = useState("");
    const [loaderAct, setloaderAct] = useState(false);
    const [captchaErr, setCaptchaErr] = useState();
    const [captchaVal, setCaptchaVal] = useState(null)

    const handleCaptchaChange = (value) => {
        setCaptchaVal(value)
    }

    const handleOnChangeEmail = (event) => {
        // const domainValue = ['live', 'yahoo', 'google', 'codebird', 'gmail'];
        // const emailLastValue = ['com', 'net', 'org', 'io', 'in'];
        setEmail(event.target.value);
        let emailIDVal =
            /^(?!.{255})(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailIDTrue = emailIDVal.test(event.target.value);
        if (emailIDTrue) {
            // let emailValue = event.target.value;
            // let splitValue = emailValue.split("@")[1].split(".");
            // let userDomain = splitValue[0];
            // let lastValue = splitValue[1];

            // const domainCheck = domainValue.indexOf(userDomain);
            // if (domainCheck !== -1) {
            //     const lastCheck = emailLastValue.indexOf(lastValue);
            //     if (lastCheck !== -1) {
                    setValidEmail(true);
                    setEmailErr("");
            //     } else {
            //         setValidEmail(false);
            //         setEmailErr(" Domain must be .com, .net, .in, .io");
            //     }
            // } else {
            //     setValidEmail(false);
            //     setEmailErr(" Domain must be @gmail, @yahoo, @live");
            // }
        } else {
            if (event.target.value === "") {
                setValidEmail(false);
                setEmailErr(" This field is required");
            } else {
                setValidEmail(false);
                setEmailErr(" Invalid Email");
            }
        }
    };


    const forgetPassword = async () => {
        if (email !== "" && email !== undefined) {
            if (captchaVal !== '' && captchaVal !== undefined && captchaVal !== null) {
                if (isValidEmail) {
                    setloaderAct(true)
                    let data = qs.stringify({
                        email: email,
                        captcha: captchaVal
                    });
                    let response = await forgetPasswordGetOTP(data);
                    if(!response){
                        setloaderAct(false);
                        setCaptchaVal(null)
                        window.grecaptcha.reset();
                        Swal.fire({
                            text: "Error while processing your request please try again later",
                            button: false,
                            background: "black",
                            icon: "error",
                            timer: 2000,
                        });
                    }else{
                        if (response.success === true) {
                            setloaderAct(false)
                            Swal.fire({ showConfirmButton:false,
                                text: response.msg,
                                button: false,
                                background: 'black',
                                icon: "success",
                                confirmButtonText: 'Close',
                            });
                            setEmail("");
                            setCaptchaVal(null)
                            setCaptchaErr("")
                            window.grecaptcha.reset();
                        } else {
                            setloaderAct(false)
                            Swal.fire({ showConfirmButton:false,
                                text: response.msg,
                                button: false,
                                background: 'black',
                                icon: "error",
                                confirmButtonText: 'Close',
                            });
                            setCaptchaVal(null)
                            window.grecaptcha.reset();
                        }
                    }
                } else {
                    setEmailErr(" Email not Verified");
                }
            } else {
                setCaptchaErr(" This field is required")
            }
        } else {
            setEmailErr(" This field is required");
        }
    }
    const submitOnEnter = (event) => {
        if (event.key === "Enter") {
            forgetPassword();
        }
    }

    return (
        <>
            <Helmet>
                <style>{"body { background-color: #000;html{background-color: #000; }"}</style>
                <title>Forgot Password | Golteum</title>
            </Helmet>

            <div className="cssForAll">
                <div className="login-wrap ">
                    <div className="start-box">
                        <div id="stars"></div>
                        <div id="stars2"></div>
                        <div id="stars3"></div>
                    </div>
                    <div className="login-box ">
                        <div className="login-logo">
                            <Link to="/">
                                <img className="logoSize" src="images/logo.png" alt="" />
                            </Link>
                        </div>
                        <div className="login-form">
                            <div className='mt-4'>
                                <h2>Reset Password</h2>
                                <p>if you forget your password, please enter your registered email below to receive your instructions.</p>
                            </div>
                            <input className="mt-4 email-in" type="text" placeholder="Your Email Address" name="" value={email} onChange={handleOnChangeEmail} onKeyUp={submitOnEnter} />
                            <div>
                                <span style={{ color: "red" }} >{emailErr}</span>
                            </div>
                            <div className='captcha'>
                                <Captcha  handleCaptchaChange={handleCaptchaChange}/>
                            </div>
                            <div className='text-center'>
                                <span style={{ color: "red" }} >{captchaErr}</span>
                            </div>
                            {loaderAct ? <button className="btn btn-yellow w-100 mt-4" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> : <button className="btn btn-yellow w-100 mt-4" onClick={forgetPassword}>Send Reset Link</button>}
                            <div className="or-box">
                                <span>OR</span>
                            </div>
                            <div className="account-new">
                                Already have an account? <Link to="/">Login now</Link>
                            </div>
                        </div>
                    </div>
                    <div className="footer-item">
                        <h4>JOIN OUR COMMUNITY</h4>
                        <div className="social-login-ic">
                            <a href="https://twitter.com/golteum?s=20&t=Kirb38vwigNjUq0GgWCyUA" target="_blank" rel="noreferrer">
                                <i className='bi bi-twitter'></i>
                            </a>
                            <a href="https://t.me/golteum" target="_blank" rel="noreferrer">
                                <i className='bi bi-telegram'></i>
                            </a>
                            <a href="https://instagram.com/golteum?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
                                <i className='bi bi-instagram'></i>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100082846137280" target="_blank" rel="noreferrer">
                                <i className='bi bi-facebook'></i>
                            </a>
                        </div>
                        <div className="link-f">
                        <a href={privacyPolicy} target="_blank" rel="noreferrer">PRIVACY POLICY</a>
                            {/* <Link to="/privacy-policy">PRIVACY POLICY</Link> */}
                            {/* <Link to="/term">TERMS OF USE</Link> */}
                        </div>
                        <div className="copy-r">
                            © 2024 Golteum.io - All rights reserved.
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
