import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import RequireAuth from './RequireAuth';
// import { verifyToken } from "../components/helper/userHelper"
import Login from './Login'
import Register from './Register'
import Dashboard from './Dashboard'
import Privacy from './Privacy'
import Term from './Term'
import Forgot from './ForgotPass'
import BuyToken from './BuyToken'
import Transactions from './Trasanctions'
import Profile from './Profile'
import Support from './Support'
import CommonWrapper from 'components/User/Dashboard/Common/CommonWrapper';
import Success from './Success'
import Cancel from './Cancel'
import Verification from './Verfication';
import ActivityLog from './ActivityLogs'
import Resetpassword from './ResetPassword';
import Page404 from './Page404';



// function RequireAuth({ children, redirectTo }) {

//   const navigate = useNavigate();
//   const [isVerified, setIsVErified] = useState(true);

//   useEffect(()=>{
//     console.log("checking details")
//     let isAPISubscribed = true;
//     async function checkVerification(){
//         return await verifyToken();
//     }
//     if(isAPISubscribed){
//       checkVerification().then((value)=>{
//         console.log(value, "cheking authentication")
//         setIsVErified(value);
//       });
//   }
//     return ()=>{
//         isAPISubscribed = false;
//         console.log("checking details in cleanup")
//     }
// },[]);

// console.log("isVerified",isVerified, redirectTo)
//    if(!isVerified){

//       return navigate(redirectTo);
//   }
//   return children
// }


const Router = () => {

  return (
    <div>
      {/* <Suspense fallback={<div style={{height:"100vh",width:"100%",textAlign:"center",color:"#fff", background:"#000"}}></div>}> */}
      <Routes>
        <Route
          path="/"
          element={
            <Login />
          }
        />
        <Route
          path="/register"
          element={
            <Register />
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Privacy />
          }
        />
        <Route
          path="/term"
          element={
            <Term />
          }
        />
        <Route
          path="/forgot-password"
          element={
            <Forgot />
          }
        />
        <Route
          path="/verification"
          element={
            <Verification />
          }
        />
        <Route
          path="/resetPassword"
          element={
            <Resetpassword />
          }
        />
        <Route
          path="/dashboard"
          element={
            <RequireAuth redirectTo={"/"}>
              <CommonWrapper>
                <Dashboard />
              </CommonWrapper>
            </RequireAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequireAuth redirectTo={"/"}>
              <CommonWrapper>
                <Profile />
              </CommonWrapper>
            </RequireAuth>
          }
        />
        <Route
          path="/buy-token"
          element={
            <RequireAuth redirectTo={"/"}>
            <CommonWrapper>
              <BuyToken />
            </CommonWrapper>
            </RequireAuth>
          }
        />
        <Route
          path="/transactions"
          element={
            <RequireAuth redirectTo={"/"}>
              <CommonWrapper>
                <Transactions />
              </CommonWrapper>
            </RequireAuth>
          }
        />
        <Route
          path="/support"
          element={
            <RequireAuth redirectTo={"/"}>
              <CommonWrapper>
                <Support />
              </CommonWrapper>
            </RequireAuth>
          }
        />

        <Route
          path="/payment-success"
          element={
            <RequireAuth redirectTo={"/"}>
              <Success />
            </RequireAuth>
          }
        />
        <Route
          path="/payment-cancel"
          element={
            <RequireAuth redirectTo={"/"}>
              <Cancel />
            </RequireAuth>
          }
        />
        <Route
          path="/activity-log"
          element={
            <RequireAuth redirectTo={"/"}>
              <CommonWrapper>
                <ActivityLog />
              </CommonWrapper>
            </RequireAuth>
          }
        />

        <Route
          path="/404"
          element={
            <Page404 />
          }
        />
        <Route
          path="/asset-manifest.json"
          element={<Navigate to='/dashboard' replace />}
        />
        <Route
          path="/manifest.json"
          element={<Navigate to='/dashboard' replace />}
        />
        <Route
          path="/robots.txt"
          element={<Navigate to='/dashboard' replace />}
        />
        <Route
          path="*"
          element={<Navigate to='/404' replace />}
        />

      </Routes>
      {/* </Suspense> */}
    </div>
  );
};

export default Router;
