/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState,useEffect } from 'react'
import { Helmet } from "react-helmet";
import '../../Css/Custom.css'
import '../../Css/Responsive.css'
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import CardRight from '../Common/CardRight';
import MenuTab from './MenuTab';
import ProfileData from './ProfileData';
import Settings from './Settings';
import ReferralBonus from './ReferralBonus';
import Waitlist from './Waitlist';
import Password from './Password';
// import { getUserDetails } from 'components/helper/userHelper';
// import { useNavigate } from "react-router-dom";
import { useUser } from 'components/UserContext';
import IdentityVerification from "./IdentityVerification";

export default function Index() {
    const { users } = useUser();
    let params = new URL(document.location).searchParams;
    let id = params.get("id");
    // const [toggleState, setToggleState] = useState(parseInt(id) === 4 ? 4 : parseInt(id) === 3 ? 3 : 1);
    const toggleTab = (index) => {
        // setToggleState(index);
        setOnset(index)
    };
    // const navigate = useNavigate();
    const [ user , setUser ] = useState({
        fullname : "",
        email : "",
        dob : "",
        phone : "",
        nation : "",
        twoFA : "",
        wallet: "",
        id: "",
        recordActivity: "",
    })
    const [onset, setOnset] =useState()

    useEffect(() => {
        let isAPISubscribed = true;
        if(isAPISubscribed){
            setOnset(parseInt(id) === 5 ? 5 : parseInt(id) === 4 ? 4 : parseInt(id) === 3 ? 3 : parseInt(id) === 2 ? 2 : parseInt(id) === 6 ? 6 : 1)
        }
        return () => {
            isAPISubscribed = false;
        }
    }, [id]);

    useEffect(() => {
        let isAPISubscribed = true;
        if(isAPISubscribed){
            setUser({
                fullname: users.fullname,
                email: users.email,
                dob: users.dob,
                phone: users.phone,
                nation: users.nation,
                twoFA: users.twoFA,
                wallet: users.wallet,
                id: users.id,
                recordActivity: users.recordActivity,
            })
        }
        return () =>{
            isAPISubscribed = false
        }
        
    }, [users.fullname,users.email,users.dob,users.phone, users.nation, users.twoFA, users.wallet, users.id, users.recordActivity]);


    return (
        <>
            <Helmet>
                <title>Profile | Golteum</title>
            </Helmet>
            <div className="dash-container">
                <div className="page-tit">
                    <h4>Profile</h4>
                </div>

                <div className="dash-container-in">
                    <MenuTab toggleState={onset} toggleTab={toggleTab} />
                    <div className="row dash-home">
                        <ProfileData toggleState={onset} />
                        {/* <IdentityVerification toggleState={onset} /> */}
                        <Settings toggleState={onset} userInfo={user}/>
                        <ReferralBonus toggleState={onset} userInfo={user}/>
                        <Waitlist toggleState={onset} userInfo={user}/>
                        <Password toggleState={onset} userInfo={user}/>
                        <div className="col-md-4 d-block">
                            <CardRight  />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
