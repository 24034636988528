import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyToken } from "../components/helper/userHelper"

function RequireAuth({ children, redirectTo }) {
  const navigate = useNavigate();
  const [isVerified, setIsVErified] = useState("processing");
  const location = useLocation();

  useEffect(() => {
    let isAPISubscribed = true;
    async function checkVerification() {
      return await verifyToken();
    }
    if (isAPISubscribed) {
      checkVerification().then((value) => {
        if (value === true) {
          setIsVErified("true");
        } else if (value === false) {
          setIsVErified("false");
        }
      });
    }
    return () => {
      isAPISubscribed = false;
    }
  }, [location.pathname]);

  if (isVerified === "false") {
    return navigate(redirectTo);
  } else if (isVerified === "true") {
    return children
  } else {
    return (<div style={{ height: "100vh", width: "100%", textAlign: "center", color: "#fff", background: "#000" }}></div>)
  }
}

export default RequireAuth