/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import '../../Css/Custom.css'
import '../../Css/Responsive.css'
import Header from '../Common/Header';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import CardRight from '../Common/CardRight';
import { activityLog, getUserDetails } from 'components/helper/userHelper';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Loader from 'components/Common/Loader';
import { useUser } from 'components/UserContext';
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2';


export default function Index() {
    const { users } = useUser();
    const userTable = {
        columns: [
            {
                name: "Serial",
                selector: row => row.srno,
                sortable: true,
                width: '100px',
            },
            {
                name: "Browser",
                selector: row => row.browser,
                sortable: true
            },
            {
                name: "Path",
                selector: row => row.path,
                sortable: true
            },
            {
                name: "System",
                selector: row => row.system,
                sortable: true,
                width: '200px',
            },
            {
                name: "Date",
                selector: row => row.date,
                sortable: true,
                width: '200px',
            },

        ],
        data: []
    }

    const headers1 = [
        { label: "Serial", key: "srno" },
        { label: "Browser", key: "browser" },
        { label: "Path", key: "path" },
        { label: "System", key: "system" },
        { label: "Date", key: "date" },
    ]; 


    const [activityData, setActivityData] = useState(userTable)
    const [reload, setReload] = useState(true);
    const [showLoader, setShowLoader] = useState(false)
    const [showLogs, setShowLogs] = useState(false)
    
    useEffect(() => {

        let isApiSubscribed = true;
        const getData = async () => {
            if (isApiSubscribed && reload) {
                setShowLoader(true)
                if (users.recordActivity) {
                    setShowLogs(users.recordActivity)
                    const details = await activityLog();
                    let data = [];
                    if (details.length) {
                        details.map((item, i) => {
                            let obj = {}
                            obj = {
                                "srno": i + 1,
                                "browser": item.browser,
                                "path": item.path,
                                "system": item.system ? item.system : "--",
                                "date": new Date(item.date).toLocaleString(),
                            }
                            return data.push(obj);
                        })
                        setActivityData({ ...activityData, data: data });
                        setShowLoader(false)
                    }else{
                        setActivityData({ ...activityData, data: data });
                        setShowLoader(false)
                    }
                }
                else {
                    setShowLoader(false)
                    setShowLogs(users.recordActivity)
                    
                } 
            }
            else{
                setShowLoader(false)
            }
        }
        getData();


        return () => {
            isApiSubscribed = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users.recordActivity, reload]);

    return (
        <>
            <Helmet>
                <title>Activity Logs | Golteum</title>
            </Helmet>
            <Loader show={showLoader} />
            <div className="dash-container">
                <div className="page-tit">
                    <h4>Activity log</h4>
                </div>
                <div className="dash-container-in">
                    <div className="row dash-home">
                        <div className="col-md-12">
                            {
                                showLogs ?
                                    <div className="mt-4">
                                        <div className="card-cust table-cust-in">
                                            {activityData.data.length > 0 ? (
                                                <>
                                                    <CSVLink className="downloadBtn" headers={headers1} filename="Activity Logs.csv" data={activityData["data"]}>Download&nbsp;&nbsp;<i className="fa fa-download" aria-hidden="true"></i></CSVLink>
                                                    <DataTableExtensions {...activityData}>
                                                        <DataTable
                                                            columns={activityData["columns"]}
                                                            data={activityData["data"]}
                                                            defaultSortField="srno"
                                                            // sortIcon={<SortIcon />}
                                                            defaultSortAsc={true}
                                                            pagination
                                                            highlightOnHover
                                                            dense
                                                        />
                                                    </DataTableExtensions>
                                                </>
                                            ) : (
                                                <table className="table-cust table dt-responsive nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>Serial</th>
                                                            <th>Browser</th>
                                                            <th>Path</th>
                                                            <th>System & time</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="5">
                                                                <p style={{ textAlign: "center" }}>
                                                                    There are no records to display
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <p>Your Activity Log is disabled. To enable, please go to security setting in profile section</p>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}