import React from "react";
import { Routes, Route } from "react-router-dom";
import VerificationEmail from "../components/User/Register/Verification";
const Verification = () => {
  return (
      <Routes>
        <Route path="/" element={<VerificationEmail />} />
      </Routes>
  );
};

export default Verification;
