import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
export default function Cancel() {
    return (
        <>
            
        <Helmet>
                <style>{"body { background-color: #000; }"}</style>
        </Helmet>
        <div className='cssForAll'>
            <div className='login-wrap'>
            <div className="message_Box login-box">
                <div>
                    <div className="login-logo">
                        <Link to="/">
                            <img className="logoSize" src="images/logo.png" alt="" />
                        </Link>
                        <h4>Payment Error.</h4>
                        <Link className='btn btn-yellow mt-4' to="/buy-token">Go to Dashboard</Link>
                    </div>
                </div>
            </div>
            </div>
        </div>

        </>
    )
}
