/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import { Grid } from 'react-loader-spinner'
import { useLocation } from 'react-router-dom';

import { ThreeDots } from 'react-loader-spinner'
import { Accordion, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../Css/Custom.css'
import '../../Css/Responsive.css'
import Footer from '../Common/Footer';
import CardRight from '../Common/CardRight';
import { web3, contract_usdc, contract_usdt, ACTIVE_NETWORK_eth, ACTIVE_NETWORK_bsc, getCurrencyList, changeCurrToSelected, checkMinimumAmt, savePaymentInfo, payInvoicePayment, getUserDetails, updateMerchant, presaleCheck } from 'components/helper/userHelper';
import Swal from 'sweetalert2';
import Loader from 'components/Common/Loader';
import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import Select, { fuzzySearch } from 'react-select'
import QRCode from 'react-qr-code';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useMediaQuery } from 'react-responsive';
import Timer from './Timer';
import { useUser } from 'components/UserContext';
import { Helmet } from 'react-helmet';
var end;

export default function Index() {
    const location = useLocation();
    const Navigate = useNavigate()

    const { users } = useUser();
    const [account, setAccount] = useState(null);
    const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const navigate = useNavigate();
    const [loaderAct, setloaderAct] = useState(false);
    const [loaderAct1, setloaderAct1] = useState(false);
    const [loaderAct2, setloaderAct2] = useState(false);
    const [metamaskShow, setmetamaskShow] = useState(false);
    const [networkName, setNetworkName] = useState("")
    const [checkERR, setCheckERR] = useState("");
    const [selectERR, setSelectERR] = useState("");
    const [chainIdCrr, setChainIdCrr] = useState(1)
    const [oneCurrency, setoneCurrency] = useState('')
    
    var searchParams = new URLSearchParams(location.search);
    var currNamePath = searchParams.get('currency');
    var amountVal = searchParams.get('amount');

    const fromAmt = useRef(0)
    const toAmt = useRef(0)
    // const [currencyPath, setCurrencyPath] = useState('');
    // const [amountPath, setAmountPath] = useState('');
    const [fromCurrency, setfromCurrency] = useState()
    const [toCurrency, settoCurrency] = useState('usdc')
    const [totalGoltToken, settotalGoltToken] = useState()
    const [check, setCheck] = useState(false);

    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);

    const handleClose2 = () => setShow2(false);
    const [show2, setShow2] = useState(false);
    const [expire20, setExpire20] = useState(false);

    const handleClosepay = () => {
        Swal.fire({
            title: `Do you really want to close this transaction, this action can not be reverted?`,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                setShowpay(false);
            }
        })
    }

    const handleCloseAfterExpired = () => {
        // Swal.fire({
        //     title: `Do you really want to close this transaction, this action can not be reverted?`,
        //     showDenyButton: true,
        //     confirmButtonText: 'Yes',
        //     denyButtonText: `No`,
        // }).then(async (result) => {
        //     if (result.isConfirmed) {
                setShowpay(false);
                setExpire20(false)
        //     }
        // })
    }

    const [showpay, setShowpay] = useState(false);

    const [tokenPr, setTpkenPr] = useState(0)
    const [payAddress, setPayeaddress] = useState('')
    const [payExtraId, setPayExtraId ] = useState()
    const [payCurrencyAmt, setpayCurrAmt] = useState(0)
    const [payCurrSymbol, setpayCurrSymbol] = useState()
    const [paymentSent, setPaymentSent] = useState()
    const [copybtn, setcopybtn] = useState(false)
    const [copybtn1, setcopybtn1] = useState(false)
    const [user, setUser] = useState({
        isEmailVerified: "",
        wallet: "",
        tokenPrice: ""
    })
    const [OnLoader, setOnLoader] = useState(false)
    const [currListAll, setcurrListAll] = useState([]);
    const [getCurrentPriceSelected, setgetCurrentPriceSelected] = useState(0)
    const [selectCheck, setselectCheck] = useState(false)
    const [timer, setTimer] = useState(0)
    const [loadAfter, setloadAfter] = useState(0)
    const [fullNameCrr, setFullNameCrr] = useState('')
    const [fullNameCrr1, setFullNameCrr1] = useState('')
    const [fullNameCrr2, setFullNameCrr2] = useState('')
    const [paymentId, setPaymentId] = useState()
    const [showWaitList, setShowWaitList] = useState(true);
    const now = Math.floor(((new Date()).getTime()) / 1000);
    const [open, setOpen] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [minAmt, setMinAmt] = useState(true)

    

    useEffect(() => {
        console.log("amt avav",amountVal)
        if(amountVal){
            getCurrToSelected()
        }
        // const searchParams = new URLSearchParams(location.search);
        // setCurrencyPath(searchParams.get('currency') || '');
        // setAmountPath(searchParams.get('amount') || '');
        // toAmt = searchParams.get('amount')  ;
      }, [amountVal,loadAfter, users.stage_price, tokenPr]);

    useEffect(() => {
        let isAPISubscribed = true;
        if (isAPISubscribed) {
            checkPresale();
            fatchCurrencyAll();
            setUser({
                isEmailVerified: users.userStatus,
                wallet: users.wallet,
                tokenPrice: users.stage_price,
            })
            setTpkenPr(users.stage_price)
        }
        return () => {
            isAPISubscribed = false
        }
    }, [users.stage_price, users.wallet, users.userStatus,loadAfter]);

    const checkPresale = async () => {
        let res = await presaleCheck()
        // console.log("check", res)
        setShowWaitList(res.data.presale)
        // console.log("preale chck", res.data.presale)
    }

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const customAnimation = {
        mount: { scale: 1 },
        unmount: { scale: 0.9 },
    };
    const fatchCurrencyAll = async () => {
        const res = await getCurrencyList();
        console.log("fatchCurrencyAll", res)
        let data = [];
        for (let i = 0; i < res.data.currencies.length; i++) {
            data[i] = { "value": res.data.currencies[i].code + ' ' + res.data.currencies[i].name, "label": <div className='optionlist'><img src={"https://nowpayments.io/" + res.data.currencies[i].logo_url} height="28px" width="28px" alt="" /> <div className='bigName'>{res.data.currencies[i].name}&nbsp;&nbsp;<span className='bigNamesmall'>{"(" + res.data.currencies[i].code + ")"}</span></div></div> }
        }
        setcurrListAll(data);
        
        if(currNamePath){
            handleChange(data.find(option => option.value.includes(currNamePath)))
        }
        console.log(data)
    };




    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px solid #16161',
            color: state.isSelected ? '#fff' : '#fff',
            backgroundColor: state.isSelected ? '#0c0f16' : '#0c0f16',
            padding: '2px 15px',
            borderLeft: '1px solid #16161',
            borderLeft: '5px solid #16161'
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    }


    const handleChange = async (selectedOption) => {
        Navigate({
            pathname: '/buy-token',
            search: `?currency=${selectedOption.value}&amount=${amountVal}`,
        });
        currNamePath = (selectedOption.value).split(" ")[0];
        console.log("frgre",(selectedOption.value).split(" ")[0])
        setfromCurrency((selectedOption.value).split(" ")[0])
        setFullNameCrr((selectedOption.value).split(" ")[1]);
        setFullNameCrr1((selectedOption.value).split(" ")[2] !== null && (selectedOption.value).split(" ")[2] !== '' &&  (selectedOption.value).split(" ")[2] !== undefined ? (selectedOption.value).split(" ")[2] : '');
        setFullNameCrr2((selectedOption.value).split(" ")[3] !== null && (selectedOption.value).split(" ")[3] !== '' &&  (selectedOption.value).split(" ")[3] !== undefined ? (selectedOption.value).split(" ")[3] : '');
        console.log("on slect chnage", 1, toCurrency, selectedOption.value)
        if (toCurrency && (selectedOption.value).split(" ")[0]) {
            setselectCheck(true);
            setloaderAct(true)
            let res = await changeCurrToSelected(1, toCurrency, (selectedOption.value).split(" ")[0]);
            if(res){
                setloaderAct(false)
                console.log("res", res)
                setgetCurrentPriceSelected(res.data.estimated_amount)
                console.log("to from cal", toAmt.current.value, fromAmt.current.value, getCurrentPriceSelected)
                fromAmt.current.value = toAmt.current.value * res.data.estimated_amount;
                setloadAfter(1)
            }
            else{
                Swal.fire({
                    text: "Error while processing your request please try again later",
                    button: false,
                    background: "black",
                    icon: "error",
                    timer: 2000,
                });
            }
        }
    }


    const getCurrToSelected = async () => {
        // console.log("amt",amountVal)
        // if(amountVal){
        //     // console.log("tokenPr", tokenPr)
        //     if (amountVal !== '' && amountVal >= 0) {
        //         settotalGoltToken((amountVal / tokenPr).toFixed(8))
        //         fromAmt.current.value = (amountVal * getCurrentPriceSelected).toFixed(8);
        //         if(toAmt.current.value >= 50){
        //             setMinAmt(true)
        //         }
        //         else{
        //             setMinAmt(false)
        //         }
        //     }
        //     else {
        //         toAmt.current.value = '';
        //         fromAmt.current.value = '';
        //         settotalGoltToken(0)
        //         setMinAmt(true)
        //     }
        // }
        // else{
            Navigate({
                pathname: '/buy-token',
                search: `?currency=${fromCurrency}&amount=${toAmt.current.value}`,
            });
            console.log("tokenPr", tokenPr, getCurrentPriceSelected)
            if (toAmt.current.value !== '' && toAmt.current.value >= 0) {
                settotalGoltToken((toAmt.current.value / tokenPr).toFixed(8))
                fromAmt.current.value = (toAmt.current.value * getCurrentPriceSelected).toFixed(8);
                if(toAmt.current.value >= 50){
                    setMinAmt(true)
                }
                else{
                    setMinAmt(false)
                }
            }
            else {
                toAmt.current.value = '';
                fromAmt.current.value = '';
                settotalGoltToken(0)
                setMinAmt(true)
            }
        // }
        
    }


    const handleOnCheck = (event) => {
        setCheck(!check)
    };


    const makePayment_fn = async () => {
        // console.log("toCurrencytoCurrencytoCurrency", toCurrency, fromCurrency)
        setCheckERR("");
        // if (user.isEmailVerified) {
        if (selectCheck) {
            if (toAmt.current.value !== '' && toAmt.current.value > 0) {
                if (check) {
                    setloaderAct1(true)
                    // let checkMin = await checkMinimumAmt(toCurrency, fromCurrency);
                    // console.log("checkMin", checkMin)
                    // if (toAmt.current.value >= checkMin.data.min_amount) {
                    if (toAmt.current.value >= 50) {
                        setPaymentSent(false)
                        setloaderAct1(true)
                        let invoice = await payInvoicePayment(toAmt.current.value, fromCurrency)
                        if(invoice){
                            console.log("invoice", invoice)
                            if (invoice.data.order_id) {

                                let checkAdd = web3.utils.isAddress(invoice.data.pay_address);
                                console.log("check",checkAdd)

                                if(invoice.data.pay_currency == 'eth' || invoice.data.pay_currency === 'ETH'){
                                    if(checkAdd){
                                        setChainIdCrr(ACTIVE_NETWORK_eth)
                                        setoneCurrency('eth')
                                        setmetamaskShow(true)
                                    }
                                    else{
                                        setmetamaskShow(false)
                                        Swal.fire({
                                            showConfirmButton: false, 
                                            text: "Pay address is not valid for metamask ",
                                            button: false,
                                            background: 'black',
                                            icon: "error",
                                            confirmButtonText: 'Close',
                                            timer: 4000,
                                        });
                                    }
                                }
                                else if(invoice.data.pay_currency == 'bnbbsc' || invoice.data.pay_currency === 'bnbbsc'){
                                    if(checkAdd){
                                        setChainIdCrr(ACTIVE_NETWORK_bsc)
                                        setoneCurrency('bnbbsc')
                                        setmetamaskShow(true)
                                    }
                                    else{
                                        setmetamaskShow(false)
                                        Swal.fire({
                                            showConfirmButton: false, 
                                            text: "Pay address is not valid for metamask ",
                                            button: false,
                                            background: 'black',
                                            icon: "error",
                                            confirmButtonText: 'Close',
                                            timer: 4000,
                                        });
                                    }
                                }
                                else if(invoice.data.pay_currency == 'USDC' || invoice.data.pay_currency === 'usdc'){
                                    if(checkAdd){
                                        setChainIdCrr(ACTIVE_NETWORK_eth)
                                        setoneCurrency('usdc')
                                        setmetamaskShow(true)
                                    }
                                    else{
                                        setmetamaskShow(false)
                                        Swal.fire({
                                            showConfirmButton: false, 
                                            text: "Pay address is not valid for metamask ",
                                            button: false,
                                            background: 'black',
                                            icon: "error",
                                            confirmButtonText: 'Close',
                                            timer: 4000,
                                        });
                                    }
                                }
                                else if(invoice.data.pay_currency == 'USDTERC20' || invoice.data.pay_currency === 'usdterc20'){
                                    if(checkAdd){
                                        setChainIdCrr(ACTIVE_NETWORK_eth)
                                        setoneCurrency('usdterc20')
                                        setmetamaskShow(true)
                                    }
                                    else{
                                        setmetamaskShow(false)
                                        Swal.fire({
                                            showConfirmButton: false, 
                                            text: "Pay address is not valid for metamask ",
                                            button: false,
                                            background: 'black',
                                            icon: "error",
                                            confirmButtonText: 'Close',
                                            timer: 4000,
                                        });
                                    }
                                }
                                else{
                                    setmetamaskShow(false)
                                }

                                setPayeaddress(invoice.data.pay_address)
                                setPayExtraId(invoice.data.payin_extra_id !== null && invoice.data.payin_extra_id !== '' &&  invoice.data.payin_extra_id !== undefined ? invoice.data.payin_extra_id : '')
                                setpayCurrSymbol(invoice.data.pay_currency)
                                setNetworkName(invoice.data.network)
                                setpayCurrAmt(invoice.data.pay_amount)
                                setTimer(invoice.data.expiration_estimate_date)
                                setPaymentId(invoice.data.payment_id)
                                setShowpay(true)
                                setloaderAct1(false)

                                // settotalGoltToken(0)
                                toAmt.current.value = '';
                                fromAmt.current.value = '';
                            }
                            else{
                                setloaderAct1(false)
                                Swal.fire({
                                    showConfirmButton: false, 
                                    text: invoice.data.msg,
                                    button: false,
                                    background: 'black',
                                    icon: "error",
                                    confirmButtonText: 'Close',
                                    timer: 4000,
                                });
                            }
                        }
                        else{
                            setloaderAct1(false)
                            console.log("Error while processing your request please try again later")
                        }
                    }
                    else {
                        setloaderAct1(false)
                        Swal.fire({
                            showConfirmButton: false, button: false,
                            text: 'Please Enter minimum amount 50 USD',
                            // text: `Please Enter minimum amount ${checkMin.data.min_amount} USDC`,
                            button: false,
                            background: 'black',
                            icon: "error",
                            confirmButtonText: 'Close',
                            timer: 4000,
                        });
                    }



                    // if (invoice.data.id) {
                    //     let savedInfoRes = await savePaymentInfo(invoice.data.id)
                    //     if (savedInfoRes.data.success) {
                    //         setloaderAct1(false)
                    //         window.location.href = invoice.data.invoice_url;
                    //     }
                    //     else {
                    //         setloaderAct1(false)
                    //         Swal.fire({ showConfirmButton:false, button:false, 
                    //             text: 'Payment url is not created',
                    //             button: false,
                    //             confirmButtonText: 'Close',
                    //             background: 'black',
                    //             icon: "error",
                    //             timer: 4000,
                    //         });
                    //     }
                    // }
                    // else {
                    //     setloaderAct1(false)
                    //     Swal.fire({ showConfirmButton:false, button:false, 
                    //         text: 'Payment url is not created',
                    //         button: false,
                    //         background: 'black',
                    //         icon: "error",
                    //         confirmButtonText: 'Close',
                    //         timer: 4000,
                    //     });
                    // }
                }
                else {
                    setCheckERR(' This checkbox is required')
                }
            }
            else {
                Swal.fire({
                    showConfirmButton: false, button: false,
                    text: 'Please enter amount in usd',
                    button: false,
                    background: 'black',
                    icon: "error",
                    confirmButtonText: 'Close',
                    timer: 4000,
                });
            }
        }
        else {
            setSelectERR(' This field is required')
        }
        // }
        // else {
        //     Swal.fire({
        //         showConfirmButton: false, button: false,
        //         text: 'Please verify your mail first.',
        //         button: false,
        //         background: 'black',
        //         icon: "error",
        //         confirmButtonText: 'Close',
        //         timer: 4000,
        //     });
        // }

    }


    const onCopyText = () => {
        setloaderAct2(true)
        setloaderAct2(true)
        setTimeout(function () {
            setloaderAct2(false)
            setPaymentSent(true)
            setloaderAct2(false)

        }, 8000);
    };


    const onCopyAdd = () => {
        setcopybtn(true)
        setTimeout(function () {
            setcopybtn(false)
        }, 2000);
    };

    const onCopyAdd1 = () => {
        setcopybtn1(true)
        setTimeout(function () {
            setcopybtn1(false)
        }, 2000);
    };

    //change after 20 min price
    const afterTwoZeroUpdate = async () => {
        console.log("paeyment id nfeenkffnk = ", paymentId)
        if (paymentId) {
            console.log("check payid 1 ")
            setExpire20(true);
            let res = await updateMerchant(paymentId)
            console.log("resdjdjbefbjf", res)
            setpayCurrAmt(res.data.pay_amount)
            setTimer(res.data.expiration_estimate_date)
        }
        else{
            console.log("check payid 2 ")
        }

    }


    useEffect(() => {

        const interval = setInterval(() => {
            const current = Math.floor(((new Date()).getTime()) / 1000);
            end = parseInt(new Date(timer).getTime() / 1000) - current;
            console.log(end, current, "end", "current")
            if (end <= 0) {
                console.log("enter in timer fun")
                afterTwoZeroUpdate();
                clearInterval(interval);
            } else {
                // setExpire20(false);
                console.log("running", end)
                end = end - 1;
            }
        }, 1000)
    }, [timer]);


    const connectWallet = async () => {
        try {
            if (window.ethereum) {
                const result = await window.ethereum.request({ method: 'eth_requestAccounts' });
                if (result) {
                    const chainId = await web3.eth.getChainId();
                    console.log("chain",chainId )
                    if(chainId){

                    }
                    await swichNetworkHandler();
                    setAccount(result[0]);
                    transferUsingMM()
                } else {
                    // toast.warning('No Accounts Found!');
                    Swal.fire({
                        showConfirmButton: false, button: false,
                        text: 'No Accounts Found!',
                        button: false,
                        background: 'black',
                        icon: "error",
                        confirmButtonText: 'Close',
                        timer: 4000,
                    });
                }
            } else {
                // toast.warning('Please Install Metamask wallet!');
                Swal.fire({
                    showConfirmButton: false, button: false,
                    text: 'Please Install Metamask wallet!',
                    button: false,
                    background: 'black',
                    icon: "error",
                    confirmButtonText: 'Close',
                    timer: 4000,
                });
            }
        }
        catch (err) {
            if (err.code === -32002) {
                // toast.warning('Already in processing, Please connect metamask wallet.');
                Swal.fire({
                    showConfirmButton: false, button: false,
                    text: 'Already in processing, Please connect metamask wallet.',
                    button: false,
                    background: 'black',
                    icon: "error",
                    confirmButtonText: 'Close',
                    timer: 4000,
                });
                setAccount('');
            }
            else {
                // toast.warning(err.message);
                Swal.fire({
                    showConfirmButton: false, button: false,
                    text: err.messag,
                    button: false,
                    background: 'black',
                    icon: "error",
                    confirmButtonText: 'Close',
                    timer: 4000,
                });
                setAccount('');
            }
        }
    }

    const transferUsingMM = async() =>{

        if(oneCurrency === 'eth' || oneCurrency === 'bnbbsc'){
            let userbalance = await web3.eth.getBalance(window.ethereum.selectedAddress);
            console.log("user",userbalance, account,payAddress, payCurrencyAmt )
    
            if(userbalance * 10**18 > payCurrencyAmt * 10**18){
                // using the promise
                await web3.eth.sendTransaction({
                    from: window.ethereum.selectedAddress,
                    to: payAddress,
                    value: payCurrencyAmt * 10**18
                },
                async function (err, result){
                    if(result){
                        Swal.fire({
                            showConfirmButton: false, button: false,
                            text: 'Your transaction is sent, Please wait for confirmation.',
                            button: false,
                            background: 'black',
                            icon: "success",
                            confirmButtonText: 'Close',
                            timer: 4000,
                        });
                    }
                })
                .then(function(receipt){
                    if(receipt.status == true){
                        Swal.fire({
                            showConfirmButton: false, button: false,
                            text: 'Transfer Success',
                            button: false,
                            background: 'black',
                            icon: "success",
                            confirmButtonText: 'Close',
                            timer: 4000,
                        });
                    }else{
                        Swal.fire({
                            showConfirmButton: false, button: false,
                            text: 'Transfer Error',
                            button: false,
                            background: 'black',
                            icon: "error",
                            confirmButtonText: 'Close',
                            timer: 4000,
                        });
                    }
                }).catch(function(error){
                
                    Swal.fire({
                        showConfirmButton: false, button: false,
                        text: 'Transfer Error',
                        button: false,
                        background: 'black',
                        icon: "error",
                        confirmButtonText: 'Close',
                        timer: 4000,
                    });
                });
            }
            else{
                Swal.fire({
                    showConfirmButton: false, button: false,
                    text: 'Enough balance',
                    button: false,
                    background: 'black',
                    icon: "error",
                    confirmButtonText: 'Close',
                    timer: 4000,
                });
            }
        }
        else if(oneCurrency === 'usdc'){
            const userBalance_usdc = await contract_usdc.methods.balanceOf(window.ethereum.selectedAddress).call();
            console.log("userBalance_usdc",userBalance_usdc)
    
            // if(userBalance_usdc * 10**6 > payCurrencyAmt * 10**6){
                // using the promise
                await contract_usdc.methods.transfer(payAddress, payCurrencyAmt * 10**6).send({
                     from: window.ethereum.selectedAddress 
                },
                async function (err, result){
                    if(result){
                        Swal.fire({
                            showConfirmButton: false, button: false,
                            text: 'Your transaction is sent, Please wait for confirmation.',
                            button: false,
                            background: 'black',
                            icon: "success",
                            confirmButtonText: 'Close',
                            timer: 4000,
                        });
                    }
                })
                .then(function(receipt){
                    if(receipt.status == true){
                        Swal.fire({
                            showConfirmButton: false, button: false,
                            text: 'Transfer Success',
                            button: false,
                            background: 'black',
                            icon: "success",
                            confirmButtonText: 'Close',
                            timer: 4000,
                        });
                    }else{
                        Swal.fire({
                            showConfirmButton: false, button: false,
                            text: 'Transfer Error',
                            button: false,
                            background: 'black',
                            icon: "error",
                            confirmButtonText: 'Close',
                            timer: 4000,
                        });
                    }
                }).catch(function(error){
                
                    Swal.fire({
                        showConfirmButton: false, button: false,
                        text: 'Transfer Error',
                        button: false,
                        background: 'black',
                        icon: "error",
                        confirmButtonText: 'Close',
                        timer: 4000,
                    });
                });
            // }
            // else{
            //     Swal.fire({
            //         showConfirmButton: false, button: false,
            //         text: 'Enough token balance',
            //         button: false,
            //         background: 'black',
            //         icon: "error",
            //         confirmButtonText: 'Close',
            //         timer: 4000,
            //     });
            // }
        }
        else if(oneCurrency === 'usdterc20'){
            const userBalance_usdt = await contract_usdt.methods.balanceOf(window.ethereum.selectedAddress).call();
            console.log("userBalance_usdt",userBalance_usdt)
    
            // if(userBalance_usdc * 10**6 > payCurrencyAmt * 10**6){
                // using the promise
                await contract_usdt.methods.transfer(payAddress, payCurrencyAmt * 10**6).send({
                     from: window.ethereum.selectedAddress 
                },
                async function (err, result){
                    if(result){
                        Swal.fire({
                            showConfirmButton: false, button: false,
                            text: 'Your transaction is sent, Please wait for confirmation.',
                            button: false,
                            background: 'black',
                            icon: "success",
                            confirmButtonText: 'Close',
                            timer: 4000,
                        });
                    }
                })
                .then(function(receipt){
                    if(receipt.status == true){
                        Swal.fire({
                            showConfirmButton: false, button: false,
                            text: 'Transfer Success',
                            button: false,
                            background: 'black',
                            icon: "success",
                            confirmButtonText: 'Close',
                            timer: 4000,
                        });
                    }else{
                        Swal.fire({
                            showConfirmButton: false, button: false,
                            text: 'Transfer Error',
                            button: false,
                            background: 'black',
                            icon: "error",
                            confirmButtonText: 'Close',
                            timer: 4000,
                        });
                    }
                }).catch(function(error){
                
                    Swal.fire({
                        showConfirmButton: false, button: false,
                        text: 'Transfer Error',
                        button: false,
                        background: 'black',
                        icon: "error",
                        confirmButtonText: 'Close',
                        timer: 4000,
                    });
                });
            // }
            // else{
            //     Swal.fire({
            //         showConfirmButton: false, button: false,
            //         text: 'Enough token balance',
            //         button: false,
            //         background: 'black',
            //         icon: "error",
            //         confirmButtonText: 'Close',
            //         timer: 4000,
            //     });
            // }

        }


        

    }

    const swichNetworkHandler = async () => {
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x" + chainIdCrr.toString(16) }],
            });
        } catch (error) {
            console.log("ERROR", error);
            if (error.code === 4902) {
                Swal.fire({ title: "<h5 style='color:white'>Please add the polygon network in your metamask wallet.</h5>", icon: "warning", background: "#808080", showConfirmButton: false, timer: 4000 });
                setAccount('');
            }
        }
    }

    // useEffect(() => {
    // }, [])

    return (
        <>
            <Helmet>
                <title>Buy Token | Golteum</title>
            </Helmet>            <Loader text="" show={OnLoader} />
            <Modal show={showpay} onHide={expire20 ? handleCloseAfterExpired : handleClosepay} className="QRbox" backdrop="static" keyboard={false}>
                <Modal.Header className="closebtn" closeButton>
                    <Modal.Title className="headertimer" >Payment Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>
                        {paymentSent ?
                            <p className='transacion1'>Your transaction is processing, please check your transaction history page for more details</p>
                            :
                            expire20 ? 
                            <p className='transacion1'>Your transaction is expired.</p>
                            :
                            <>
                                <div className='pt-3'>
                                    <p className="commonFields tracking-wider">
                                        Transaction Id
                                        <span className="cursor-pointer">{paymentId}</span>
                                    </p>
                                    <p className="commonFields tracking-wider">
                                        Golteum
                                        <span className="cursor-pointer">${tokenPr} USD</span>
                                    </p>
                                    <p className="commonFields tracking-wider">
                                        {fullNameCrr}  {fullNameCrr1}  {fullNameCrr2}
                                        <span className="cursor-pointer colorTheme">
                                            {/* <svg className='rounded-full11'></svg> */}
                                            {payCurrencyAmt} {payCurrSymbol}
                                        </span>
                                    </p>
                                    <p className="commonFields tracking-wider">
                                        Network
                                        <span className="cursor-pointer"><b>{networkName.toUpperCase()}</b></span>
                                    </p>
                                    <p className="commonFields tracking-wider">
                                        Price update in
                                        <span className="cursor-pointer">
                                            {paymentSent ? '' : <Timer end={now} start={new Date(timer).getTime() / 1000} />}</span>
                                    </p>
                                </div>
                                <div className='pb-5 centerCtn font-mono'>
                                    <p>Sending the funds through a different chain other than {networkName.toUpperCase()} will result in your funds being permanently lost.</p>
                                </div>
                                <div className='pt-3'>
                                    <p className="commonFields tracking-wider ">
                                        Send
                                        <span className="cursor-pointer priceMiddle">{payCurrencyAmt}</span>
                                        {payCurrSymbol} to
                                    </p>
                                    <div className='addresspayas'>
                                        <div className='addCopy'>
                                            {
                                                isMobile ?
                                                    <><input type="text" value={payAddress.substring(0, 8) + '...' + payAddress.slice(-8)} disabled />&nbsp;&nbsp;</>
                                                    :
                                                    <><p>{payAddress}</p>&nbsp;&nbsp;</>
                                            }

                                            <CopyToClipboard text={payAddress} className="  p-0" onCopy={onCopyAdd}>
                                                {copybtn ? <button style={{ border: 0, color: " #fff", backgroundColor: "#0000", padding: " 0 !important;" }}><i className="fa fa-clone copyto" aria-hidden="true"></i></button> : <button className="p-0" style={{ border: 0, backgroundColor: "#0000" }}><i className="fa fa-clone" aria-hidden="true"></i></button>}

                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                    {
                                    payExtraId !== null && payExtraId !== '' &&  payExtraId !== undefined ? 
                                    <div className='addresspayas'>
                                        <div className='addCopy'>
                                            {
                                                // isMobile ?
                                                    // <><input type="text" value={payExtraId} disabled />&nbsp;&nbsp;</>
                                                    // :
                                                    <><p>Memo: {payExtraId}</p>&nbsp;&nbsp;</>
                                            }

                                            <CopyToClipboard text={payExtraId} className="  p-0" onCopy={onCopyAdd1}>
                                                {copybtn1 ? <button style={{ border: 0, color: " #fff", backgroundColor: "#0000", padding: " 0 !important;" }}><i className="fa fa-clone copyto" aria-hidden="true"></i></button> : <button className="p-0" style={{ border: 0, backgroundColor: "#0000" }}><i className="fa fa-clone" aria-hidden="true"></i></button>}

                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                    :
                                    ''
                                    }


                                </div>
                                <Fragment>
                                    <Accordion open={open === 1} animate={customAnimation}>
                                        <Accordion.Header className='' onClick={() =>
                                            handleOpen(1)}>
                                            <div className='onBtnQr'>
                                                <div className=''>
                                                    <Grid
                                                        height="16"
                                                        width="16"
                                                        color="#000"
                                                        ariaLabel="grid-loading"
                                                        radius="12.5"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                    />
                                                </div>
                                                <p className=''>&nbsp;&nbsp;QR Code</p>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body className="">
                                            <div className='qrCodeOverly' style={{ position: "relative" }}>
                                                <QRCode
                                                    size={200}
                                                    className="qrcodeImage"
                                                    style={{ height: "auto" }}
                                                    value={payAddress ? payAddress : ''}
                                                    viewBox={`0 0 200 200`}
                                                />
                                                {/* <img src="images/logoLoader.png" className="miniLogo1" alt="" /> */}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion>
                                </Fragment>
                                <div>
                                    {
                                        metamaskShow ?
                                        <div className='metamaskbutton'>
                                        {
                                            account ?
                                            <button type='button' onClick={transferUsingMM}><img src="images/MetaMask_Fox.webp"/>&nbsp;Pay using MetaMask</button>
:
                                            <button type='button' onClick={connectWallet}><img src="images/MetaMask_Fox.webp"/>&nbsp;Pay using MetaMask</button>
                                            

                                        }
                                        </div>
                                        :
                                        ''
                                    }
                                </div>
                                <div className='pt-4 pb-0'>
                                    <p className="commonFields tracking-wider">
                                        Status
                                        <span className="cursor-pointer awaitDiv">
                                            <ThreeDots
                                                height="22"
                                                width="22"
                                                radius="9"
                                                color="#3666E0"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                            Awaiting for payment
                                        </span>
                                    </p>
                                    <p className="commonFields tracking-wider">
                                        Coin
                                        <span className="cursor-pointer">Golteum</span>
                                    </p>
                                    <p className="commonFields tracking-wider">
                                        Quantity
                                        <span className="cursor-pointer">{totalGoltToken} GLTM</span>
                                    </p>
                                    <p className="commonFields tracking-wider">
                                        Received
                                        <span className="cursor-pointer">0.00000000 {payCurrSymbol}</span>
                                    </p>
                                </div>
                                {/* <div className='payInfoas'>
                                    <p>Send</p>
                                    <p><span>{payCurrencyAmt.toFixed(5)}</span> {payCurrSymbol}</p>
                                    <p>ETH to</p>
                                </div> */}
                                {/* <label className='lbltext'>Send the indicated amount to the address below:</label> */}
                                {/* <div className='addresspayas'> */}
                                {/* <label className='labelqrs'>
                                        Pay with Address:
                                    </label> */}
                                {/* <div className='addCopy'>
                                        {
                                            isMobile ?
                                                <><input type="text" value={payAddress.substring(0, 8) + '...' + payAddress.slice(-8)} disabled />&nbsp;&nbsp;</>
                                                :
                                                <><p>{payAddress}</p>&nbsp;&nbsp;</>
                                        }

                                        <CopyToClipboard text={payAddress} className="  p-0" onCopy={onCopyAdd}>
                                            {copybtn ? <button style={{ border: 0, color: " #fff", backgroundColor: "#0000", padding: " 0 !important;" }}><i className="fa fa-clone copyto" aria-hidden="true"></i></button> : <button className="p-0" style={{ border: 0, backgroundColor: "#0000" }}><i className="fa fa-clone" aria-hidden="true"></i></button>}

                                        </CopyToClipboard>
                                    </div> */}

                                {/* <div className='cssForAll mb-4'>
                                        <div className="or-box "><span>OR</span></div>
                                    </div> */}
                                {/* <label className='labelqrs'>
                                        Scan the QR code
                                    </label> */}
                                {/* <div className='qrCodeOverly' style={{ position: "relative" }}>
                                        <QRCode
                                            size={200}
                                            className="qrcodeImage"
                                            style={{ height: "auto" }}
                                            value={payAddress ? payAddress : ''}
                                            viewBox={`0 0 200 200`}
                                        />
                                        <img src="images/logoLoader.png" className="miniLogo1" alt="" />
                                    </div> */}

                                {/* </div> */}
                                <div>
                                    <div className='cssForAll text-center mt-5 mb-5 payBtnClass'>

                                        {loaderAct2 ? <a href="#" rel="noopener" className="btn btn-grd" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</a> : <a href="#" rel="noopener" className="btn btn-grd" onClick={onCopyText}>Payment sent</a>}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            <div className="dash-container">
                <div className="page-tit">
                    <h4>Buy Token</h4>
                </div>

                <div className="dash-container-in">
                    <div className="row dash-home">
                        <div className="col-md-8 d-block">
                            <div className="card-cust p-4">
                                {showWaitList ? '' : <p className='hidewarining'>You can buy token after presale starts</p>}

                                <p className="gry-text">Enter the amount you would like to contribute in order to calculate the amount of tokens you will receive. The calculator below helps to convert the required quantity of tokens into the amount of your selected currency.</p>
                                <div className="form-group">
                                    <label>Select Cryptocurrency</label>
                                    {currListAll.length === 0 ?
                                        <div className="form-group">
                                            <label>Currency</label>
                                            <select className="form-control">
                                                <option value="" >No Cryptocurrency found.</option>
                                            </select>
                                        </div>
                                        :
                                        (
                                            <Select
                                                // controlShouldRenderValue = { false }  
                                                value={currListAll.find(option => option.value.includes(currNamePath))}                                      
                                                options={currListAll}
                                                onChange={handleChange}
                                                className="selectBox form-control"
                                                classNamePrefix="selectBox"
                                                clearable={false}
                                                placeholder="Choose cryptocurrency"
                                                styles={customStyles}
                                                menuColor='white'
                                                isSearchable
                                                noOptionsMessage={() => "No Cryptocurrency available"}
                                                autoFocus={true}
                                            />
                                        )
                                    }
                                    <div>
                                        <span style={{ color: "red" }}>{selectCheck ? '' : selectERR}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Enter amount in USD</label>
                                    <input type="text" name="" className="form-control" placeholder="Amount (USD)" ref={toAmt} defaultValue={amountVal} onChange={(event => { getCurrToSelected() })} />
                                    <span style={{ color: "red", display:"block",paddingTop: "5px" }}>{minAmt ? '' : 'Please enter minimum amount of 50 USD.'}</span>
                                </div>
                                <div className="form-group">
                                    <label>Crypto Amount {fromCurrency}</label>
                                    <input type="text" name="" className="form-control" placeholder="Enter Amount" ref={fromAmt} disabled />
                                    {loaderAct ? <img className="loaderImg_crr" src="images/loader2.gif" alt="" /> : ''}
                                </div>
                                <div className="form-group">
                                    <label>Total $GLTM Tokens you will receive</label>
                                    <input type="text" name="" className="form-control" placeholder="$GLTM Tokens" value={totalGoltToken} disabled />
                                </div>
                                <div className="check-list">
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="checkbox" checked={check} onChange={(event) => handleOnCheck(event)} id="flexCheckDefault" />
                                        <label className={check ? "form-check-label textColor" : 'form-check-label'} htmlFor="flexCheckDefault">
                                            I read and agree to Golteum’s <Link to="" onClick={() => { setShow(true) }}>token purchase agreement</Link> and <Link to="" onClick={() => { setShow2(true) }}>privacy policy</Link>.
                                        </label>
                                    </div>
                                    <div>
                                        <span style={{ color: "red" }}>{check ? '' : checkERR}</span>
                                    </div>
                                </div>
                                {
                                    showWaitList ?
                                        loaderAct1 ?
                                            <button className="btn btn-grd mt-2" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button>
                                            : <button className="btn btn-grd mt-2" onClick={makePayment_fn} >Make Purchase</button>
                                        : <button className="btn btn-grd mt-2" disabled >Make Purchase</button>
                                }
                                <p class="gry-text pt-2 dxTextBuy"><span class="bigdis">IMPORTANT NOTE:</span>Tokens will appear in your Dashboard account after payment is successfully completed by our team. You will be able to claim your tokens at the end of the presale from your Golteum Dashboard account. Partial payments not accepted. Please make sure you include the fees.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <CardRight />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <Modal show={show} onHide={handleClose} className=" agreementBox">
                <Modal.Header className="closebtn" closeButton>
                    <Modal.Title>Token Purchase Agreement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="inner-page-cont popupLyout">
                        <div className="container">
                            {/* <h1>GLTM TOKEN SALE AGREEMENT</h1> */}
                            <p> <b> THIS DOCUMENT IS NOT A SOLICITATION FOR INVESTMENT AND DOES NOT CONSTITUTE AN OFFER OF DIGITAL CURRENCY, COMMODITY, SECURITY, FINANCIAL INSTRUMENT OR ANY OTHER FORM OF INVESTMENT, SECURITIES TO THE PUBLIC OR A COLLECTIVE INVESTMENT SCHEME, NOR DOES IT REQUIRE REGISTRATION OR APPROVAL FROM A REGULATORY AUTHORITY IN ANY JURISDICTION.</b></p>
                            <p>This Agreement is part of a reward-based fundraising event and no financial instruments are issued to the Participant.</p>
                            <p>Tokens are not securities and do not carry with them any rights as may be commonly associated with securities and this Agreement, its Annexes or any other documents which are an integral part of the Agreement do not constitute an offer of securities to the public by GLTM Token.</p>
                            <p>If you wish to purchase GLTM Tokens, you must not understand, interpret, classify or treat GLTM Token’s as: (1) currencies; (2) bonds and stocks issued by any institution (3) units of securities or derivatives such as collective investment schemes and business trusts (4) rights under contracts for difference and other contracts whose purpose is or is impersonated for the purpose of guaranteeing return on investment or avoiding losses (5) rights, options, and derivatives in these bonds and stocks issued by any institution.</p>
                            <p>THIS TOKEN SALE AGREEMENT (the “Agreement” and/or “Token Crowdsale Agreement”) is made as of today</p>
                            <h5>BETWEEN</h5>
                            <p> <b>Golteum Limited,</b> a corporate entity associated with the golteum.io platform, (hereinafter referred to as the “Company”)</p>
                            <h5>AND</h5>
                            <p> <b>Any person</b> (natural or juridical), who intends to participate in and contribute towards, this public reward-based fundraising and become a Participant (hereinafter referred to as the “Participant”)</p>
                            <p>(Hereinafter singly referred to as the ‘Party’ and collectively referred to as the ‘Parties’)</p>
                            <h5>PREAMBLE</h5>
                            <p> <b>WHEREAS,</b> the Company intends to accept contributions through a Token Crowdsale, in exchange for an allocation of Tokens up to an amount not exceeding the Maximum Token Amount, and during the Token Crowdsale Period.</p>
                            <p> <b>WHEREAS,</b> the amount raised will be used to fund the Company’s development of a platform (the “Platform”) that enables the Company to create and release products and services that allow users of the Platform to inter alia (i) make payments, (ii) and participate in other services.</p>
                            <p> <b>WHEREAS,</b> the terms of the Token Crowdsale and other information and details of Token and the Project are outlined on the Website and in the Whitepaper.</p>
                            <p> <b>WHEREAS,</b> the Company declares that, having taken all reasonable care to ensure that such is the case, the information contained in this Agreement and on the Website, to the best of its knowledge, is in accordance with the facts and contains no omission likely to affect a Participant’s decision to participate in the Token Crowdsale or not.</p>
                            <p> <b>WHEREAS,</b> the Token Crowdsale applies only to Participants who confirm that they are not Prohibited Participants.</p>
                            <p> <b>WHEREAS,</b> the Company intends only to accept contributions in the Coins or tokens denoted on the web page for the Tokens unless otherwise decided at the discretion of the Company.</p>
                            <p><b>WHEREAS,</b> the Company and the Participant desire to enter into a relationship in which the Participant shall contribute towards the Project, and the Company shall allocate the Tokens to be used on the Platform, developed by the Company.</p>
                            <p> <b>WHEREAS,</b> Tokens are linked to the use of the Platform and are not designed or disingenuously devised to acquire shares or security/ies or equivalent rights, intellectual property rights or any other form of participation relating to the Company or money, or any expectation of profits.</p>
                            <p> <b>NOW, THEREFORE,</b> in consideration of the mutual agreements herein contained, and intending to be legally bound, the parties agree as follows:</p>

                            <h3>1.Definitions and Interpretation</h3>
                            <h5>GLTM Token</h5>
                            <p>The token created and issued by Golteum.io. The Company may be deemed to include its subsidiary/ies, parent/s and/or other related parties who will be contributing to the development and completion of the Project.
                            </p>
                            <h5> The Platform</h5>
                            <p>a decentralized platform that the Company is developing and owns, operating on the basis of blockchain technology and smart contracts.</p>
                            <h5>Dissolution Event</h5>
                            <p>an event whereby it becomes necessary, recommendable or less disadvantageous for the Company to dissolve or liquidate.</p>
                            <h5>Exchange Rate</h5>
                            <p>means (i) with respect to ETH the relative ETH/USD exchange rate displayed as an estimate to the Participant prior to the contribution and calculated by the Company on the basis of an averaged ETH/USD exchange rate which shall be acquired from multiple independent sources at the time of the contribution and (ii) with respect to other means of payment, the exchange rate to be used shall be determined by the Company the same way and agreed with the Participant.</p>
                            <h5>Hard Cap</h5>
                            <p>the aggregate maximum contribution that may be accepted by the Company from any number of Participants and which amount shall not exceed  (100,000,000) GLTM) or equivalent in any ratio of USDT or ETH, (subject to Exchange rate) as a consequence of which Tokens shall be allocated to the Participant/s from within the Total Token Supply;</p>
                            <h5>Maximum Token Amount</h5>
                            <p>the total amount of Tokens available for allocation during the Token Crowdsale in exchange for contribution received which contribution shall not exceed the Hard Cap.</p>
                            <h5>Minimum Contribution</h5>
                            <p>there is no minimum contribution from anyone Participant during the Token Crowdsale, but the Company reserves the right to introduce a minimum contribution at a later stage. A contribution less than a possible minimum contribution may at the Company’s discretion not be accepted.
                            </p>
                            <h5>Participant</h5>
                            <p>the amount in USDT or ETH or other accepted currency contributed by a Participant subject to the USD exchange rate.</p>
                            <p>Price a monetary value set by the Company at USD 0.13 to USD 0.36 per Token used as a reference in order to determine the number of Tokens to be allocated to a Participant further to the Participation Amount.</p>
                            <h5>Prohibited Participant</h5>
                            <p>(i)a natural person wishing to become a Participant and being a citizen, national, resident or having a similar connecting factor to; or (ii) a juridical person wishing to become a Participant and being incorporated, registered or effectively managed and controlled form or in: a country, jurisdiction or territory where the Token Crowdsale or the holding and use, of Tokens and/or virtual currencies or other tokens at any other moment in time is prohibited by laws, regulations or other practices and policies in the said country, jurisdiction or territory, which is taken to include, but is not limited to the US or any other jurisdictions where the aforementioned are prohibited. This shall include any person representing or acting on behalf of such Prohibited Participant/s in any manner or capacity whether openly or covertly.</p>
                            <h5>Project</h5>
                            <p>the Project being the reason behind the Token Crowdsale with the aim of creating the Platform into which Token/s shall be utilized as explained on the Website.</p>
                            <h5>Terms</h5>
                            <p>The Terms of Token Crowdsale as may be amended from time to time, which shall govern the purchase and use of Tokens and the Platform.</p>
                            <h5>Token</h5>
                            <p>a utility cryptographic decentralized token named “GLTM” based on the platform intended primarily for the use of, access or participation to the Platform as outlined on the Website</p>
                            <h5>Token Crowdsale</h5>
                            <p>the offer made by the Company, through four Phases to a public reward-based fundraising event, to accept contributions towards the Project, which contributions will be rewarded with an allocation of a determinate amount of Tokens, in the Private Sale of the Token, to participants according to the terms of this Agreement.</p>
                            <p>The Token Crowdsale will be conducted on the project's web page and at a later stage other platforms or exchanges.</p>
                            <h5>Token Crowdsale Period</h5>
                            <p>The Token Crowdsale Period starts on the date and time set to be announced on the website and continues for a period of up to six (6) months or the moment when the Maximum Token Amount has been reached, whichever occurs earlier;</p>
                            <h5>Total Token Supply</h5>
                            <p>the total amount of Token supplied shall be 100m (100,000,000); where a total of (29,500,000) are to be sold in the Crowdsale (Maximum Token Amount).</p>
                            <h5>Wallet</h5>
                            <p>a private key, or a combination of private keys linked to an ethereum-based digital wallet having a unique address and capable of accepting ERC-20 and the GLTM Token and which is necessary to acquire, hold and dispose of the GLTM Token.</p>
                            <h5>Website</h5>
                            <p>the website linked to the domain www.Golteum.io and all subdomain of such websites or any other website as indicated by the Company from time to time.</p>
                            <h5>Terms and context</h5>
                            <p>The reference to the terms “country”, “jurisdiction” and “territory” may be used interchangeably within this Agreement and shall have the same meaning and shall also be taken to include any determinate geographic location to the extent applicable in this Agreement.</p>
                            <p>The reference to the term “holding” used with respect to the holding of Token shall be construed to include holding in any manner including but not limited to ‘ownership’ and ‘possession’, whether in the Participant’s own name or on behalf of others.</p>
                            <p>The term “use” with respect to the use of Token shall be construed to include ‘trade’, ‘barter’, ‘exchange’ or ‘utilising’ of Token in any other manner, whether in the Participant’s own name or on behalf of others.</p>
                            <p>The term “Wallet” shall be construed to include ‘digital Vault’ or other storage mechanism and these terms may be used interchangeably within this Agreement.</p>
                            <p>The terms “you”, “your”, “he”, “contributor” and “participant” may be used interchangeably within this Agreement and shall have the same meaning as the definition of Participant above.</p>
                            <p>The terms “we”, “us”, “our” and “company” may be used interchangeably within this Agreement and shall have the same meaning as the definition of Company above.</p>
                            <p>The headings in this Agreement are inserted for convenience only and shall not affect its construction.</p>
                            <p>Where the context so requires, the use of the masculine gender shall include the feminine and/or neuter genders and the singular shall include the plural, and vice versa.</p>
                            <h3>2.The process and the sale</h3>
                            <h5>Agreement</h5>
                            <p>The Participation process, detailed on the Website, will involve the Company accepting contributions from a Participant during the Token Crowdsale. The Company shall not be obliged to accept contributions that are less than the Minimum Contribution and shall also not be obliged to accept contributions from Participants who do not provide the documents necessary.</p>
                            <p>Acceptance to this Agreement, the Terms and any other documents made via the Website together with the contribution made by the Participant shall be legally binding on the Participant.</p>
                            <p>Therefore, to the extent that the Company refuses to accept the Contribution as outlined in this Agreement, the contribution shall be non-refundable.</p>
                            <p>The Company intends only to accept contributions in ETH. </p>
                            <h5>Transfer of Tokens</h5>
                            <p>Tokens are issued at the sole discretion of the Company on submission of the requisite Participation Amount, and satisfaction of the following cumulative conditions: (a) the Participant has accepted via the Website, the term of this Agreement, its Annexes and any other documents forming part of the Contract; (b) the Participant has transferred the contribution which the Company has confirmed receipt of; (c) The Contribution satisfied the Minimum Contribution, during the Token Crowdsale, unless otherwise accepted by the Company; (d) the Company has received, is satisfied with, the documentation requested in Annex I; (e) the Company has no reason to believe that the Representations made in Annex II are incorrect or false;</p>
                            <p>After all the above cumulative conditions are fulfilled, the transfer of Tokens shall be made electronically to the designated Wallet, within a reasonable time after the Token Crowdsale presale stage 4 ends.</p>
                            <p>Where any of the above cumulative conditions has not been satisfied by the Participant, the Company shall request immediately the Participant to rectify the situation and satisfy the said conditions. Failure to do so within a reasonable time, the Company shall reserve the right to return the contribution to the Participant.</p>
                            <p>The Tokens shall not be transferable until the Token Crowdsale Period ends, and all Maximum Token Amount has been allocated and distributed.</p>
                            <h3>3.Participation Principles</h3>
                            <p>The Participant is not eligible to acquire any Tokens if the Participant is a Prohibited Participant as defined above. Tokens are not available to any Prohibited Participant and the Company retains the right to refuse to transfer the Tokens to any Prohibited Participant.</p>
                            <p>3.2.The Participant understands and agrees that it is his obligation to ensure compliance with any legislation relevant to his country of residence or domicile concerning the acquisition of Tokens. The Participant also represents and warrants that to the extent that he is not a Prohibited Participant, it is solely up to him to inform himself and ensure that no prior or subsequent approval, notification, registration or licence is needed or if such is needed it is solely up to him to obtain such prior or subsequent approval, notification, registration or licence.</p>
                            <p>3.3.All Tokens allocated from the Company are final, and there are no refunds or cancellations except as may be required by applicable law, decree, regulation, treaty, or administrative act and/or as set forth in Clause 4 of this Agreement. The Company reserves the right to refuse or cancel the acquisition of Tokens at any time at its sole discretion.</p>
                            <p>3.4.The Participant confirms his understanding that Tokens are not securities and do not carry with them any rights as may be commonly associated with securities. In particular, Tokens do not grant any rights with respect to corporate decision making. Also, Tokens do not grant a right to dividends, votes or proceeds upon liquidation or any other right to payment from the Company. Tokens are intended solely to be used on the Platform. Rights of the Participant in this Token Crowdsale are limited to statutory and contractual rights according to Law.</p>
                            <p>3.5.The Participant confirms his understanding that the Company retains all right, title and interest in all of its intellectual property, including inventions, discoveries, processes, marks, methods, compositions, formulae, techniques, information, source code, brand names, graphics, user interface design, text, logos, images, information and data pertaining to the Website, the Project and Token whether or not patentable, copyrightable or protectable in trademark, and any trademarks, copyrights or patents based thereon. A Participant may not use any of the Company’s intellectual property for any reason, except with the Company’s express, prior, written consent.</p>
                            <p>3.6.Acquiring Tokens by the Participant in no way creates any exclusive relationship between the Participant and the Company, nor any partnership, joint venture, employment or agency.</p>
                            <p>3.7.The Participant confirms his understanding that the Company shall issue Tokens once the Token Crowdsale is closed; and only if documentation requested in Annex II and other verifications have been passed and approved by the Company at its sole discretion.</p>
                            <h3>4.Dissolution Event</h3>
                            <p>4.1.In the case of a Dissolution Event before the Token Crowdsale end date, the Company will refund an amount equal to the Participation Amount and payable to the Participant immediately prior to, or concurrent with, the consummation of the Dissolution Event, subject to rights and privileges of creditors under Irish law.</p>
                            <p>. If immediately prior to the consummation of the Dissolution Event, the assets of the Company that remain legally available for distribution to the Participant and all participants entering this Agreement separately, as determined in good faith by the Company’s board of directors, are insufficient to permit the payment to all Participants of their respective Participation Amounts, then the remaining assets of the Company legally available for distribution, following all distributions to the shareholders and creditors, will be distributed with equal priority and pro-rata among the Participants in proportion to their Participation Amounts.</p>
                            <h3>5.Termination</h3>
                            <p>This Agreement will expire and terminate upon the earlier of (i) the allocation of Tokens to the Participant according to Clause 2 or refund of the amount of Participation Amount to Participant as the case may be; or (ii) the payment, or setting aside for payment, of amounts due to the Participant according to Clause 4;</p>
                            <p>All provisions of the Terms which by their nature should survive termination, shall survive termination, including but not limited to, disclaimers or limitations of obligations or liability and indemnity.</p>
                            <h3>6.Representations and Warranties of the Company</h3>
                            <h5>The Company hereby represents and warrants to the Participant that:</h5>
                            <p>a.the Company is a company duly organized and validly existing under the laws of the jurisdiction of its incorporation and has the power to own and lease its properties and to carry on its business as now being conducted and as presently proposed to be conducted;</p>
                            <p>b.the Company has the legal right and full power and authority to enter into, execute, deliver and perform their respective obligations under the Agreement;</p>
                            <p>c.the execution and performance of this Agreement by the Company have been duly authorized by all necessary actions of the Company, and this Agreement has been duly executed and delivered by the Company;</p>
                            <p>d.The execution and performance of the Agreement does not and will not breach any agreement or obligation by which they are bound or will not violate or infringe any applicable law or tax regulations; and</p>
                            <p>e.The Agreement, once executed, will constitute legal, valid, binding and enforceable obligations towards the Parties.</p>

                            <h3>7.Representations and Warranties of the Participant</h3>
                            <h5>The Participant hereby represents and warrants to the Company that:</h5>
                            <p>a.The Participant is an individual or a legal entity duly organized and validly existing under the laws of the jurisdiction of its incorporation;</p>
                            <p>b.The Participant has the legal right and full power and authority to enter into, execute, deliver and perform their respective obligations under the Agreement;</p>
                            <p>c.The execution and performance of this Agreement by the Company have been duly authorized by all necessary actions of the Company, and this Agreement has been duly executed and delivered by the Company;</p>
                            <p>d.The execution and performance of the Agreement does not and will not breach any agreement or obligation by which they are bound or will not violate or infringe any applicable law or tax regulations;</p>
                            <p>e.The Participation Amount is provided on Participant’s own account, not as a nominee or agent, and not with a view to assigning any part thereof, and Participant has no present intention of selling, granting any participation in, or otherwise distributing any interest the Participant has under with respect to the Participation Amount or otherwise in connection with this Agreement;</p>
                            <p>f.Participant does not have any contract, undertaking, agreement or arrangement with any person to sell, transfer or grant participations to such person or to any third person, with respect to this Agreement and/or the Participation Amount;</p>
                            <p>g.Participant is a Participant in a Project in its development stage and acknowledges that it is able to fend for itself, can bear the economic risk of its acquisition of Token, and has such knowledge and experience in financial or business matters that it is capable of evaluating the merits and risks of this Agreement;</p>
                            <p>h.The Participant has such knowledge and experience and sophistication in financial, tax, business and technology matters as to enable the Participant to evaluate the legal, economic and other merits and risks associated with this Agreement and the transactions contemplated thereby, including, but not limited to, subscribing for Token, and to make an informed decision with respect thereto. Without prejudice to the foregoing, the Participant hereby represents and warrants to the Company that he has carefully reviewed and understands and accepts the various risks of entering into this Agreement, including the Participant’s possible participation in the Token Crowdsale and the risks associated with subscribing for Token. The Participant hereby consents and agrees to bear such risks.</p>
                            <p>i.The Participant hereby warrants that is responsible for determining what taxes shall be applied including, for example, Crowdsale, use, value-added, and similar taxes. Any amount that the Participant pays for Tokens is exclusive of all applicable taxes. It is also the Participant’s responsibility to withhold, collect, report and remit the possible taxes to the appropriate tax authorities. The Company is not responsible for withholding, collecting, reporting, or remitting any Crowdsale, use, value-added, or similar tax arising from the purchasing of Tokens.</p>
                            <p>J. The Participant understands and agrees that the Tokens have no rights, uses or attributes, purposes, functionalities or features, express or implied, including without limitation any uses, purposes, attributes, functionalities or features except those that are provided by Platform and as explained on the Webpage</p>
                            <p>K. The Participant furthermore warrants to the Representations made in Annex II.</p>
                            <h3>8.Taxes & Indemnity</h3>
                            <p>The Participant shall be responsible to pay all applicable taxes and duties, if any, that may arise in connection with its acquisition under this Agreement. Buyer will defend, indemnify and hold harmless the Company, its directors, officers, members, employees, agents, attorneys, representatives, affiliates and associates from any claims, damages, losses, liabilities, penalties, fines, costs and expenses arising out of or relating to any third-party claim concerning this agreement, including without limitation any claims related to taxes and duties mentioned.</p>
                            <h3>9.Disclaimers: Limitation of Liability</h3>
                            <p>THE ACQUISITION OF TOKENS UNDER THIS AGREEMENT, THE USE OF TOKENS AND THE PLATFORM ARE PROVIDED ON AN ‘AS IS’ AND ‘AS AVAILABLE’ BASIS. THE COMPANY AND ITS AFFILIATES MAKE NO REPRESENTATIONS OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE REGARDING THE ACQUISITION OF TOKEN UNDER THIS AGREEMENT, AND THE PLATFORM INCLUDING ANY WARRANTY THAT THE PLATFORM WILL BE UNINTERRUPTED, ERROR-FREE OR FREE OF HARMFUL COMPONENTS OR THAT ANY CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED. EXCEPT TO THE EXTENT PROHIBITED BY LAW, THE COMPANY, ITS DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES, AGENTS, ATTORNEYS, REPRESENTATIVES, AFFILIATES AND ASSOCIATES DO NOT ACCEPT ANY LIABILITY FOR ANY DAMAGE OR LOSS, INCLUDING LOSS OF BUSINESS, REVENUE, OR PROFITS, OR LOSS OF OR DAMAGE TO DATA, EQUIPMENT, OR SOFTWARE (DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY OR OTHERWISE), RESULTING FROM ANY USE OF, OR INABILITY TO USE TOKENS, THE PLATFORM OR THE MATERIAL, INFORMATION, SOFTWARE, FACILITIES OR CONTENT ON THE PLATFORM, AS WELL AS FROM ACQUIRING OF TOKENS,</p>
                            <p>REGARDLESS OF THE BASIS, UPON WHICH THE LIABILITY IS CLAIMED. BUYER ASSUMES ALL RISK OF LOSS RESULTING FROM, CONCERNING OR ASSOCIATED WITH RISKS SET FORTH IN THIS AGREEMENT, WHICH ARE HEREBY INCORPORATED BY REFERENCE INTO THIS AGREEMENT.</p>
                            <h3>10.Disclosure of Certain Risk Factors associated with Tokens</h3>
                            <p>The Participant understands that the acquisition of Tokens involves substantial risk, including, without limitation, the following (the terms herein shall have the meaning assigned to them in the Agreement to which this is attached):</p>
                            <p>(a)The Company has no operational history.</p>
                            <p>(b)The Company maintains sole discretion in the conduct of its business, including as related to its offer, maintenance and use of any Token and the Platform. The Participant will not have the ability to influence the performance or decisions made by Company.</p>
                            <p>(c) The acquisition of Tokens is subject to severe constraints on liquidity. At the time of the Token Crowdsale Event, there is no market for the Tokens and such a market may not develop. The acquisition of Tokens is suitable only for sophisticated and experienced Participants who are financially able to maintain their tokens for an indefinite period of time and who can afford a loss of their entire Participation Amount.</p>
                            <p>(d)Acquisition of Tokens is highly volatile and speculative and considered highly risky. In particular, the Participant understands and accepts the inherent risks associated with Tokens, including, but not limited to, risks associated with</p>
                            <p>(a)decentralization of the blockchain technology;</p>
                            <p>(b)money laundering;</p>
                            <p>(c)fraud;</p>
                            <p>(d)anonymity of transactions;</p>
                            <p>(e)exploitation for illegal purposes;</p>
                            <p>(f)theft;</p>
                            <p>(g)instability and other flaws of exchanges or brokers/custodians;</p>
                            <p>(h)the lack of regulation of tokens as of the date hereof.</p>
                            <p>(e)The Participant understands and accepts that the software and hardware, technology and technical concepts and theories usually used by issuers of Tokens is still in an early development stage and unproven, there is no warranty that the technology will be uninterrupted or error-free and there is an inherent risk that the technology could contain weaknesses, vulnerabilities or bugs causing, inter alia, the complete loss of the Tokens and the Participant’s entire Participation Amount.</p>
                            <p>(f)The Participant understands and accepts that the distribution of a Token and the development of the Project by the Crowdsale of Token, may be abandoned for a number of reasons, including but not limited to failure of the Project, lack of interest of the industry and/or the public, lack of funding, lack of commercial success or prospects (e.g. caused by competing projects) etc. The Participant, therefore, understands that there is no assurance that, even if such Project is partially or fully developed and launched, the Participant may receive any functionality through the Token held by him, and that they may become worthless. The Participant further understands and accepts that the Project financed by the Crowdsale of Token, may give rise to other, alternative projects, promoted by third parties unaffiliated to the issuer of the Token, under which the Token will have no intrinsic value.</p>
                            <p>(g)Tokens are usually accessed using a private key that corresponds to the Wallet at which it is stored. The Participant understands and accepts that if the private key or the “seed” used to create the address and corresponding private key got lost or stolen, the obtained Token associated with the Participant’s address might be unrecoverable and will be permanently lost.</p>
                            <p>(h)The Participant understands and accepts that the underlying software application and software platform of Token, the Platform, the internet, the blockchain network, the Participant, and other involved software, technology components and/or platforms concerned with Token may be exposed to attacks by hackers or other individuals that could result in theft or loss of Token and the Participant’s entire Participation Amount.</p>
                            <p>The Participant understands and accepts that public blockchain-based systems depend on independent validators, and therefore may be vulnerable to consensus attacks including, but not limited to, double-spend attacks, majority voting power attacks, race condition attacks and censorship attacks. Any successful attacks present a risk to such a system, expected proper execution and sequencing of Token related transactions, and expected proper execution and sequencing of software computations, including loss of the Participant’s entire Participation Amount.</p>
                            <h3>12.Miscellaneous</h3>
                            <p>12.1Repayment of the Participation Amount or distribution of Tokens in accordance with Clause 4 or 5 above will constitute the full and final discharge of any and all obligations of the Company hereunder and thereupon this Agreement shall terminate and be void in its entirety with no further obligations of the Company.</p>
                            <p>12.2The Company may withhold any amounts required by applicable law from any repayment or conversion of the Participation Amount. The Company may condition any repayment or conversion of the Participation Amount on the Participant satisfying any such withholding obligations.</p>
                            <p>12.3Neither this Agreement nor any rights or obligations hereunder may be assigned by the Participant, in whole or in part, without the express prior written consent of the Company. The Company may transfer and assign its rights and obligations hereunder to any other natural or juridical person, to another affiliate, including any entity controlled, controlling or under common control with the Company, to a third party that acquires all or substantially all of the assets subject to this Agreement or securities of the Company or any entity into which the Company shall merge or any affiliate thereof.</p>
                            <p>12.4All notices and other communications required or permitted hereunder to be given to a party to this Agreement shall be in writing and shall be sent by fax or email or mailed by registered or certified mail, postage prepaid, or otherwise delivered by hand or by messenger. Any notice sent in accordance with this Clause 12 shall be effective (i) if mailed, seven (7) days after mailing, (ii) if sent by messenger, upon delivery, and (iii) if sent via fax or email, on the first business day following transmission accompanied by delivery notification.</p>
                            <h3>13.Amendments</h3>
                            <p>The Agreement may only be amended by a written document signed by all the contracting Parties</p>
                            <p>No waiver of any party‘s default or breach of its representations, warranties, covenants, duties, agreements, or obligations or any term or provision of this Agreement, will be effective unless in writing and signed by Parties; any waiver will be limited to the default or breach described therein and no waiver will be or be deemed a waiver of any other, similar, prior, continuing, or subsequent default or breach.</p>
                            <h3>14.Validity of the Agreement</h3>
                            <p>The illegality, invalidity or unenforceability of any provision of this Agreement decided by the law of another jurisdiction does not affect the legality, validity or enforceability under the law of another jurisdiction nor the legality, validity or enforceability of another provision of this Agreement.</p>
                            <h3>15.Applicable law and jurisdiction</h3>
                            <p>This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of Ireland.</p>
                            <h3>16.Entire agreement</h3>
                            <p>This Agreement embodies the entire agreement between the Parties and supersedes all previous statements, representations and agreements between the Parties relating to the subject matter of this Agreement.</p>
                            <h3>Annex I-Representations</h3>
                            <h5>Representations</h5>
                            <p>In connection with the contribution towards the Project and allocation of Tokens from the Company, the Participant confirms, represents, warrants and agrees as follows:</p>

                            <ul>
                                <li> The ETH or other crypto Wallet from which I have made the Contribution to the Project is beneficially owned by me. To the extent that Tokens will be sent to another Wallet, I confirm that the Wallet, the details of which I shall provide the Company is likewise beneficially owned by me.</li>
                                <li>I am not a Prohibited Participant as defined.</li>
                                <li>I, or my immediate family members and/or close associates, am/are not a Politically Exposed Persons;</li>
                                <li>I have never been adjudged bankrupt;</li>
                                <li>I have never been subject to any investigation by a governmental, professional or other regulatory or statutory body;</li>
                                <li>I have never been a director, shareholder or manager of a business entity which has been the subject of any investigation as aforesaid, or which has been adjudged bankrupt, compulsorily wound up or has made any compromise or arrangement with its creditors;</li>
                                <li>I have never been convicted of any criminal offence in any jurisdiction (other than a minor offence in connection with the use of a motor vehicle) and no criminal proceedings were instituted and/or are pending against me in any jurisdiction;</li>
                                <li>None of the property that the Participant has contributed to the Company further to the Token Crowdsale has been derived from, or related to, any activity that is deemed criminal under the laws of any applicable jurisdiction;</li>
                                <li>No contribution made by the Participant to the Company and no allocation of Tokens to the Participant from the Company shall cause the Company and/or the Providers to be in violation of the Prevention of Money Laundering Act, Prevention of Money Laundering and Funding of Terrorism Regulations, (EU) 2015/849 - Fourth Money Laundering Directive, Regulation (EU) 2015/847 on information accompanying transfers of funds.</li>
                                <h5>Additional Information</h5>
                                <p>(a)The Participant agrees to provide to the Company any additional information that the Company deems necessary or convenient to ensure compliance with all applicable laws concerning money laundering and similar activities.</p>
                                <p>(b)The Participant understands that the Company may release confidential information about the Participant and, if applicable, any underlying beneficial owners, if the Company, in its sole discretion, determines that it is in the best interest of the Company in light of relevant rules and regulations. The Participant undertakes to promptly notify the Company if at any time he is unable to satisfy the agreements set forth herein or if the representations set forth herein cease to be true.</p>
                                <h5>Politically Exposed Persons</h5>
                                <p>The term ‘politically exposed persons’ is broad and generally includes all persons who fulfil a prominent public function. A natural person who is or has been entrusted with a prominent public function shall include:</p>
                                <p>Heads of State, Heads of Government, Ministers and Deputy and Assistant Ministers and Parliamentary Secretaries; </p>
                                <p>(b) Members of Parliament;</p>
                                <p>(c) Members of the Courts or of other high-level judicial bodies whose decisions are not subject to further appeal, except in exceptional circumstances;</p>
                                <p>(d) Members of courts of auditors, Audit Committees or of the boards of central banks; </p>
                                <p>(e) Ambassadors, charge d’affaires and other high-ranking officers in the armed forces;</p>
                                <p>(f) Members of the administration, management or boards of State-owned corporations; and where applicable, for the purposes of (a) to (e), shall include positions held at the Community or international level.</p>
                                <p>With respect to the term ‘immediate family members’ of PEPs, the PMLFTR provides that the term shall include: (a) the spouse or any partner recognized by national law as equivalent to the spouse; (b) the children and their spouses or partners; and (c) the parents.
                                    With respect to the term ‘persons known to be close associates’, the PMLFTR provide that the term shall include: (a) a natural person known to have: (1) joint beneficial ownership of a body corporate or any other form of legal arrangement; (2) or any other close business relations with that PEP.</p>
                                <p>(b)A natural person who has sole beneficial ownership of a body corporate or any other form of legal arrangement that is known to have been established for the benefit of that PEP.</p>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={show2} onHide={handleClose2} className=" agreementBox">
                <Modal.Header className="closebtn" closeButton>
                    <Modal.Title>Privacy Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="inner-page-cont popupLyout">
                        <div className="container">
                            <p> This Privacy Policy explains how Golteum Ltd. and its affiliates collects, uses, shares, and protects user information obtained through the Golteum website found at www.golteum.io. The terms “we,” “us,” and “our” refer to Golteum and its affiliates. When we ask for certain personal information from users it is either because we are required by law to collect this information or it is relevant for specified purposes. Any non-required information you provide to us is done so voluntarily. You decide whether to provide us with non-required information; you may not be able to access or utilize all of our Services if you choose not to. </p>
                            <p> By using the Golteum website, you consent to the data practices described in this Privacy Policy. On occasion, Golteum may revise this Privacy Policy to reflect changes in law or our personal data collection and use practices. If material changes are made to this Privacy Policy, the changes will be announced by posting on the website and the Last Revised date will be updated. We will ask for your consent before using your information for any purpose that is not covered in this Privacy Policy. </p>
                            <p> The latest Privacy Policy has incorporated elements from the General Data Protection Regulation, as we act in accordance to its personal information processing rules within the European Economic Area. </p><br />

                            <h4>INFORMATION THAT GOLTEUM COLLECTS</h4>
                            <p> We want you to understand the types of information we collect when you register for and use Golteum’s services.</p>
                            <p> Information you provide to us upon registration:</p>
                            <p> When you create a Golteum account, you provide us with personal information that includes your contact information (email address, name, and a password). You can also choose to add a phone number for SMS for two-factor authentication verification for improved security. </p>
                            <p> Information we collect when authenticating user identity: </p>
                            <p> To comply with global industry regulatory standards, including those related to Anti-Money Laundering ("AML"), Know-Your-Customer ("KYC"), and Combatting the Financing of Terrorism ("CFT"), Golteum may require user accounts to undergo user identity authentication for both personal and enterprise accounts. This entails collecting formal identification. </p>
                            <p> Information we collect as you use our services:</p>
                            <p> A. Service Usage Information</p>
                            <p> Through your use of the Golteum platform, we also monitor and collect tracking information related to usage, such as access date and time, device identification, operating system, browser type and IP address. This information may be directly obtained by Golteum or through third party services. This service usage data helps our systems to ensure that our interface is accessible for users across all platforms and can aid during criminal investigations. </p>
                            <p> B. Transaction Information</p>
                            <p> For all personal and enterprise user accounts, we collect transaction information, including deposit snapshots, account balances, trade history, withdrawals, order activity and distribution history. This transaction data is monitored for suspicious trading activity for user fraud protection, and legal case resolution. </p><br />

                            <h4>WHY DOES GOLTEUM COLLECT THIS INFORMATION?</h4>
                            <p> A. To provide and maintain our services</p>
                            <p> We use the information collected to deliver our services and to verify user identity. We use the IP address and unique identifiers stored in your device’s cookies to help us authenticate your identity and to provide our services. Given our legal obligations and system requirements, we cannot provide you with our services without data, like identification, contact information and transaction-related information. </p>
                            <p> B. To protect our users</p>
                            <p> We use the information collected to protect our platform, its users’ accounts, and archives. We use IP addresses and cookie data to protect against automated abuse such as spam, phishing and Distributed Denial of Service attacks. We analyze trading activity with the goal of detecting suspicious behaviour early to prevent potential fraud and loss of funds to bad actors.</p>
                            <p> C. To comply with legal and regulatory requirements</p>
                            <p> Respect for the privacy and security of the data you store with Golteum informs our approach to complying with regulations, governmental requests and user-generated inquiries. We will not disclose or provide any personal information to third party sources without review from our legal team and/or prior consent from the user. </p>
                            <p> D. To measure site performance</p>
                            <p> We actively measure and analyze data to understand how our services are used. This review activity is conducted by our operations team to continually improve our platform’s performance and to resolve issues with the user experience.<br /> We continuously monitor our systems’ activity information and communications with users to look for and quickly fix problems. </p>
                            <p> E. To communicate with you</p>
                            <p> We use personal information collected, like an email address to interact with users directly when providing customer support on a ticket or to keep you informed on logins, transactions, and security. Without processing your personal information for confirming each communication, we will not be able to respond to your submitted requests, questions and inquiries. All direct communications are kept confidential and reviewed internally for accuracy. </p>
                            <p> F. To enforce our Terms of Use and other agreements</p>
                            <p> It is very important for us and our customers that we continually review, investigate and prevent any potentially prohibited or illegal activities that violate our Terms of Use. For the benefit of our entire user base, we carefully enforce our agreements with third parties and actively investigate violations of our posted Terms of Use. Golteum reserves the right to terminate the provision of service to any user found engaging in activities that violate our Terms of Use. </p><br />
                            <h4>HOW DOES GOLTEUM PROTECT USER DATA?</h4>
                            <p> Golteum has implemented a number of security measures to ensure that your information is not lost, abused, or altered. Our data security measures include, but are not limited to: PCI Scanning, secure sockets layer encryption technology, pseudonymisation, internal data access restrictions, and strict physical access controls to buildings and files. Please note that it is impossible to guarantee 100% secure transmission of data over the Internet nor method of electronic storage. As such, we request that you understand the responsibility to independently take safety precautions to protect your own personal information. If you suspect that your personal information has been compromised, especially account and/or password information, please lock your account and contact Golteum customer service immediately via email on <a href="mailto:info@golteum.io" rel="noopener">info@golteum.io</a> </p><br />
                            <h4>CONTACT US</h4>
                            <p> We are committed to respecting the freedoms and rights of all our Golteum users who have placed their trust in our service. If you should have any questions or concerns regarding this Privacy Policy, or if you would like to file a data protection request, please refer to our FAQ and contact us via email on <a href="mailto:info@golteum.io" rel="noopener">info@golteum.io</a> </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
