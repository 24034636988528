import React, { useState } from 'react'
import { Helmet } from "react-helmet";
import '../../Css/Custom.css'
import '../../Css/Responsive.css'
import Header from './Header';
import Sidebar from './Sidebar';
import UserProvider from "../../../UserContext";

function CommonWrapper(props) {

    const [hamBurger, setHamBureger] = useState(false)

    const handleHamBurger = () =>{
        setHamBureger(!hamBurger)
    }


    return (
        <>
            <UserProvider>
                {/* <Loader text="" show={OnLoader} /> */}
                <Helmet>
                    <style>{"body { background-color: #0c0f16; }"}</style>
                </Helmet>
                <div className='cssForAll'>
                    <div className="wraper">
                        <Header hamBurger={hamBurger} handleHamBurger={handleHamBurger} />
                        <div className="dash-wrap">
                            <Sidebar hamBurger={hamBurger} handleHamBurger={handleHamBurger} />
                            {props.children}
                        </div>
                    </div>
                </div>
            </UserProvider>
        </>

    )
}

export default CommonWrapper