import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "components/User/Login/Index";
const Index = () => {
  return (
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
  );
};

export default Index;
