import React, { useState, useEffect, useMemo } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getReferralList } from 'components/helper/userHelper';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Loader from 'components/Common/Loader';
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2';
import $ from "jquery"
import FilterComponent from './FilterComponent';

export default function ReferralBonus(props) {
    const [copybtn, setcopybtn] = useState(false)
    const [totalReferraluser , settotalReferraluser ] = useState(0)
    const [selectedTxs, setSelectedTxs] = useState([])

    const referralTable = {
        columns: [
            {
                name: "Serial",
                selector: row => row.id,
                sortable: true
            },
            {
                name: "Name",
                selector: row => row.name,
                sortable: true
            },
            {
                name: "Total Purchased Tokens",
                selector: row => row.tokens,
                sortable: true,
                maxWidth:"240px",
                minWidth:"240px",
            },
            {
                name: "Date",
                selector: row => row.date,
                sortable: true
            }
        ],
        data: []
    }
    const headers1 = [
        { label: "Serial", key: "id" },
        { label: "Name", key: "name" },
        { label: "Total Purchased Tokens", key: "tokens" },
        { label: "Date", key: "date" }
    ];
    const [referralData, setreferralData] = useState(referralTable);
    const [showLoader, setShowLoader] = useState(false);

    const onCopyText = () => {
        setcopybtn(true)
        setTimeout(function () {
            setcopybtn(false)
        }, 2000);
    };


    const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const filteredItems = referralData["data"].filter(
		item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
	);

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);

    
    useEffect(() => {          
        let isApiSubscribed = true;
        const getData = async() =>{
            if(isApiSubscribed){
                // setShowLoader(true)
                const referralList = await getReferralList();
                console.log("referralList",referralList)
                if(!referralList){
                    setShowLoader(false)
                    Swal.fire({
                        text: "Error while processing your request please try again later",
                        button: false,
                        background: "black",
                        icon: "error",
                        timer: 2000,
                    });
                }else{
                    let data = [];
                    let id = 1;
                    if (referralList.referraledUser.length) {
                        settotalReferraluser(referralList.referraledUser.length)
                        for (let i = referralList.referraledUser.length; i > 0; i--) {
                            let details = {
                                "id": id,
                                "name": referralList.referraledUser[i-1].fullName,
                                "tokens": referralList.referraledUser[i-1].tokenBal,
                                "date": new Date(referralList.referraledUser[i-1].date).toLocaleString()
                            }
                            id++;
                            data.push(details)
                        }
                    }
                    // $("[name=filterDateTable]").attr("autocomplete", "off"); 
                    console.log("datadatadata",data)
                    setreferralData({...referralData, data:data});
                    // $("[name=filterDateTable]").attr("autocomplete", "off"); 
                    setShowLoader(false);                                  
                }
            }
        }
        getData();
        return () => {
            isApiSubscribed = false;
        }; 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelected = (event) =>{
        console.log(event.selectedRows)
        setSelectedTxs(event.selectedRows)
        // setSelectedPayment(selectedPayment=>{
        //     return{
        //         ...selectedPayment,
        //         count:event.selectedCount,
        //         data:event.selectedRows
        //     }
        // })
        // getBulkSenderUpload(event)
        // const newObject = {"Serial" : event.selectedCount-1, "Address" : event.selectedRows[event.selectedCount].wallet, "Amount" : event.selectedRows[event.selectedCount].tokens }
    }


    return (
        <>
            <div className={props.toggleState === 3 ? "content active-content col-md-8" : "content col-md-8"}>
                <div className="referral-box-t mb-4">
                    <div className="card-cust p-4">
                        <h4>Referral details</h4>
                        <div className="form-group mt-4 referral-input">
                            <h6>Referral link</h6>
                            <input type="text" name="" value={window.location.origin + '/register?id=' + props.userInfo.id} className="form-control" disabled/>
                            {/* <button className="btn btn-grd"><i className="fa fa-clone" aria-hidden="true"></i> Copy</button> */}
                            <CopyToClipboard  text={window.location.origin + '/register?id=' + props.userInfo.id} className="btn btn-grd" onCopy={onCopyText}>
                            {copybtn ? <button style={{ border: 0, backgroundColor:"#0000"}}><i className="fa fa-clone copyto" aria-hidden="true"></i></button> : <button style={{ border: 0, backgroundColor:"#0000"}}><i className="fa fa-clone" aria-hidden="true"></i></button>}                                 
                            </CopyToClipboard>  
                        </div>
                    </div>
                </div>
                {/* <div className="referral-table">
                    <div className="card-cust table-cust-in">
                    {(referralData.data.length > 0) ? (
                        <><CSVLink className="downloadBtn" headers={headers1} filename="Referral List.csv" data={referralData["data"]}>Download&nbsp;&nbsp;<i className="fa fa-download" aria-hidden="true"></i></CSVLink>
                            <DataTableExtensions {...referralData}>
                                <DataTable 
                                    columns={referralData["columns"]}
                                    data={referralData["data"]}
                                    defaultSortField="Serial"
                                    defaultSortAsc={true}
                                    sortable={true}
                                    pagination
                                    highlightOnHover
                                    dense
                                />
                            </DataTableExtensions>
                        </>
                    ) : <table className="table-cust table dt-responsive nowrap">
                        <thead>
                            <tr className='text-center' >
                                <th>Serial</th>
                                <th>Name</th>
                                <th>Total Purchased Tokens</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="4"><p style={{ textAlign: "center" }}>No Data Found</p></td>
                            </tr>
                        </tbody>
                    </table>}
                    </div>
                </div> */}
                <div className="referral-table">
                    <div className="card-cust table-cust-in">
                    <CSVLink className="downloadBtn" headers={headers1} filename="Referral List.csv" data={selectedTxs.length > 0 ? selectedTxs :  referralData["data"]}>Download&nbsp;&nbsp;<i className="fa fa-download" aria-hidden="true"></i></CSVLink>
                    <DataTable
                        // title="Contact List"
                        columns={referralData["columns"]}
                        data={filteredItems}
                        pagination
                        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        selectableRows
                        onSelectedRowsChange={handleSelected}
                        persistTableHead
                    />
                    </div>
                </div>
            </div>
        </>
    )
}
