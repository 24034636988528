import React from "react";
import { Routes, Route } from "react-router-dom";
import Cancel from "components/PaymentStatus/Cancel";
const Index = () => {
  return (
      <Routes>
        <Route path="/" element={<Cancel />} />
      </Routes>
  );
};

export default Index;
