/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react'
import { reSendEmail, presaleCheck } from "components/helper/userHelper";
import { Helmet } from "react-helmet";
import '../Css/Custom.css'
import Swal from 'sweetalert2';
// import { useNavigate } from "react-router-dom";
import '../Css/Responsive.css'
import Footer from './Common/Footer';
import CardRight from './Common/CardRight';
import Loader from 'components/Common/Loader';
import { Link } from 'react-router-dom';
import { chnageCurrencyAmt, fetchDummyStage } from 'components/helper/userHelper';
import CountDown from 'components/Common/CountDown';
import { useUser } from 'components/UserContext';
import statics1 from './img/statics1.png'
import statics2 from './img/statics2.png'
import statics3 from './img/statics3.png'
import profileImg from './img/profile.svg'

export default function Index() {
    const { users } = useUser();
    const [OnLoader , setOnLoader] = useState(false)
    const [loaderAct, setloaderAct] = useState(false);
    const [showWaitList, setShowWaitList] = useState(false);
    const [user, setUser] = useState({
        fullname: "",
        pre_stage: "0",
        stage_price: "0",
        tokenAmount: "0",
        userStatus: "",
        amtSold: "0",
        startTime: "0",
        endTime: "0",
        stageNumber: "0",
        wallet: "",
        futurePrice:"NA",
        remainingTokens:0
    });
    
    const [usdToEth , setusdToEth] = useState(0)
    const now = Math.floor(((new Date()).getTime()) / 1000);
    const [seconds, setSeconds] = useState(0);
    

    useEffect(() => {
        const countDownDate = new Date("Oct 31, 3000 11:46:00").getTime();
        const coundown = setInterval(function () {
            const now = new Date().getTime();
            const distance = countDownDate - now;
            setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
            if (distance <= 0) {
            clearInterval(coundown);
            setSeconds(0);
            }
        }, 1000);
    }, [seconds])

    useEffect(() => {
        let isAPISubscribed = true;
        const fatchUserDetails = async () => {
            setUser({
                fullname: users.fullname,
                wallet:users.wallet,
                userStatus: users.userStatus,
                pre_stage: users.stageNumber,
                stage_price: users.stage_price,
                tokenAmount: users.tokenAmount,
                amtSold: users.amtSold,
                startTime: users.startTime,
                endTime: users.endTime,
                stageNumber: users.stageNumber,
                futurePrice: users.futurePrice,
                remainingTokens: users.remainingTokens
            });
            // setUser((prev)=>{
            //     return {
            //         ...prev,
            //         fullname: users.fullname,
            //         wallet:users.wallet,
            //         userStatus: users.userStatus,
            //         pre_stage: users.stageNumber,
            //         startTime: users.startTime,
            //         endTime: users.endTime,
            //         stageNumber: users.stageNumber,
            //         futurePrice: users.futurePrice, 
            //     }
            // });
        }
        // const fetchDummyStage_fn = async () =>{
        //     const dummyData = await fetchDummyStage();
        //     console.log("dummyData",dummyData)
        //     setUser((prev)=>{
        //         return {
        //             ...prev,
        //             stage_price: dummyData.tokenPrice,
        //             tokenAmount: dummyData.totalTokens,
        //             amtSold: dummyData.soldTokens,
        //             remainingTokens: dummyData.remainingTokens   
        //         }
        //     });
        // }
        if(isAPISubscribed){
            fatchUserDetails();
            // fetchDummyStage_fn();
            getChangeAmtCurr();
            checkPresale()
        }
        return () => {
            isAPISubscribed = false
        }

    }, [users.amtSold, users.endTime, users.fullname, users.futurePrice, users.remainingTokens, users.stageNumber, users.stage_price, users.startTime, users.tokenAmount, users.userStatus, users.wallet]);

    

    const checkPresale = async() =>{
        let res = await presaleCheck()
            setShowWaitList(res.data.presale)
            // console.log("preale chck",res.data.presale)
    }

    const resendEmail_fn = async() =>{
        setloaderAct(true)
        let res = await reSendEmail();
        if(!res){
            Swal.fire({
                text: "Error while processing your request please try again later",
                button: false,
                background: "black",
                icon: "error",
                timer: 2000,
            });
        }else{
            if(res.data.success){
                setloaderAct(false)
                Swal.fire({ showConfirmButton:false, 
                    text: res.data.msg,
                    button:false,
                    background: 'black',
                    icon: "success",
                    timer: 4000,
                });
            }else{
                setloaderAct(false)
                Swal.fire({ showConfirmButton:false, 
                    text: res.data.msg,
                    button: false,
                    background: 'black',
                    icon: "error",
                    timer: 4000,
                });
            }
        }
    }

    const getChangeAmtCurr = async() => {
        let res = await chnageCurrencyAmt(1, 'usd', 'eth');
        if(res){
            setusdToEth(res.data.estimated_amount)          
        }
        else{
            console.log("Error while processing your request please try again later")
        }
    }

    return (
        <>
        <Helmet>
            <title>Dashboard | Golteum</title>
        </Helmet>
        <Loader text="" show={OnLoader} />
            <div className="dash-container">
                <div className="page-tit">
                    <h4>Dashboard</h4>
                </div>
                <div className="dash-container-in">
                    <div className="welcome-msg">
                        <h4>Welcome to <font>Golteum</font></h4>
                        <p>Thank you for signing up, {user.fullname ? `${user.fullname}.` : '...'} In accordance with the terms and conditions you will be able to purchase and see your Golteum tokens using this dashboard.
                        {user.fullname ? '' : <span className='profile-warning'>Warning: Please complete your profile.</span>}

                        </p>
                    </div>
                    <div className="row dash-home">
                        <div className="col-md-8">
                            <div className="card-cust">
                                <div className="dash-statics row">
                                    <div className="col-md-4">
                                        <article>
                                            <figure>
                                                <img src={statics1} />
                                            </figure>
                                            <figcaption>
                                                <p>Stage of the Presale</p>
                                                <h4>{user.pre_stage}</h4>
                                            </figcaption>
                                        </article>
                                    </div>
                                    <div className="col-md-4">
                                        <article>
                                            <figure>
                                                <img src={statics2} />
                                            </figure>
                                            <figcaption>
                                                <p>Stage Price</p>
                                                <h4>{user.stage_price} USD</h4>
                                            </figcaption>
                                        </article>
                                    </div>
                                    <div className="col-md-4">
                                        <article>
                                            <figure>
                                                <img src={statics3} />
                                            </figure>
                                            <figcaption>
                                                <p>Stage Bonus</p>
                                                {/* <h4>{user.futurePrice} USD</h4> */}
                                                {/* <h4>30%</h4> */}
                                                <h4>15%</h4>
                                            </figcaption>
                                        </article>
                                    </div>
                                </div>
                                <div className="raised-token-bar">
                                    <div className="raised-token-text d-flex">
                                        <div className="raised-left">
                                            <span>Amount Sold</span>
                                            <strong>{Number(user.tokenAmount - user.remainingTokens).toFixed(2)} $GLTM</strong>
                                        </div>
                                        <div className="raised-right ml-auto">
                                            <span>Amount Available</span>
                                            <strong>{Number(user.remainingTokens).toFixed(2)} $GLTM</strong>
                                        </div>
                                    </div>
                                    <div className="pro-bar">
                                        <div className="pro-bar-in">
                                            <span style={{ width: (((user.tokenAmount - user.remainingTokens) * 100) / user.tokenAmount)+'%'}}></span>
                                        </div>
                                        {/* <div className="bullet-rg" style={{left:(((user.tokenAmount - user.amtSold) * 100) / user.tokenAmount)+'%'}}></div> */}
                                    </div>
                                    {/* <div className="raised-token-text d-flex">
                                        <div className="raised-left">
                                            <span>Amount Sold</span>
                                            <strong>${Number((user.tokenAmount - user.remainingTokens) * user.stage_price).toFixed(2)}</strong>
                                        </div>
                                        <div className="raised-right ml-auto">
                                            <span>Amount Available</span>
                                            <strong>${Number((user.remainingTokens) * user.stage_price).toFixed(2)}</strong>
                                        </div>
                                    </div> */}
                                </div>

                                <div className="stage-token">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="token-price-text">
                                                <p>Token Price</p>
                                                <h4>1 $GLTM  = {user.stage_price} USD</h4>
                                                <span>1 USD  = {usdToEth} ETH</span>
                                                <div className="token-price-btn mt-2">
                                                    <Link className="btn btn-grd" to="/buy-token">Buy Token Now</Link>&nbsp;&nbsp;
                                                    {showWaitList ?  '' : <Link className="btn btn-grd" to="/profile?id=4">Waitlist</Link>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="golteum-timer">
                                                <h6>STAGE {user.stageNumber ? user.stageNumber : '0'} {user.startTime > now ? 'STARTS' : 'ENDS'} IN:</h6>
                                                <CountDown end={user.endTime} start={user.startTime} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="account-status d-flex">
                                    <div className="account-status-in">
                                        <figure>
                                            <img src={profileImg} />
                                        </figure>
                                        <figcaption>
                                            <h4>Your Account Status</h4>
                                            {user.userStatus ? <span style={{color:"green"}}>Email is verified</span> : <span>Not confirmed</span> }
                                            
                                        </figcaption>
                                    </div>
                                    {user.userStatus ? '' : 
                                      (loaderAct ? <button className="btn btn-grd ml-auto" disabled><img className="loaderImg" src="images/loader2.gif" alt="" />Processing ...</button> : <a href="#" className="btn btn-grd ml-auto" onClick={resendEmail_fn}>Resend confirmation email</a>)
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <CardRight />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
