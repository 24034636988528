import React from "react";
import { Routes, Route } from "react-router-dom";
import Profile from "components/User/Dashboard/Profile/Index";
const Index = () => {
  return (
      <Routes>
        <Route path="/" element={<Profile />} />
      </Routes>
  );
};

export default Index;
