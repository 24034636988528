import React, { createContext, useContext, useState, useEffect } from "react";
import { getUserDetails } from "./helper/userHelper";
import Loader from "./Common/Loader";


const UserContext = createContext(null);
export const useUser = () => useContext(UserContext);

function UserProvider({ children }) {

  const [showLoader, setShowLoader] = useState(false);

  const [users, setUser] = useState({
    id: "",
    status: "",
    fullname: "",
    email: "",
    dob: '',
    phone: "",
    nation: "",
    countryCode: "",
    twoFA: "",
    tokenBal: "",
    wallet: "",
    userStatus: "",
    pre_stage: "0",
    stage_price: "0",
    tokenAmount: "0",
    amtSold: "0",
    startTime: "0",
    endTime: "0",
    stageNumber: "0",
    futurePrice: "NA",
    recordActivity: false,
    remainingTokens:0,
    isAdminCreated:"",
    verified: ""
  })

  useEffect(() => {
      let ApiSubscribed = true;
      if(ApiSubscribed){
        fatchUserDetails();
      }
      return () =>{
        ApiSubscribed = false;
      }
  }, []);

  const fatchUserDetails = async () => {
      setShowLoader(true)
      let res = await getUserDetails();
      if(res){
        if (res.data.success) {
          console.log('userDetails', res.data.data.userDetails)
          setShowLoader(false)
          setUser({
            id: res.data.data.userDetails._id,
            status: res.data.success,
            fullname: res.data.data.userDetails.fullName,
            email: res.data.data.userDetails.email,
            dob: res.data.data.userDetails.dob !== "NA" ? res.data.data.userDetails.dob : '',
            phone: res.data.data.userDetails.mob,
            nation: res.data.data.userDetails.nationality,
            countryCode: res.data.data.userDetails.countryCode,
            twoFA: res.data.data.userDetails.twoFactor,
            tokenBal: res.data.data.userDetails.tokenBal,
            wallet: res.data.data.userDetails.wallet,
            userStatus: res.data.data.userDetails.isEmailVerified,
            futurePrice: res.data.data.futurePrice,
            recordActivity: res.data.data.userDetails.recordActivity,
            isAdminCreated: res.data.data.userDetails.isAdminCreated,
            pre_stage: res.data.data.StageData.length !== 0 ? res.data.data.StageData[0].stageNumber : 0,
            stage_price: res.data.data.StageData.length !== 0 ? res.data.data.StageData[0].tokenPrice : 0,
            tokenAmount: res.data.data.StageData.length !== 0 ? res.data.data.StageData[0].totalTokens : 0,
            amtSold: res.data.data.StageData.length !== 0 ? res.data.data.StageData[0].remainingTokens : 0,
            startTime: res.data.data.StageData.length !== 0 ? res.data.data.StageData[0].startTime : 0,
            endTime: res.data.data.StageData.length !== 0 ? res.data.data.StageData[0].endTime : 0,
            stageNumber: res.data.data.StageData.length !== 0 ? res.data.data.StageData[0].stageNumber : 0,
            remainingTokens: res.data.data.StageData.length !== 0 ? res.data.data.StageData[0].remainingTokens : 0,
            verified: res.data.data.userDetails.verified
          })
        }
        else{
          setShowLoader(false)
        }
      }
      else{
        setShowLoader(false)
      }

  }
  return (
    <>
      <Loader show={showLoader} />
      <UserContext.Provider
        value={{
          users
        }}
      >
        {children}
      </UserContext.Provider>
    </>

  );
}

export default UserProvider;
