const Article = ({ src, title, delay, children }) => {
  return (
    <div className="col-md-3 wow fadeInUp" data-wow-delay={delay}>
      <article className={`box-blue`}>
        <figure>
          <img src={src} alt={src} />
        </figure>
        <figcaption>
          <h4>{title}</h4>
          {children}
        </figcaption>
      </article>
    </div>
  );
};

export default Article;



export const ontop = () => {
	window.scroll({
		top: 0,
		left: 0,
		behavior: "smooth",
	});
};