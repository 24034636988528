import React from "react";
import { Routes, Route } from "react-router-dom";
import ActivityLog from "components/User/Dashboard/ActivityLogs/Index";
const Index = () => {
    return (
        <Routes>
            <Route path="/" element={<ActivityLog />} />
        </Routes>
    );
};
export default Index;