import React,{ useEffect } from 'react'
import Header from 'components/Layout/header'
import Footer from 'components/Layout/footer'
import { ontop } from 'components/Common/article'
export default function Index() {
    useEffect(() => {
        ontop();
    }, [])
    return (
        <>
            <Header />
            <div classname="warper ">
                <div className="site_content" id="home">
                    <div className="inner-head">
                        <h4>Golteum Privacy Policy</h4>
                    </div>
                    <div className="inner-page-cont">
                        <div className="container">
                            <p> This Privacy Policy explains how Golteum Ltd. and its affiliates collects, uses, shares, and protects user information obtained through the Golteum website found at www.golteum.io. The terms “we,” “us,” and “our” refer to Golteum and its affiliates. When we ask for certain personal information from users it is either because we are required by law to collect this information or it is relevant for specified purposes. Any non-required information you provide to us is done so voluntarily. You decide whether to provide us with non-required information; you may not be able to access or utilize all of our Services if you choose not to. </p>
                            <p> By using the Golteum website, you consent to the data practices described in this Privacy Policy. On occasion, Golteum may revise this Privacy Policy to reflect changes in law or our personal data collection and use practices. If material changes are made to this Privacy Policy, the changes will be announced by posting on the website and the Last Revised date will be updated. We will ask for your consent before using your information for any purpose that is not covered in this Privacy Policy. </p>
                            <p> The latest Privacy Policy has incorporated elements from the General Data Protection Regulation, as we act in accordance to its personal information processing rules within the European Economic Area. </p><br />

                            <h4>INFORMATION THAT GOLTEUM COLLECTS</h4>
                            <p> We want you to understand the types of information we collect when you register for and use Golteum’s services.</p>
                            <p> Information you provide to us upon registration:</p>
                            <p> When you create a Golteum account, you provide us with personal information that includes your contact information (email address, name, and a password). You can also choose to add a phone number for SMS for two-factor authentication verification for improved security. </p>
                            <p> Information we collect when authenticating user identity: </p>
                            <p> To comply with global industry regulatory standards, including those related to Anti-Money Laundering ("AML"), Know-Your-Customer ("KYC"), and Combatting the Financing of Terrorism ("CFT"), Golteum may require user accounts to undergo user identity authentication for both personal and enterprise accounts. This entails collecting formal identification. </p>
                            <p> Information we collect as you use our services:</p>
                            <p> A. Service Usage Information</p>
                            <p> Through your use of the Golteum platform, we also monitor and collect tracking information related to usage, such as access date and time, device identification, operating system, browser type and IP address. This information may be directly obtained by Golteum or through third party services. This service usage data helps our systems to ensure that our interface is accessible for users across all platforms and can aid during criminal investigations. </p>
                            <p> B. Transaction Information</p>
                            <p> For all personal and enterprise user accounts, we collect transaction information, including deposit snapshots, account balances, trade history, withdrawals, order activity and distribution history. This transaction data is monitored for suspicious trading activity for user fraud protection, and legal case resolution. </p><br />

                            <h4>WHY DOES GOLTEUM COLLECT THIS INFORMATION?</h4>
                            <p> A. To provide and maintain our services</p>
                            <p> We use the information collected to deliver our services and to verify user identity. We use the IP address and unique identifiers stored in your device’s cookies to help us authenticate your identity and to provide our services. Given our legal obligations and system requirements, we cannot provide you with our services without data, like identification, contact information and transaction-related information. </p>
                            <p> B. To protect our users</p>
                            <p> We use the information collected to protect our platform, its users’ accounts, and archives. We use IP addresses and cookie data to protect against automated abuse such as spam, phishing and Distributed Denial of Service attacks. We analyze trading activity with the goal of detecting suspicious behaviour early to prevent potential fraud and loss of funds to bad actors.</p>
                            <p> C. To comply with legal and regulatory requirements</p>
                            <p> Respect for the privacy and security of the data you store with Golteum informs our approach to complying with regulations, governmental requests and user-generated inquiries. We will not disclose or provide any personal information to third party sources without review from our legal team and/or prior consent from the user. </p>
                            <p> D. To measure site performance</p>
                            <p> We actively measure and analyze data to understand how our services are used. This review activity is conducted by our operations team to continually improve our platform’s performance and to resolve issues with the user experience.<br /> We continuously monitor our systems’ activity information and communications with users to look for and quickly fix problems. </p>
                            <p> E. To communicate with you</p>
                            <p> We use personal information collected, like an email address to interact with users directly when providing customer support on a ticket or to keep you informed on logins, transactions, and security. Without processing your personal information for confirming each communication, we will not be able to respond to your submitted requests, questions and inquiries. All direct communications are kept confidential and reviewed internally for accuracy. </p>
                            <p> F. To enforce our Terms of Use and other agreements</p>
                            <p> It is very important for us and our customers that we continually review, investigate and prevent any potentially prohibited or illegal activities that violate our Terms of Use. For the benefit of our entire user base, we carefully enforce our agreements with third parties and actively investigate violations of our posted Terms of Use. Golteum reserves the right to terminate the provision of service to any user found engaging in activities that violate our Terms of Use. </p><br />
                            <h4>HOW DOES GOLTEUM PROTECT USER DATA?</h4>
                            <p> Golteum has implemented a number of security measures to ensure that your information is not lost, abused, or altered. Our data security measures include, but are not limited to: PCI Scanning, secure sockets layer encryption technology, pseudonymisation, internal data access restrictions, and strict physical access controls to buildings and files. Please note that it is impossible to guarantee 100% secure transmission of data over the Internet nor method of electronic storage. As such, we request that you understand the responsibility to independently take safety precautions to protect your own personal information. If you suspect that your personal information has been compromised, especially account and/or password information, please lock your account and contact Golteum customer service immediately via email on <a href="#" rel="noopener">info@golteum.io</a> </p><br />
                            <h4>CONTACT US</h4>
                            <p> We are committed to respecting the freedoms and rights of all our Golteum users who have placed their trust in our service. If you should have any questions or concerns regarding this Privacy Policy, or if you would like to file a data protection request, please refer to our FAQ and contact us via email on <a href="#" rel="noopener">info@golteum.io</a> </p>
                        </div>
                    </div>
                </div>
                <br />
            </div>

            <Footer />
        </>
    )
}
