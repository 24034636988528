// /* eslint-disable no-script-url */
// /* eslint-disable jsx-a11y/alt-text */
// /* eslint-disable jsx-a11y/anchor-is-valid */
// import { Link } from "react-router-dom";

// const Footer = () => {
//   return (
//     <footer>
//       <div className="container">
//         <div className="foot-top">
//           <a href="#" className="foot-logo" rel="noopener">
//             <img src="images/logo-footer.png" />
//           </a>
//           <div className="social-ic">
//             <h4>JOIN OUR COMMUNITY</h4>
//             <div className="social-ic-in">
//               <a href="#" rel="noopener">
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   viewBox="0 0 24 24"
//                   width="24"
//                   height="24"
//                 >
//                   <path fill="none" d="M0 0h24v24H0z" />
//                   <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />
//                 </svg>
//               </a>
//               <a href="#" rel="noopener">
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="20.083"
//                   height="16.737"
//                   viewBox="0 0 20.083 16.737"
//                 >
//                   <path
//                     id="Path_35"
//                     data-name="Path 35"
//                     d="M7.88,13.03,7.548,17.7a1.162,1.162,0,0,0,.928-.449l2.228-2.13,4.618,3.382c.847.472,1.444.223,1.672-.779l3.031-14.2h0c.269-1.252-.453-1.741-1.278-1.434L.932,8.91c-1.216.472-1.2,1.15-.207,1.457L5.28,11.783l10.58-6.62c.5-.33.951-.147.578.182Z"
//                     transform="translate(0 -2)"
//                     fill="#fff"
//                   />
//                 </svg>
//               </a>
//               <a href="#" rel="noopener">
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   viewBox="0 0 24 24"
//                   width="24"
//                   height="24"
//                 >
//                   <path fill="none" d="M0 0h24v24H0z" />
//                   <path d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z" />
//                 </svg>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="foot-last">
//         <div className="container">
//           <div className="foot-last-in d-flex align-items-center">
//             <span>© 2024 Golteum.io All Rights Reserved.</span>
//             <div className="foot-link ml-auto">
//               <Link to="/privacy-policy">PRIVACY POLICY</Link>
//               {/* <Link to="/term">TERMS OF USE</Link> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;


/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="foot-top">
          <a href="#" className="foot-logo">
            <img className="New-logo" src="images/GLTM-Logo.png" />
          </a>
          <div className="social-ic">
            <h4>Join Our Community</h4>
            <div className="social-ic-in">
              <a target={"_blank"} className="foot-icon" href="https://twitter.com/golteum?s=20&t=Kirb38vwigNjUq0GgWCyUA">
                <i className="bi bi-twitter"></i>
              </a>
              <a target={"_blank"} className="foot-icon" href="https://t.me/golteum">
                <i className="bi bi-telegram"></i>
              </a>
              <a className="foot-icon" target={"_blank"} href="https://instagram.com/golteum?igshid=YmMyMTA2M2Y=">
                <i className="bi bi-instagram"></i>
              </a>
              <a className="foot-icon" target={"_blank"} href="https://www.facebook.com/profile.php?id=100082846137280">
                <i className="bi bi-facebook"></i>
              </a>
              <a target={"_blank"} className="foot-icon" href="https://www.linkedin.com/company/golteum/">
                <i className="bi bi-linkedin"></i>
              </a>
              <a target={"_blank"} className="foot-icon" href="https://discord.gg/golteum">
                <i className="bi bi-discord"></i>
              </a>
            </div>
          </div>
          <div className="disclaimertext">
            <h4><i>Disclaimer:</i></h4>
            <p>Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may go down as well as up. Profits may be subject to capital gains or other taxes applicable in your jurisdiction.</p>
          </div>
        </div>
      </div>
      <div className="foot-last">
        <div className="container">
          <div className="foot-last-in d-flex  align-items-center">
            <span>© 2024 Golteum.io All Rights Reserved.</span>
            <div className="foot-link ml-auto ">
              <Link className="" to="/privacy-policy">PRIVACY POLICY</Link>
              <Link className="" to="/term">TERMS OF USE</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
