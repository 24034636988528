import React from "react";
import { Routes, Route } from "react-router-dom";
import BuyToken from "components/User/Dashboard/BuyToken/Index";
const Index = () => {
  return (
      <Routes>
        <Route path="/" element={<BuyToken />} />
      </Routes>
  );
};

export default Index;
